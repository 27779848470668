.brochure-container {
    position: relative;

    .bg-shape {

        display: block;
        position: absolute;
        width: 300%;
        overflow: hidden;
        top: -230px;
        bottom: 0;
        height: calc(100% + 230px);
        right: 0;

        svg {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            fill:$bg-light-grey;
        }

        @media (min-width: $max-tablet ) {
            width: 200%;
        }
        @media (min-width: $small-desktop ) {
            width: 100%;
        }
    }
   

  .listing {
    z-index: 2;
    position: relative;

    .listing-item:first-child {
     
        &:before {
            content: "";
            height: 1px;
            background-color: $border;
            position: absolute;
            top: 0;
            left: 64px;
            right: 64px;
        }
    }

  }

 &:after { //bottom curve
    z-index: 1;
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    background: $bg-dark-grey;
    border-radius: 100% 0 0 0;
    width: 100%;
    padding-bottom: 100%;
    max-width: 730px;
     
    @media (min-width: $max-tablet ) {
        width: 60%;
        padding-bottom: 45%;
    }

 }


}
.all-downloads-cta {
    margin-top: 64px;
    display: flex;
    justify-content: center;

    a {
        
        margin: 0 auto;
    }
}
