.main {
    padding-top: 30px;

    @media(min-width: $breakpoint) {
        padding-top: 5px;
    }

    &.interim {
        padding-top: 0px;
    }

}

.hero {
    position: relative;
    min-height: 298px;
    display: flex;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    &.simple-header {
        margin-top: $horizontal-space;
    }

    .has-mobile-img {
        padding: 0;

        .image-bg-header {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
            height: 324px;
        }
    }

    .hero-content {
        margin-top: 0;
        margin-bottom: $horizontal-space;
    }

    &.home-header {
    }

    &.has-img {
        min-height: 540px;

        .hero-content {
            margin-top: 0;
        }
    }

    @media(min-width: $small-desktop) {
        min-height: 400px;

        .has-mobile-img {
            padding: 0 $horizontal-space;

            .image-bg-header {
                height: 440px;
                border-bottom-left-radius: 160px;
            }
        }

        .hero-content {
            margin-top: 200px;
        }


        &.has-img {
            min-height: 540px;

            .hero-content {
                margin-top: 180px;
            }
        }

        &.home-header {
            min-height: 680px;
        }
    }

    .hero-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.has-mobile-img {
        @media(max-width: #{$small-desktop - 1}) {
            .hero-img.desktop {
                display: none;
            }
        }
    }

    &.has-desktop-img {
        @media(min-width: $small-desktop) {
            .hero-img.mobile {
                display: none;
            }
        }
    }


    .hero-content {
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        max-width: 700px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;


        p {
            @include font-size(16);
            letter-spacing: 0.6px;
        }

        h2, h3, h4, p {
            opacity: .7;
        }

        h1, p {
            color: black;
        }

        .button {
            margin-top: 40px;
            align-self: center;
        }
    }

    .explore {
        letter-spacing: 0.86px;
        opacity: .7;
    }



    &.dark {

        .hero-content {
            h1, h2, h3, h4, p {
                color: white;
            }
        }


        .explore {
            color: white;

            .wrap {
                color: white;
            }
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgb(0,0,0);
            background: -moz-linear-gradient(180deg, rgba(0,0,0,0.85) 0%, rgba(0,0,0,0) 100%);
            background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.85) 0%, rgba(0,0,0,0) 100%);
            background: linear-gradient(180deg, rgba(0,0,0,0.85) 0%, rgba(0,0,0,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
            z-index: 1;
        }
    }


    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 2;
    }
}

.explore {
    position: absolute;
    bottom: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    letter-spacing: 0.86px;

  .wrap {
    height: 16px;
    width: 16px;
    text-align: center;
    position: absolute;
    bottom: -30px;

  }

  svg {
    
    width: auto;
  }

  .wrap svg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 16px;
    height: 16px;
  }

  .bounce {
    animation-iteration-count: infinite;
    animation-duration: 1.5s;
    animation: bounce 3.6s ease infinite;
    transform-origin: 50% 50%;
  }

  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    5.55556% {
      transform: translateY(0);
    }
    11.11111% {
      transform: translateY(0);
    }
    22.22222% {
      transform: translateY(-5px);
    }
    27.77778% {
      transform: translateY(0);
    }
    33.33333% {
      transform: translateY(-5px);
    }
    44.44444% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
    }
  }
}




.videogallery .hero-content {
      h1, p {
        color: white;
        
      }
    
   

    .explore {
      color: white;

      .wrap {
        color: white;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgb(0,0,0);
      background: -moz-linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
      background: -webkit-linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
      background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
      z-index: 1;

    }
  }