﻿.quantity {
    display: inline-block;

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    &.buttons_added {
        text-align: left;
        position: relative;
        white-space: nowrap;
        vertical-align: top;

        input {
            display: inline-block;
            margin: 0;
            vertical-align: top;
            box-shadow: none;
            @include font-size(16);
        }

        .minus, .plus {
            @include font-size(20);
            height: 48px;
            width: 48px;
            background-color: #ffffff;
            border: 1px solid #e1e1e1;
            cursor: pointer;

            &:hover:not([disabled]) {
                background: #eeeeee;
            }

            &[disabled] {
                background: #eeeeee;
                opacity: .4;

                &:hover {
                    cursor: not-allowed;
                }
            }

            &:focus {
                outline: none;
            }
        }

        .minus {
            border-right: 0;
        }

        .plus {
            border-left: 0;
        }

        .input-text {
            &.qty {
                width: 48px;
                height: 48px;
                padding: 0 5px;
                text-align: center;
                background-color: transparent;
                border: 1px solid #e1e1e1;
            }
        }
    }
}
