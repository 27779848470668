﻿.basket-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 0px;

    .basket {
        background-color: $header-basket-bg;
        position: fixed;
        height: auto;
        top: 63px;
        width: 100%;
        padding: $grid-spacing;
        display: none;
        box-shadow: 0 32px 44px 0 rgba(0, 0, 0, 0.15);

        @media(min-width: $breakpoint) {
            padding: 0 40px;
            top: 40px;
            width: 386px;
            right: 114px;
            overflow-x: hidden;
            z-index: 9;
        }


        .basket-error {
            color: #dd1515;
            @include font-size(14);
        }

        .basket-items {
            max-height: 400px;
            overflow-y: auto;
            margin-bottom: 10px;
            border-bottom: 1px solid $border-basket;
            padding: 16px 16px 0;

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-button {
                width: 68px;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background: #d8d8d8;
                border: 1px solid transparent;
                border-radius: 3.5px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #000;
            }



            ul {
                padding: 0;
                margin: 0;

                li {
                    list-style: none;
                }
            }
        }

        .basket-item {
            padding-bottom: 24px;

            .basket-item-wrapper {
                display: flex;

                &.basket-item-content {

                    .basket-item-details {
                        .basket-item-name {
                            @include font-size(14,18);
                            padding-bottom: 6px;
                        }

                        .basket-item-ref {
                            @include font-size(12,24);
                            padding-bottom: 6px;
                            letter-spacing: .4px;
                            opacity: .7;
                        }


                        .basket-item-price {
                            @include font-size(14,24);
                            padding-bottom: 6px;
                            letter-spacing: .7px;
                        }

                        .basket-item-update {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 12px 0;

                            .quantity {
                                display: flex;
                                margin-right: $grid-spacing;

                                &.buttons_added {
                                    input {
                                        @include font-size(12,24);
                                        width: 32px;
                                        height: 32px;
                                    }

                                    input::-webkit-outer-spin-button,
                                    input::-webkit-inner-spin-button {
                                        -webkit-appearance: none;
                                        margin: 0;
                                    }

                                    input[type=number] {
                                        -moz-appearance: textfield;
                                    }
                                }
                            }


                            .basket-item-remove a {
                                @include font-size(12, 24);
                                letter-spacing: .6px;
                                text-decoration: none;
                                color: $black;
                                opacity: .7;
                                display: flex;
                                align-items: center;
                                margin-bottom: 0;

                                .icon-remove {
                                    width: 10px;
                                    height: 11px;
                                    margin-right: 10px;
                                    opacity: .7;
                                }
                            }
                        }

                        .basket-item-msg {
                            @include font-size(10);
                            letter-spacing: .5px;
                            display: flex;
                            align-items: center;
                            background-color: $success-message-bg;
                            color: $success-message-txt;
                            padding: 12px;
                            margin: 16px 0 24px;
                            display: none;

                            svg {
                                margin-right: 10px;
                                width: 12px;
                                height: 10px;

                                g {
                                    fill: $success-message-txt !important;
                                }
                            }
                        }
                    }

                    .basket-item-image {
                        width: 64px;
                        height: 64px;
                        min-width: 64px;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center center;
                        margin-right: 20px;
                        border: 1px solid $border-basket;
                        border-bottom-right-radius: 40px;
                    }
                }

                &.basket-item-pricing {
                    width: 100px;


                    @include font-size(14);

                    .basket-item-quantity {
                        margin-right: 10px;
                    }
                }
            }
        }

        .basket-controls {
            .basket-total {
                @include font-size(16,24);
                letter-spacing: .53px;
                text-align: right;
                border-bottom: 1px solid $border-basket;
                padding: 10px 0 16px;
                margin-bottom: 28px;

                .basket-total-txt {
                    margin-right: 3px;
                }
            }

            .basket-buttons {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                button, a {
                    @include font-size(10,16);
                    letter-spacing: 1.6px;
                    height: 40px;
                    padding: 11px 24px;
                    border-radius: 20px;
                    text-transform: uppercase;
                }

                .basket-close {
                    background: rgba(216,216,216,.6);
                    border: none;
                    opacity: .6;
                    min-width: 91px;
                    margin-right: 8px;

                    &:hover {
                        background: $black;
                        transition: $duration $easing;
                        color: $white;
                        opacity: 1;
                    }
                }

                .basket-button {
                    @include font-size(10,18);
                    margin: 0;
                    background-color: #1b1b1b;
                    min-width: 138px;
                    color: $white;
                }
            }
        }

        .basket-empty-wrapper {
            padding: 1.5rem;

            h4 {
                text-align: center;
                padding-bottom: 1rem;
            }

            .basket-buttons {
                justify-content: center;

                button {
                    margin: 0;
                }
            }
        }
    }

    @media(min-width: $min-desktop) {
        .basket {
            padding: 40px;
        }
    }

    @media(min-width: $max-desktop) {
        padding: 0 40px;
    }
}

.basket-container {

    table {
        text-align: left;
        overflow-x: initial;


        td, th {
            min-width: initial;
        }

        .basket-title {
            min-width: 325px;
        }

        thead {

            tr {
                th:first-child {

                    @media(min-width: $small-desktop) {
                        width: 60%;
                    }
                }
            }
        }
    }

    .sub-heading {
        color: $text-light;

        @media(min-width: $small-desktop) {
            margin-bottom: 0;
        }

        &._mobile {

            @media(min-width: $small-desktop) {
                display: none;
            }
        }
    }

    .basket-empty-wrapper {

        h2 {
            text-align: center;
            margin-bottom: 40px;
        }

        .basket-empty-cta {

            a {
                width: fit-content;
                margin: 0 auto;

                &:hover > span {
                    color: $white;
                    z-index: 9;
                }
            }

            @media(min-width: $min-desktop) {
                width: 50%;
                margin: 0 auto;
            }
        }
    }

    form {
        margin-left: 0;

        input {
            margin-bottom: 0px;
            padding-bottom: 0.25rem;
        }

        .shopping-cart {
            display: block;
            width: 100%;

            @media(min-width: $small-desktop) {
                display: table;
            }

            .options-title {

                @media(min-width: $small-desktop) {
                    display: none;
                }
            }

            .text-right {
                text-align: left;

                @media(min-width: $min-tablet) {
                    text-align: right;
                }
            }

            .text-center {
                text-align: center;
            }

            thead {
                display: none;

                @media(min-width: $small-desktop) {
                    display: table-row-group;
                }

                tr:first-child {
                    background-color: transparent;
                    font-weight: normal;
                }


                th {
                    @include font-size(12,22);
                    color: $black;
                    padding-bottom: 10px;
                    letter-spacing: .6px;
                    border: none;
                    border-bottom: 1px solid $border-basket;

                    .pl-6 {
                        padding-left: 1.5rem;
                    }
                }
            }

            tbody {
                display: block;

                @media(min-width: $small-desktop) {
                    display: table-row-group;
                }

                @media(max-width: $small-desktop) {


                    td {
                        width: 100% !important;
                        display: block !important;

                        @media(min-width: $small-desktop) {
                            width: auto !important;
                            display: table-cell !important;
                        }
                    }
                }

                tr {
                    background-color: transparent;
                    padding: 2rem 0;

                    @media(max-width: $small-desktop) {
                        display: flex;
                        flex-direction: column;
                    }

                    &.basket-items {
                        border-bottom: 1px solid $border-basket;

                        .desktop {

                            @media(max-width: $small-desktop) {
                                padding-left: 116px;
                            }
                        }
                    }
                }

                td {
                    @include font-size(14,20);
                    padding: 0 15px 0 0;
                    border: none;

                    @media(min-width: $small-desktop) {
                        padding: 2rem 0 2.25rem 0;
                    }

                    &.basket-price {
                        @include font-size(16);
                        color: $black;
                        letter-spacing: .53px;
                        margin-bottom: 22px;

                        span {
                            @include font-size(12);
                            letter-spacing: .6px;
                            display: inline-block;
                            margin-right: 29px;

                            @media(min-width: $small-desktop) {
                                display: none;
                            }
                        }

                        @media(min-width: $small-desktop) {
                            text-align: right;
                        }
                    }

                    &.basket-total {
                        padding-right: 0;
                        text-align: right;

                        .basket-remove {
                            @include font-size(12,17);
                            color: $text-opacity;
                            letter-spacing: .6px;
                            display: flex;
                            align-items: center;

                            svg {
                                height: 12px;
                                width: 12px;
                                margin: 0 10px 0 0;
                                opacity: .7;
                                vertical-align: middle;
                            }

                            span {
                                display: block;
                            }

                            @media(min-width: $small-desktop) {
                                margin-bottom: 0;
                                justify-content: flex-end;
                            }
                        }

                        @media(max-width: $small-desktop) {
                            text-align: left;
                        }

                        @media(min-width: $small-desktop) {
                            width: 6%;
                        }
                    }

                    &.basket-qty {
                        margin-bottom: $grid-spacing;

                        .quantity {
                            display: flex;

                            @media(min-width: $small-desktop) {
                                justify-content: center;
                            }
                        }

                        @media(min-width: $small-desktop) {
                            width: 18%;
                        }
                    }

                    &.basket-option-sm {

                        @include font-size(16,26);
                        color: $black;

                        p {
                            padding-left: 7rem;
                        }


                        @media(min-width: $small-desktop) {
                            display: none;
                        }
                    }

                    .items-start {
                        align-items: flex-start;
                    }


                    .flex {
                        display: flex;

                        .basket-remove {
                            color: $black;
                            @include font-size(16,21);
                            display: flex;
                            align-items: center;
                            text-decoration: none;

                            svg {
                                height: 16px;
                                width: 16px;
                                margin: 0 10px 6px 0;
                            }
                        }

                        .flex-shrink-0 {
                            width: 96px;
                            height: 96px;
                            min-width: 96px;
                            background-size: cover;
                            background-repeat: no-repeat;
                            background-position: center center;
                            margin-right: 1.75rem;

                            &.shopping-cart__product-image {
                                border: solid 1px $lighter-grey;
                                border-bottom-right-radius: 60px;

                                @media(min-width: $small-desktop) {
                                    border-bottom-right-radius: 120px;
                                }
                            }

                            @media(min-width: $small-desktop) {
                                width: 224px;
                                height: 224px;
                            }
                        }

                        .shopping-cart__product-name {
                            display: block;
                            @include font-size(16,28);
                            margin-bottom: 10px;
                            text-decoration: none;
                            text-align: left;

                            @media(min-width: $small-desktop) {
                                @include font-size(24,32);
                            }
                        }

                        .shopping-cart__product-model {
                            @include font-size(10);

                            @media(min-width: $small-desktop) {
                                @include font-size(12,24);
                            }
                        }

                        .shopping-cart__product-info {

                            @media(min-width: $min-mobile-landscape) {
                                width: 50%;
                            }
                        }

                        .shopping-cart__cart-mobile {

                            .shopping-cart__product-price {
                                @include font-size(16);
                                color: $black;
                                letter-spacing: .53px;
                                margin-bottom: 22px;

                                span {
                                    @include font-size(12);
                                    letter-spacing: .6px;
                                    display: inline-block;
                                    margin-right: 29px;
                                }
                            }

                            .shopping-cart__product-qty {
                                margin-bottom: $grid-spacing;

                                .quantity {
                                    display: flex;
                                }
                            }

                            .shopping-cart__product-remove {

                                @media(min-width: $small-desktop) {
                                    margin-top: 20px;
                                }

                                .basket-remove {
                                    @include font-size(12,17);
                                    color: $top-nav-color;
                                    letter-spacing: .6px;
                                    justify-content: left;

                                    svg {
                                        height: 12px;
                                        width: 12px;
                                        margin: 0 5px 0 0;
                                        opacity: .7;
                                    }
                                }
                            }

                            @media(min-width: $small-desktop) {
                                display: none;
                            }
                        }

                        .basket-product-detail {
                            text-transform: uppercase;
                            @include font-size(13);
                            letter-spacing: 2.32px;
                            color: $black;

                            &.basket-option {
                                display: none;

                                @media(min-width: $small-desktop) {
                                    display: block;
                                }
                            }
                        }

                        .shopping-cart__product-description {
                            @include font-size(16,26);
                            color: $black;
                            margin: 0;
                            font-family: 'HelveticaNeueLTStd-Lt';
                            margin-bottom: 1rem;
                        }

                        .shopping-cart__remove {
                            text-decoration: underline;
                            margin-top: .75rem;
                            display: inline-block;

                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }

                    .shopping-cart__quantity-input {
                        text-align: center;
                        color: $grey-on-white;
                        @include font-size(16,26);

                        @media(max-width: $small-desktop) {
                            padding: 0;
                        }
                    }
                }
            }

            tfoot {

                & tr, td {
                    padding: 0;
                    background-color: transparent;
                    border: none;

                    @media(max-width: $small-desktop) {
                        display: block;
                    }
                }

                & > tr {
                    display: flex;

                    @media(min-width: $small-desktop) {
                        display: revert;
                    }

                    td {
                        flex: 1;

                        @media(min-width: $min-tablet) {
                            &:first-child {
                                min-width: 80%;
                            }
                        }
                    }

                    td:not(:first-child) {
                        &.text-right {
                            text-align: right;
                        }
                    }
                }

                .vat-wrapper {
                    td:first-child {

                        @media(min-width: $small-desktop) {
                            padding-right: $vertical-space;
                        }
                    }
                }

                @media(max-width: $small-desktop) {
                    display: block;
                }

                .basket-vat {
                    @include font-size(12,24);
                    text-transform: uppercase;
                    color: $black;
                    letter-spacing: .6px;
                    padding: 2rem 0 0;

                    &.cost {
                        letter-spacing: .4px;
                    }
                }




                .shopping-cart__subtotal-row {
                    td {
                        @include font-size(16,24);
                        letter-spacing: .53px;
                        color: $black;
                        padding: 1rem 0 0;
                    }

                    td:first-child {

                        @media(min-width: $small-desktop) {
                            padding-right: $vertical-space;
                        }
                    }
                }

                .discount-text {

                    td {
                        @include font-size(12,20);
                        padding: 3rem 0;
                        color: $text-opacity;

                        @media(min-width: $min-tablet) {
                            padding-left: 20%;
                        }
                    }
                }

                .shopping-cart__cta-row {

                    td {
                        padding-right: 0;
                        width: 100%;

                        @media(max-width: $small-desktop) {
                            display: block;
                        }
                    }

                    .shopping-cart__cta-row-inner {
                        display: flex;
                        justify-content: space-evenly;
                        flex-direction: column;
                        align-items: center;

                        @media(min-width: $min-mobile-landscape) {
                            flex-direction: row;
                            align-items: baseline;
                        }

                        @media(min-width: $max-tablet) {
                            justify-content: flex-end;
                            margin-right: 50px;
                        }



                        a.underline {
                            order: 2;


                            @media(min-width: $min-mobile-landscape) {
                                order: 1;
                            }

                            @media(min-width: $max-tablet) {
                                margin-right: 28px;
                            }
                        }

                        .cta-row-inner {
                            display: flex;
                            order: 1;

                            @media(min-width: $min-mobile-landscape) {
                                order: 2;
                            }

                            #basket-button {
                                &:hover {
                                    span {
                                        color: $white;
                                        z-index: 9;
                                    }
                                }
                            }

                            button {
                                margin: 1rem 0;

                                @media(min-width: $small-desktop) {
                                    margin: 0;
                                    margin-right: 1.75rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
