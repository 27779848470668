﻿/*.container-pod {
   
    .curver-border-bottom-left {
        border-radius: 0px 0px 0px 6rem;

        @media (min-width: $small-desktop) {
            border-radius: 0px 0px 0px 10rem;
        }
    }

    h2 {
        color: $white;

        @media (min-width: $max-desktop) {
            @include font-size(40,48);
        }
    }

    span, p {
        color: $content-txt-white;
    }

    .pod-cta {
        width: 194px;
        margin: 2.5rem 0;
    }
   
    

}*/


.pod-promo {
        background-color: $background-black;
        position: relative;
        display: flex;
        flex-direction: column;


            .__text {
                position: relative;
                z-index: 2;  
                order: 2;
                padding: 40px 64px;

                h2 {
                    color: $white;
                    margin-bottom: 30px;
                }
            }

            .promo-content-img {          
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                max-height: 375px;
                width: 285px;
                height: 158px;
                border-bottom-left-radius: $curve-mob;
                order: 1;
                align-self: flex-end;

                @media (min-width: $small-desktop) {
                    height: 300px;
                    width: 44%;
                }
               
            }

            @media(min-width: $max-tablet) {
                flex-direction: row;
                align-items: center;
                height: 100%;
                justify-content: space-between;

                .__text {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    order: 1;
                    max-width: 450px;
                    margin-left: 64px;
                   
                }

                .promo-content-img {    
                   border-bottom-left-radius: $curve-desktop;
                   order: 2;
                    align-self: flex-start;
                    height: 375px;
                    width: 45%;
                    margin-bottom: 64px;
                }
            }


        
    }