select {
    display: none;

    &:focus {
        border-bottom: 1px solid $black;
        outline: none;
    }

    &::placeholder, &::-webkit-input-placeholder, &:-ms-input-placeholder {
        color: $black;
        @include font-size(16,24);
        letter-spacing: .6px;
        opacity: .7;
        //font-family: 'GT-America-Extended-Light';
    }
}

.bv_mainselect {
    position: relative;
   width: 100%;
    border: 0;
    padding: 16px 16px 16px 0;


    .bv_atual {
        position: relative;
        border: 0;
        border-bottom: 1px solid $input-border;
        background: transparent;
        width: 100%;
        padding: 8px 40px 16px 0;
        opacity: .7;
        color: $black;
        @include font-size(16,24);
        letter-spacing: .6px;
        //font-family: 'GT-America-Extended-Light';
        border-radius: 0;

        .arrow {
            border: solid $black;
            border-width: 0 1px 1px 0;
            position: absolute;
            right: 8px;

            &.down {
                margin-top: 6px;
            }

            &.up {
                margin-top: 12px;
            }
        }
    }

    .bv_ul_inner {
        text-align: center;
        background-color: #2c2c2c;
        border-radius: 0;
        margin-top: 0;
        border: none;
        z-index: 999;

        &.bv_ul_mobile {
            overflow: auto;
        }

        .bv_disabled {
            background-color: #3b3b3b !important;
        }

        > li {

            &:first-child{
                display: none !important;
            }
            color: $sub-nav-color;
            padding: $grid-spacing;
            cursor: pointer;
            user-select: none;
            letter-spacing: .6px;

            &:hover {
                background-color: #3b3b3b;
            }
        }
    }
}

.form-pod {
    
    

    .form-wrapper {
        background-color: $pale-grey;
        border-bottom-right-radius: 160px;
        padding: 64px $horizontal-space-mob;
    }

    .form-pod-header {
        text-align: center;
        margin-bottom: $spacing;

        @media(min-width: $max-tablet) {
            width: 50%;
            margin: 0 auto;
            padding: 0 32px 32px;
            max-width: 456px;
        }
    }

    .umbraco-forms-submitmessage {
        text-align: center;
        display: block;
        @include font-size(24);
        //font-family: 'GT-America-Extended-Light';
        max-width: 80%;
        margin: 0 auto;

        @media(min-width: $max-tablet) {
          @include font-size(32);
            max-width: 50%;
        }
    }
}

.umbraco-forms-fieldset {
    border: none;
    padding: 0;

    .umbraco-forms-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto;

        @media(min-width: $max-tablet) {
            max-width: 456px;
        }

        .umbraco-forms-field {
            margin-bottom: 30px;
            width: 100%;

            @media(min-width: $max-tablet) {
                max-width: 400px;
            }

            .field-validation-error,
            .parsley-errors-list.filled {
                display: block;
                color: $error-msg;
                background-color: $error-msg-bg;
                padding: 8px 8px 8px 32px;
                position: relative;
                font-family: 'GT-America-Expanded-Medium';
                font-size: 10px;
                margin-top: 10px;

                &:before {
                    position: absolute;
                    content: '';
                    background-image: url('/assets/img/icon-alert.svg');
                    width: 20px;
                    height: 20px;
                    background-repeat: no-repeat;
                    left: 8px;
                }
            }

            .parsley-errors-list {
                list-style-type: none;
            }


            .umbraco-forms-label {
                margin-left: 0;
                @include font-size(12,24);
                letter-spacing: .6px;
                //font-family: 'GT-America-Extended-Light';
                opacity: 0.7;
            }

            input[type=text],
            input[type=email] {
                border: 0;
                border-bottom: 1px solid $input-border;
                padding: 10px 0;
                background: transparent;
                width: 100%;
                padding: 16px 0;
                color: $black;
                @include font-size(16,24);
                letter-spacing: .6px;
                //font-family: 'GT-America-Extended-Light';

                @media(min-width: $max-tablet) {
                    width: 400px;
                }

                &:focus {
                    border-bottom: 1px solid $black;
                    outline: none;
                }

                &::placeholder, &::-webkit-input-placeholder, &:-ms-input-placeholder {
                    color: $black;
                    @include font-size(16,24);
                    letter-spacing: .6px;
                    opacity: .7;
                    // font-family: 'GT-America-Extended-Light';
                }
            }

            &.checkboxforsomeinformationrequest {
                display: flex;
                flex-direction: row-reverse;
                justify-content: flex-end;
                align-items: center;

                @media(min-width: $max-tablet) {
                    width: 400px;
                }

                > label {
                    margin-top: 12px;
                    margin-left: 24px;
                }

                .umbraco-forms-field-wrapper {

                    label {
                        margin-top: 7px;
                        position: relative;
                        padding-left: 13px;

                        input[type=checkbox] {
                            display: none;
                            position: absolute;
                            opacity: 0;
                            cursor: pointer;
                            height: 0;
                            width: 0;
                            order: 1;
                            margin-top: 3px;

                            &:checked ~ .checkmark:after {
                                display: block;
                            }
                        }

                        .checkmark {
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 24px;
                            width: 24px;
                            background-color: transparent;
                            border: 1px solid black;


                            &:after {
                                content: url(../../img/icon-tick.svg);
                                position: absolute;
                                display: none;
                                left: 4px;
                                top: 4px;
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }
                }
            }

            .checkbox,
            .checkboxlist {
                margin-top: 10px;
                display: flex;
                flex-direction: column;

                a {
                    text-decoration: underline !important;
                }

                label {
                    @include font-size(14);
                    font-weight: 400;
                    user-select: none;
                    display: block;
                    position: relative;
                    padding-left: 25px;
                    order: 2;
                    text-transform: none;
                    padding: 3px 32px;
                }

                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                    order: 1;
                    margin-top: 3px;

                    &:checked ~ .checkmark:after {
                        display: block;
                    }
                }

                .checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 24px;
                    width: 24px;
                    background-color: transparent;
                    border: 1px solid black;


                    &:after {
                        content: url(../../img/icon-tick.svg);
                        position: absolute;
                        display: none;
                        left: 4px;
                        top: 2px;
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }

        .multichoice, .radiobuttonlist, .singlechoice, .dataconsent {

            @media(min-width: $max-tablet) {
                width: 400px;
            }



            .umbraco-forms-field-wrapper {
                margin-top: 10px;

                .checkbox, .radiobuttonlist, .checkboxlist {

                    label {
                        letter-spacing: .6px;
                        text-transform: none;
                        @include font-size (12,20);
                        color: $black;
                        opacity: .7;
                        //font-family: 'GT-America-Extended-Light';
                        margin-top: 16px;
                    }
                }

                .radiobuttonlist {
                    display: flex;
                    flex-wrap: wrap;

                    label {
                        display: block;
                        position: relative;
                        padding-left: 35px;
                        margin-bottom: 12px;
                        cursor: pointer;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        width: 100%;

                        input {
                            position: absolute;
                            opacity: 0;
                            cursor: pointer;
                            height: 0;
                            width: 0;

                            &:checked ~ .checkmark:after {
                                display: block;
                            }
                        }

                        .checkmark {
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 24px;
                            width: 24px;
                            background-color: transparent;
                            border-radius: 50%;
                            border: 1px solid black;

                            &:after {
                                content: "";
                                position: absolute;
                                display: none;
                                left: 3px;
                                top: 3px;
                                width: 16px;
                                height: 16px;
                                border-radius: 50%;
                                background: black;
                            }
                        }
                    }
                }
            }
        }

        .longanswer {
            textarea {
                width: 100%;
                border-radius: 24px;
                padding: 16px 24px;
                border: none;
                @include font-size(16);
                color: $black;
                margin-top: 10px;
                min-height: 184px;
                background-color: $txt-area-bg;
                resize: vertical;

                &:focus {
                    border-color: $black;
                    outline: none;
                }
            }

            @media(min-width: $max-tablet) {
                width: 400px;
            }
        }

        .consentforstoringsubmitteddata {

            & > label {
                display: none;
            }

            .umbraco-forms-field-wrapper {
                .checkbox {

                    label {
                        @include font-size(10);
                    }

                    .checkmark {
                        &:after {
                            top: 4px;
                        }
                    }
                }
            }
        }
    }
}

.umbraco-forms-navigation {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    .button {
        &:before {
            background: $white;
            border-color: #000 #000 #000 transparent;
        }

        input[type=submit] {
            background: none;
            border: none;
            outline: 0;
            min-height: 47px;
            width: 100%;
            left: 0;
            position: absolute;
            color: transparent;
            z-index: 9999;
            padding: 0;

            &:focus, &:active {
                outline: 0;
            }
        }
    }
}

.form-pod {
  .umbraco-forms-navigation {
    .button {
      background-color: transparent;

        &:before {
            background: transparent;
        }
    }
  }
}

.download-form {
	display: none;
	position: fixed;
	height: 100vh;
	width: 100%;
	z-index: 10;
	align-items: flex-start;
	justify-content: center;
	background-color: rgba(0,0,0,0.6);
	top: 0;
	overflow-x: auto;

	&__inner {
    background-color: $white;
    padding: 40px;
    position: relative;
		margin: 20px;
		max-width: 800px;
		width: 100%;

    .form-pod-header {
      text-align: center;
    }
			
		&--close {
			cursor: pointer;
			position: absolute;
			border-radius: 50px;
			height: 40px;
			min-width: 40px;
			background-color: $light-grey;
			display: flex;
			align-items: center;
			justify-content: center;
			right: 20px;
			top: 20px;
		}
	}

	&.active {
		display: flex;
	}
}

.newsletter-pod {
    &__signup {
        form {
            .umbraco-forms-container {


                .umbraco-forms-field {

                    .field-validation-error,
                    .parsley-errors-list.filled {
                        background-color: $error-msg-bg;
                    }

                    input[type=text],
                    input[type=email] {
                        &::placeholder, &::-webkit-input-placeholder, &:-ms-input-placeholder {
                            color: $white;
                        }
                    }

                    &.email {
                        margin-bottom: 0;
                    }
                }

                .dataconsent {
                    label {
                        display: none;
                    }

                    .umbraco-forms-field-wrapper {
                        .checkbox {
                            & > label {
                                @include font-size(10,24);
                                color: $white;

                                a {
                                    color: $white;
                                    text-decoration: underline;
                                }

                                .checkmark {
                                    border: 1px solid $white;

                                    &:after {
                                        content: url(../../img/icon-tick-white.svg);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#success-message {
    display: none;
}





/* *****************************

BVSelect Jquery Plugin Styles
Developed by: Bruno Vieira

**************************** */
.bv_mainselect {
    color: #fff;
    padding: 8px 0;
    width: 100%;
    min-width: 300px;

}
.bv_atual {
    color: #535353;
    font-size: 11pt;
    padding: 10px;
    border: 2px solid #e1e1e1;
    border-radius: 6px;
    cursor: pointer
}
.bv_disabled {
    background-color: #e6e6e6!important;
    cursor: default!important;
    opacity: 0.5;
}
.bv_separator {
    font-weight: 700;
    color: #282828!important;
    cursor:default!important;
    background-color:transparent!important;
}
.bv_background {
    background-color: #fff
}
.arrow {
    border: solid grey;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    float: right;
    pointer-events: none;
}
.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin-top: 5px;
    transition: $duration $easing;
}
.down {
    transition: $duration $easing;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-top: 2px
}

.bv_ul_mobile {
   /* width: 80% !important;
    position: fixed !important;
    top: 5% !important;
    left: 10% !important;
    border: 0px !important;
    height: 90% !important;
    margin: 0px !important;
    overflow-y: scroll;
    z-index: 10 !important;
    max-height:100%!important;*/
}

.bv_ul_bg
{
  /*  position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.8); z-index: 9;*/
}

.bv_ul_mobile li
{
    padding:20px!important;
}


.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.bv_ul_inner {
    position: absolute;
    margin-top: 10px;
    padding: 0;
    display: none;
    border: 2px solid #e1e1e1;
    border-radius: 8px;
    display: none;
    z-index: 1;
    max-height: 400px;
    overflow-x: auto;
}

.bv_ul_inner li {
    cursor: pointer;
    list-style: none;
    color: #353535;
    padding: 5px 0 5px 9px;
    font-family: GT-America-Extended-Light;
    font-size: 10pt;
    padding: 12px 28px 10px 10px;
}

.bv_ul_inner i {
    color: #464646;
    font-size: 17pt;
    margin-right: 10px;
    float: left;
    margin-top: -1px;
    margin-left: 3px;
}

.bv_ul_inner li img{
    float: left;
    margin-right: 11px;
    position: relative;
    bottom: 6px;
    max-width: 30px; /* Change to fit your images */
}

.bv_ul_inner li input
{
    padding: 0px;
    border: 0px;
        border-bottom-color: currentcolor;
        border-bottom-style: none;
        border-bottom-width: 0px;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom-width: 0px;
    color: black;
    height: 38px;
    width: 100%;
    background-color: transparent;
    font-size: 10pt;
    font-weight: bold;
}
.bv_ul_inner li:hover {
    background-color: #eee
}
.nofocus
{
    background-color: rgba(255, 255, 255, 0.1) !important;
    padding: 0px !important;
    border-bottom: 2px solid #e1e1e1;
}
.innerinput
{
    padding: 0px 9px 0px 10px;
}
*:focus {
    outline: none!important;
}
