.breadcrumb {
  width: 100%;
  background-color: $pale-grey;
  padding: 24px 0;

  display: none;

  @media(min-width: $max-tablet) {
    display: block;
  }

  .container {
    ul {
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {

        @include font-size(12,24);

        span {

          &.breadcrumb-arrow {
            padding: 0 14px;

            svg {
              height: 8px;
            }
          }

          opacity: 0.7;
          &.active {
            opacity: 1;
            font-family: 'GT-America-Expanded-Medium';
          }
        }

        a {
          margin: 0;
        }
      }
    }
  }
}

.breadcrumb-no-bg {
  .breadcrumb {
    background-color: transparent;
    padding: 40px 0;
  }
}