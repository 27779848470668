.results {
  border-top: 1px solid $border;

  .result {
    padding: $spacing 0;
    border-bottom: 1px solid $border;

    .title {
      @include font-size(24);
      margin-bottom: $spacing-small;

      a {
      	//font-family: 'GT-America-Extended-Light';
      }
    } 

    p {
      opacity: .7;
      word-wrap: break-word;
    }

    a {
    	font-family: 'GT-America-Expanded-Medium';
      

      &:hover {
        text-decoration: underline;
      }
    }

  }

}


.results-page {
  display: flex;
  align-items: center;
  margin-right: 10px;

  @media(min-width: $max-tablet) {
    margin-right: 50px;
  }

  @media(min-width: $large-desktop) {
    margin-right: 0px;
  }

  .input-wrapper {

    background: $bg-light-grey;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    padding: 14px 0 14px 20px;
    width: calc(100% - 50px);
     
    @media(min-width: $small-desktop) {
      padding: 24px 140px 24px 54px;
    }

     &:after {
        content: '';
        width: 20px;
        height: 1px;
        background: $black;        
        position: absolute;
        top: 50%;
        right: 100px;
        z-index: 2;
        filter: invert(50%);

        @media(min-width: $small-desktop) {
          width: 56px;
          right: 85px;
        }
   
    }
  }

  input[type=text] {
    border:0;
    outline:0;
    background-color: transparent;
    @include font-size(16);
    width: 100%;

    @media (min-width: $max-tablet) {
      @include font-size(24);
    }
  }

 .submit-wrapper {
    background: $btn-hover-grey;
    height: 100%;
    width: 88px;
    transform: skewX(-45deg);
    display: flex;
    align-items: center;
    position: absolute;
    right: 20px;
    transition: $duration $easing;

    @media(min-width: $small-desktop) {
      height: 80px;
      width: 110px;
      right: 0;
    }
 

    input[type=submit] {
      transform: skewX(45deg);
      border:0;
      outline:0;
      background:transparent url(../../img/icon-search-black.svg) no-repeat center;
      background-size: 24px;
      font-size: 0;
      text-indent: -9999px;
      width: 100%;
      height: 100%;
    }

    &:hover {
      background: black;
      input[type=submit] {
          background:transparent url(../../img/icon-search-white.svg) no-repeat center;
           background-size: 24px;
      }
    }
  }

}
