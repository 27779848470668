﻿.pac-container.pac-logo {
    &:after {
        background-image: none !important;
    }
}

.find-a-showroom {

    .no-results-wrapper {
        display: none;

        .showroom-no-results {
            text-align: center;
            max-width: 800px;
            margin: 0 auto;
            padding: 0 32px;


            a {
                width: fit-content;
                margin: 32px auto 0 auto;
            }

            @media(min-width: $max-tablet) {
                padding: 0 64px;
            }
        }

        .results-form {
            position: relative;

            @media(min-width: $small-desktop) {
                &:before {
                    content: "";
                    background: #f5f5f5;
                    width: 50%;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                }
            }

            .container {
                &.no-results-form {
                    background: $bg-light-grey;
                    padding: 0;
                    border-bottom-right-radius: $curve-mob;

                    h2 {
                        padding-top: 32px;
                        margin: 0;
                        text-align: center;

                        @media(min-width: $small-desktop) {
                            padding-top: 60px;
                        }
                    }

                    @media(min-width: $small-desktop) {
                        max-width: 1000px;
                        border-bottom-right-radius: $curve-desktop;
                    }

                    form {
                        padding: 32px;

                        @media(min-width: $small-desktop) {
                            padding: 40px 60px 60px;

                            .col-md-12 {
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: flex-start;
                                margin: 20px 0 0;
                                align-items: flex-start;
                                max-width: 100%;
                                flex-direction: row;
                                align-items: flex-start;
                                justify-content: space-between;

                                @-webkit-keyframes rotate {
                                    100% {
                                        -webkit-transform: rotate(360deg);
                                    }
                                }

                                @keyframes rotate {
                                    100% {
                                        -webkit-transform: rotate(360deg);
                                    }
                                }

                                .umbraco-forms-field {
                                    width: 100%;
                                    margin-bottom: 30px;

                                    @media(min-width: $max-tablet) {
                                        padding-right: 30px;
                                    }



                                    @media(min-width: $max-tablet) {
                                        width: 50%;

                                        &.longanswer,
                                        &.dataconsent,
                                        &.singlechoice,
                                        &.multiplechoice,
                                        &.titleanddescription,
                                        &.recaptcha2 {
                                            width: 100%;
                                            padding-right: 0;
                                            max-width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    .showroom-filter {
        background-color: $pale-grey;
        border-bottom-right-radius: $curve-mob;
        padding: $spacing;

        h2 {
            text-align: center;
            margin-bottom: 36px;
        }

        .filter-wrapper {
            display: flex;
            flex-direction: column;

            @media(min-width: $max-tablet) {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                margin: 0 auto;
            }

            @media(min-width: $small-desktop) {
                width: 85%;
            }

            @media(min-width: $max-desktop) {
                width: 75%;
            }

            fieldset {
                border: none;
                padding: 0;
                margin: 0;

                @media(min-width: $max-tablet) {
                    width: calc(50% - 30px);
                }

                .validation-msg {
                    @include font-size(12,16);
                    display: none;
                    margin: 24px 0;
                    color: #dd1515;
                    background-color: rgba(221,21,21,.1);
                    padding: 0.75rem;
                    max-width: 400px;
                }
            }

            .filter-input {
                display: flex;
                flex-direction: column;

                & > div {
                    display: flex;
                    flex-direction: column;
                }

                label {
                    letter-spacing: .6px;
                    opacity: .7;
                    margin-bottom: 16px;
                }

                input {
                    @include font-size(16,20);
                    color: $black;
                    border: 0;
                    border-bottom: 1px solid $black;
                    padding: 10px 0;
                    background: transparent;
                    max-width: 400px;
                }

                .location-marker {
                    flex-direction: row;
                    align-items: center;
                    margin-top: 25px;


                    &:hover span {
                        cursor: pointer;
                    }

                    span {
                        @include font-size(12,15);
                        letter-spacing: .04em;
                        font-family: 'GT-America-Expanded-Medium';
                        margin-left: 7px;
                    }

                    svg {
                        width: 32px;
                        height: 28px;
                    }
                }
            }

            .filter-checkboxes {
                margin-top: 41px;

                @media(min-width: $max-tablet) {
                    margin-top: 0;
                }

                span {
                    @include font-size(12,15);
                    letter-spacing: .6px;
                    opacity: .7;
                }

                .checkbox-wrapper {
                    margin-top: 20px;

                    .checkboxlist {
                        margin-top: 10px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;

                        label {
                            @include font-size(16);
                            user-select: none;
                            display: block;
                            position: relative;
                            padding-left: 25px;
                            order: 2;
                            text-transform: none;
                            padding: 0 36px;
                            margin-bottom: 22px;
                        }

                        input {
                            position: absolute;
                            opacity: 0;
                            cursor: pointer;
                            height: 0;
                            width: 0;
                            order: 1;
                            margin-top: 3px;

                            &:checked ~ .checkmark:after {
                                display: block;
                            }
                        }

                        .checkmark {
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 24px;
                            width: 24px;
                            background-color: transparent;
                            border: 1px solid rgba(0, 0, 0, .3);

                            &.active-border {
                                border: 1px solid black;
                            }


                            &:after {
                                content: url(../../img/icon-tick.svg);
                                position: absolute;
                                display: none;
                                left: 4px;
                                top: 4px;
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }
                }
            }

            .buttons {
                margin: 36px 0 36px;

                @media(min-width: $max-tablet) {
                    margin: 36px 0;
                    width: calc(100% - 30px);
                }

                .button {
                    span {
                        @include font-size(11,14);
                        letter-spacing: .16em;
                        opacity: 1;
                    }

                    @media(min-width: $max-tablet) {
                        margin: 0 auto;
                    }

                    &:disabled, &[disabled] {
                        opacity: .6;
                        cursor: not-allowed;
                    }
                }
            }
        }

        @media(min-width: $max-tablet) {
            border-bottom-right-radius: $curve-desktop;
        }
    }


    .showroom-map {
        &.hidden {
            display: none;
        }

        &.container {
            padding: 0;
            margin: 0;

            @media(min-width: $max-tablet) {
                margin: 0 auto;
                padding: 0 32px;
            }
        }

        h2 {
            margin-bottom: 48px;
            text-align: center;
        }
    }

    .showroom-results {
        margin-bottom: 48px;

        @media(min-width: $max-tablet) {
            margin-bottom: 80px;
        }

        .showroom {
            .nearest-title, .secondary-title {
                text-align: center;
            }

            .nearest-results {
                border-bottom: 1px solid $border-basket;
                padding: 31px 8px;

                &:nth-of-type(1), &:nth-of-type(3) {
                    border-top: 1px solid $border-basket;
                }

                @media(min-width: $max-tablet) {
                    padding: 37px 20px;
                }

                .accordion-minus {
                    display: none;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .accordion-plus {
                    svg {
                        transition: transform 0.2s ease-in;

                        &:hover {
                            cursor: pointer;
                            transform: rotate(45deg);
                        }
                    }
                }

                &.first-item {
                    .accordion-minus {
                        display: inline;
                    }

                    .accordion-plus {
                        display: none;
                    }
                }
            }

            &.premier-showroom {
                .location-title {
                    .location-title-text {

                        span {

                            &.prem-showroom-label {
                                color: $gold;
                                opacity: 1;

                                &:before {
                                    content: "• ";
                                    color: $gold;
                                }
                            }
                        }
                    }

                    .btn-toggle {
                        .accordion-minus {
                            display: none;
                        }
                    }
                }

                &.first-item {
                    .accordion-minus {
                        display: inline;
                    }

                    .accordion-plus {
                        display: none;
                    }
                }
            }

            .secondary-title {
                margin: 64px 0 32px;

                @media(min-width: $max-tablet) {
                    margin: 64px 0 48px;
                }
            }

            .location-title {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                & > div:first-child {
                    display: flex;
                    align-items: center;
                }

                .location-title-icon {
                    svg {
                        width: 32px;
                        height: 39px;

                        @media(min-width: $max-tablet) {
                            width: 44px;
                            height: 53px;
                        }
                    }
                }

                .location-title-text {
                    padding: 0 13px;

                    @media(min-width: $max-tablet) {
                        padding: 0 0 0 15px;
                    }

                    p {
                        color: $black;
                        margin: 0;
                        letter-spacing: initial;
                    }

                    span {
                        @include font-size(10,24);
                        letter-spacing: .4px;
                        opacity: .6;

                        &.prem-showroom-label {
                            color: $gold;
                            opacity: 1;

                            &:before {
                                content: "• ";
                                color: $gold;
                            }
                        }

                        @media(min-width: $max-tablet) {
                            @include font-size(12,24);
                        }
                    }
                }
            }

            .location-details {
                display: none;

                &.first-item {
                    display: block;
                }

                .location-details-wrapper {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-top: 43px;
                    flex-wrap: wrap;

                    @media(min-width: 1080px) {
                        flex-wrap: initial;
                    }

                    .showroom-details {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        @media(min-width: $max-tablet) {
                            flex-wrap: initial;
                        }

                        .showroom-details-title {
                            @include font-size(14,18);
                            colour: $black;
                            opacity: 1;
                        }

                        .location-address {
                            width: 50%;

                            @media(min-width: $max-tablet) {
                                padding-right: 60px;
                                width: initial;
                            }
                        }

                        .location-phone {
                            width: 45%;
                            padding-left: 30px;

                            @media(min-width: $max-tablet) {
                                padding: 0 60px;
                                width: initial;
                            }
                        }

                        .location-products {
                            width: 100%;
                            margin-top: 24px;

                            @media(min-width: $max-tablet) {
                                padding: 0 60px;
                                width: initial;
                                margin-top: 0;
                            }
                        }

                        p, a, span {
                            @include font-size(12,24);
                            letter-spacing: .4px;
                            opacity: .6;
                        }
                    }

                    .contact-links {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 100%;
                        margin-top: 40px;

                        @media(min-width: 1080px) {
                            align-items: end;
                            margin-top: 0px;
                            width: initial;
                            text-align: right;
                        }

                        a {
                            @include font-size(12,15);
                            margin-bottom: 20px;
                            letter-spacing: .4px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}
.premier-content {
    background: $white;
    max-width: 640px;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 40px;
    width: 80%;

    @media(min-width: $max-tablet) {
        padding: 100px;
        width: 100%;
    }

    p {
        @include font-size(12,24);
        letter-spacing: .4px;
    }

    button {
        display: none;
    }

    .close-premier-popup {
        background-color: $pale-grey;
        width: 38px;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        position: absolute;
        top: 12px;
        right: 12px;

        @media(min-width: $max-tablet) {
            width: 48px;
            height: 48px;
            top: 24px;
            right: 24px;
        }

        svg {
            transition: transform 0.2s ease-in;

            &:hover {
                transform: rotate(45deg);
            }
        }

        &:hover {
            cursor: pointer;
        }
    }
}
