section.gallery {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -130px;
    left: 0;
    background: url(../../img/bg-arch.svg) no-repeat top left;
    background-size: 100% auto; 
    width: 329px;
    height: 621px;
    z-index: 0;
  }
}




.filter-gallery {
  position: relative;
  margin-bottom: 24px;
  z-index: 2;

  @media (min-width: $max-tablet) {
    margin-bottom: 40px;
  }

  form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    fieldset {
      border: 0;
      padding: 0;
  
      label {
        //font-family: 'GT-America-Extended-Light';
        letter-spacing: 0.6px;
        opacity: 0.7;
      }
  
      .bv_mainselect {
        padding: 8px 0px 24px 0;
        min-width: 300px;

        @media (min-width: $small-desktop) {
          min-width: 360px;
        }
      }
    }

    @media (min-width: $max-tablet) {
      align-items: center;
      flex-direction: row;
      fieldset {
        .bv_mainselect {
          padding-right: 24px;
        }
      }
    }
  }

}

.gallary-page {

  .sub-heading {
    margin-bottom: 24px;
  }
}


.listing.gallery {
  justify-content: space-between;
  z-index: 1;

  .listing-item {
    z-index: 1;
    margin-bottom: 48px;

    @media (min-width: $max-tablet) {
      width: calc(50% - 12px);
    }

    .img {
      height: 310px;

      .img-holder {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .video-link {
        position: relative;
        width: 100%;
        height: 100%;
        display: block;
      }

      .video-icon {
        width: 78px;
        height: 78px;
        border-radius: 50%;
        color: black;
        background: white;
        border: 1px solid white;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: $duration $easing;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;

        svg {
          width: 22px;
          height: 24px;
        }
      }

      &:hover {

        .video-icon {
          color: white;
          background: transparent;
        }

      }
    }

    .content {
      align-items: center;


      .title {
        @include font-size(16);
      }

      .categories {
        display: flex;
        align-items: center;

        span {
          opacity: 0.7;
          display: flex;
          position: relative;
          padding-right: 24px;
          margin-right: 24px;

          &:after {
            content: '';
            width: 1px;
            height: 20px;
            background-color: black;
            transform: rotate(45deg);
            position: absolute;
            right: 0;
            opacity: 0.3;
          }

          &:last-child {
            margin-right: 0;
            padding-right: 0;

            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
}

.videogallery {
  background: $dark-theme-bg;
  color: white;

  .buttons {
    display: flex;

    // Only if back button..
    .button {
      padding: 19px 20px 17px 44px;

      .btn-text {
        position: relative;

        &:before {
          content: '';
          background-image: url('/assets/img/icon-arrow-left.png');
          width: 14px;
          height: 14px;
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          top: -3px;
          left: -18px;
        }

        &.hide-arrow {
          &:before {
            background-image: none;
          }
        }

        
      }

      &:hover {

          .btn-text:before {
            background-image: url('/assets/img/icon-arrow-left-white.svg');
          }
        }
    }
  }

  .sub-heading {
    opacity: .7;
    z-index: 1;

    &:before {
      background-color: white;
    }
  }

  .bv_mainselect .bv_atual {
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, .2);

    .arrow {
      border-color: #fff;
    }
  }

  .listing.gallery {
    .content {
      p {
        color: $sub-nav-color;
      }

      .categories {
        display: flex;
        align-items: center;

        span {

          &:after {
            content: '';
            background-color: #fff;
            opacity: 0.7;
          }
        }
      }
    }
  }
}