
@font-face {
  font-family: 'GT-America-Extended-Light';
  src: url('/assets/src/css/fonts/GT-America-Extended-Light.woff') format('woff'), 
  url('/assets/src/css/fonts/GT-America-Extended-Light.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'GT-America-Expanded-Medium';
  src: url('/assets/src/css/fonts/GT-America-Expanded-Medium.woff') format('woff'), 
  url('/assets/src/css/fonts/GT-America-Expanded-Medium.woff2') format('woff2');
  font-display: swap;
}



p, ul, a {
  margin-bottom: $spacing-small;
//  font-family: 'GT-America-Extended-Light';

}


h1, h2, h3, h4, h5, h6, p, ul, ol {
  margin-top: 0;
  margin-bottom: 16px;
  font-weight: normal;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 1rem;
 // font-family: 'GT-America-Extended-Light';
  color: $black;
}

h1, .h1 {
  @include font-size(32);
  margin-bottom: 2rem;

  &.homepage {
    @include font-size(32);
  }

}

h2, .h2 {
  @include font-size(24);
  margin-bottom: 24px;
}

h3, .h3 {
  @include font-size(24,32);
}

h4, .h4 {
  @include font-size(18,24);
}

p {
    @include font-size(16,24);
    letter-spacing: 0.6px;
    color: $content-txt;
}

@media(min-width: $max-tablet) {

  h1, .h1 {
    @include font-size(40);
    margin-bottom: 2rem;

    &.homepage {
      @include font-size(56);
    }
  }

  h2, .h2 {
    @include font-size(32);
    // margin-bottom: 40px;
  }

  h3, .h3 {
    @include font-size(24);
  }

  h4, .h4 {
    @include font-size(16);
  }

}
 
.sub-heading   {

   @include font-size(12, 12);
   letter-spacing: .6px;
   display: flex;
   align-items: center;
    padding-left: 11px;
    margin-bottom: 16px;

    &:before {
      content: '';
      width: 1px;
      height: 32px;  
      background-color: black;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      margin-right: 24px;
    }

    &.semi-trans {
      opacity: .5;
    }

    &.white {

      opacity: .5;
      color: $white;

      &:before {
          background-color: white;
      }
    }
  }


