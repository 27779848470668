﻿.product-breadcrumb {
	display: none;

	@media(min-width: $small-desktop) {
		margin-bottom: 48px;
		display: block;
	}
}
.vendrcheckoutcheckoutsteppage {
	header {
		.icon {
			&.main-logo {
				@media (max-width: $breakpoint) {
					fill: #ffffff;
				}
			}
		}
	}
}

.checkout-container {
	margin-top: -2.5rem;

	.checkout-wrapper {

		@media(min-width: $small-desktop) {
			display: flex;
			flex-direction: row-reverse;
		}

		.order-summary-wrapper {
			background-color: $pale-grey;
			padding: 27px $spacing;
			margin-top: 31px;
			z-index: 9;
			position: relative;

			.rotate-svg {
				transform: rotate(180deg);
			}

			&.close-summary {
				z-index: 0;
			}

			@media(min-width: $small-desktop) {
				z-index: initial;
			}

			.hidden {
				display: none;

				@media(min-width: $small-desktop) {
					display: block;
					z-index: initial;
				}
			}

			@media(min-width: $small-desktop) {
				margin-top: 3rem;
				width: 40%;
				padding: 1rem 2.5rem;
				border-top-left-radius: 24px;
			}

			@media(min-width: $max-lg-desktop) {
				width: 20%;
				min-width: 400px;
				padding: 1rem 2.5rem;
				margin-top: 7rem;
			}

			.order-summary {
				@include font-size(12,24);
				color: $black;
				letter-spacing: .6px;
				font-family: 'GT-America-Expanded-Medium';
				text-decoration: none;

				@media(min-width: $small-desktop) {
					display: none;
				}

				&:hover {
					cursor: pointer;
				}

				svg {
					height: 8px;
					width: 12px;
					opacity: .7;
				}

				.summary-total {
					float: right;
				}
			}

			#order-summary {
				@media(min-width: $small-desktop) {
					display: block;
				}



				.discount-codes {
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					border-bottom: 1px solid $border;
					padding-bottom: 1rem;
					margin-bottom: 2rem;

					& > li {
						list-style: none;
						padding: 0.25rem 0;
						color: $text;
						@include font-size(14,18);
						text-decoration: none;
						letter-spacing: 2px;
						text-transform: uppercase;
						font-family: 'HelveticaNeueLTStd-Md';

						.basket-remove {
							display: flex;
							align-items: center;
							text-decoration: none;

							@include font-size(13);
							text-transform: none;

							svg {
								height: 16px;
								width: 16px;
								margin: 0 10px 6px 0;
							}
						}
					}
				}


				.order-summary-item-list {
					padding: 0;
					list-style-type: none;
					margin-top: $spacing;

					.order-list-items {
						list-style: none;
						border-bottom: 1px solid $border;

						.order-list-items-wrapper {
							padding: 2rem 0;
							display: flex;

							.checkout-item {
								display: flex;
								margin-right: 1rem;

								@media(min-width: $min-tablet) {
									width: 100%;
									margin-right: 0;

									.flex-order-details {
										width: 100%;
									}
								}

								.checkout-product-img {
									width: 80px;
									height: 80px;
									min-width: 80px;
									background-size: cover;
									background-repeat: no-repeat;
									background-position: center center;
									margin-right: 1rem;
									border-bottom-right-radius: 35px;

									@media(min-width: $min-tablet) {
										margin-right: 1.75rem;
									}
								}

								.checkout-product-name {
									@include font-size(16);
									color: $black;
								}

								.checkout-product-info {
									margin-top: 10px;
									display: flex;
									flex-direction: column;

									& > div {
										display: flex;
										flex-direction: row;
										justify-content: space-between;
									}

									span {
										display: block;
										letter-spacing: .6px;
										opacity: .7;
										padding: .25rem 0;
									}
								}

								.checkout-product-spec {
									@include font-size(14,18);
									color: $text;
									display: flex;
									flex-direction: column;

									@media(min-width: $small-desktop) {
										@include font-size(16,26);
									}
								}
							}

							.checkout-product-cost {
								display: none;

								@media(min-width: $small-desktop) {
									color: $text;
									@include font-size(12);
									letter-spacing: .4px;
									flex: 1;
									text-align: right;
									display: block;
									opacity: .7;
								}
							}
						}
					}
				}

				.customer-code-form {
					display: flex;
					margin: 1.5rem 0;
					flex-wrap: wrap;

					input[type="text"] {
						width: calc(100% - 100px);
						padding-bottom: 0px;
						border: 0;
						border-bottom: 1px solid #e1e1e1;
						padding: 10px 0;
						background: transparent;
					}

					button {
						height: 55px;
						width: 100px;
						@include font-size(12,24);
						color: $black;
						letter-spacing: .6px;
						font-family: 'GT-America-Expanded-Medium';
						border: 0;
						-webkit-appearance: none;
						padding-right: 0;
					}

					.field-validation-error {
						display: block;
						width: 100%;
						@include font-size(14,20);
					}
				}

				.checkout-subtotal-items {

					& > div {
						@include font-size(12);
						display: flex;
						color: $black;
						letter-spacing: .6px;
						justify-content: flex-end;
						padding: 0.25rem 0;

						.checkout-item-number {
							min-width: 100px;
							text-align: right;
						}
					}

					.checkout-total-item {
						@include font-size(16,24);
						letter-spacing: .53px;
						color: $black;
						padding-top: 0.5rem;
					}
				}
			}
		}

		.checkout-information {

			@media(min-width: $small-desktop) {
				width: 70%;
				padding: 0 2rem;
			}

			@media(min-width: $max-lg-desktop) {
				width: 63%;
				padding: 0 12rem 0 2rem;
			}

			.checkout-info {
				margin: 2rem 0;

				h1 {
					margin-bottom: 3rem;
				}

				section {
					a.button {
						width: fit-content;
					}

					&.order-complete-summary {
						margin: 0 auto;
					}

					&.checkout-summary {
						margin-top: 40px;

						h3 {
							margin-bottom: $grid-spacing;
						}

						a {
							&.button {
								margin: 3rem 0 2rem;
							}
						}
					}
				}

				h3 {
					@include font-size(16,24);
					margin-bottom: $grid-spacing;


					&.border-bottom {
						border-bottom: 1px solid $border-basket;
						padding-bottom: $grid-spacing;
					}

					span {
						font-family: 'GT-America-Expanded-Medium';
					}
				}

				form {

					@media(min-width: $max-desktop) {
						.no-flex {
							max-width: 45%;
						}
					}

					.flex-fields {

						@media(min-width: $small-desktop) {
							display: flex;
							justify-content: space-between;
						}

						& > div {
							display: flex;
							flex-direction: column;


							@media(min-width: $small-desktop) {
								width: 48%;
							}

							label {
								letter-spacing: .6px;
								opacity: .7;
								margin-bottom: 7px;
							}

							::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
								@include font-size(16);
								color: $black;
								opacity: 1; /* Firefox */
							}

							:-ms-input-placeholder { /* Internet Explorer 10-11 */
								@include font-size(16);
								color: $black;
							}

							::-ms-input-placeholder { /* Microsoft Edge */
								@include font-size(16);
								color: $black;
							}
						}

						&._desktop {

							@media(min-width: $small-desktop) {
								flex-direction: column;

								& > div {
									width: 100%;
								}

								&.delivery-info {
									margin-top: $spacing;
								}
							}
						}

						@media(min-width: $max-desktop) {
							display: flex;
							justify-content: space-between;

							& > div {
								width: 45%;
							}

							.selectric {
								padding-bottom: 5px;
							}
						}
					}

					@media(min-width: $max-tablet) {
						margin-left: 0;
					}

					input, textarea, select, .selectric-wrapper {
						margin-bottom: 2rem;
					}

					input:not(input[type=checkbox]) {
						@include font-size(16);
						color: $black;
						border: 0;
						border-bottom: 1px solid $checkout-input;
						padding: 10px 0;
						background: transparent;
					}

					textarea {
						@include font-size(16);
						color: $black;
						border: 1px solid $checkout-input;
						margin-top: 1.5rem;
						border-radius: 24px;
						min-height: 175px;
						padding: 15px;
						width: 100%;
						max-width: 100%;
					}

					h1 {
						@include font-size(32,40);
					}

					h2 {
						border-bottom: 1px solid $border-basket;
						padding-bottom: $grid-spacing;
					}

					.step-info-banner {
						background-color: $bg-notification-dark;
						padding: $spacing-small 20px;
						display: flex;
						align-items: center;
						color: $white;
						margin-bottom: 3rem;

						svg {
							margin-right: $grid-spacing;
							width: 35px;
						}

						p {
							@include font-size(12,16);
							color: $white;
							margin: 0;
							letter-spacing: .24px;

							a {
								color: $white;
								text-decoration: underline;
								letter-spacing: .24px;
							}
						}
					}

					h2, h3 {
						@include font-size(16,24);
					}

					.contact-info {

						.step-header {
							display: flex;
							align-items: center;
							justify-content: space-between;
							position: relative;
							margin-bottom: 31px;


							span {
								@include font-size(10,24);
								opacity: .7;
								letter-spacing: .6px;
								display: block;
								margin-bottom: 2rem;
							}

							&::after {
								content: '';
								position: absolute;
								border-style: solid;
								border-width: 0 1px 1px;
								border-color: $border-basket;
								display: block;
								width: 100%;
								top: 40px;
							}
						}

						h3 {
							margin: 2.5rem 0;
							border-bottom: 1px solid $border-basket;
							padding-bottom: $grid-spacing;
						}

						#shipping-info {
							.flex-fields {
								.bv_mainselect {
									margin-bottom: 2rem;

									@media(min-width: $max-desktop) {
										margin-bottom: initial;
									}
								}
							}
						}
					}
				}

				.shipping-method-radio {
					margin: 3rem 0;

					ul {
						padding: 0;

						& > li {
							list-style: none;
							display: flex;
							border-bottom: 1px solid $border;
							justify-content: space-between;
							padding: $horizontal-space 0;
							justify-content: space-between;

							&:first-child {
								padding-top: 0;
							}

							label {
								border-radius: 50%;
								cursor: pointer;
								display: flex;
								align-items: center;
								padding-left: 2.5rem;
								position: relative;
								top: 0.25rem;

								.radio-button__custom-indicator {
									background-color: $white;
									border: 1px solid $black;
									border-radius: 50%;
									height: 24px;
									left: 0;
									position: absolute;
									top: -7px;
									width: 24px;

									&::after {
										background-color: $black;
										border-radius: 50%;
										content: "";
										display: none;
										height: 8px;
										left: 7px;
										position: absolute;
										top: 7px;
										width: 8px;
									}
								}

								.radio-label {
									margin-top: -7px;
								}

								input {
									opacity: 0;
									position: absolute;
									z-index: -1;

									&:checked ~ .radio-button__custom-indicator {
										&::after {
											display: block;
										}
									}
								}
							}
						}
					}
				}

				.general-info {
					p {
						strong {
							@include font-size(16,24);
							font-family: 'GT-America-Extended-Light';
							margin: 2rem 0;
							display: block;
							color: $black;
							font-weight: normal;
						}
					}
				}

				.payment-title {
					margin-top: 3rem;
					border-bottom: 1px solid $border-basket;
					padding-bottom: $grid-spacing;
				}

				.review-contact-info {
					margin: 2rem 0;

					@media(min-width: $min-tablet) {
						display: flex;
					}

					& > div {
						margin: $block-padding 0;


						@media(min-width: $min-tablet) {
							flex: 1;
						}

						label {
							@include font-size(12,24);
							letter-spacing: .6px;
							display: block;
							opacity: .7;
						}

						span {
							@include font-size(16,24);
							letter-spacing: .6px;
						}
					}
				}

				.review-addresses {



					@media(min-width: $max-mobile) {
						display: flex;

						& > div {
							flex: 1;
						}
					}

					.review-address {
						@include font-size(12,24);
						letter-spacing: .6px;
						opacity: .7;
						display: flex;
						flex-direction: column;
						margin: 2rem 0;
					}
				}

				.review-notes {
					padding: 40px 0;
				}

				.checkbox-wrapper {
					display: block;
					margin-bottom: 15px;

					input {
						padding: 0;
						height: initial;
						width: initial;
						margin-bottom: 0;
						display: none;
						cursor: pointer;
					}

					label {
						@include font-size(10);
						letter-spacing: .6px;
						position: relative;
						cursor: pointer;
						display: flex;


						@media(min-width: $min-mobile-landscape) {
							align-items: center;
						}

						&::before {
							content: '';
							-webkit-appearance: none;
							background-color: transparent;
							border: 1px solid $black;
							box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
							padding: 10px;
							display: inline-block;
							position: relative;
							vertical-align: middle;
							cursor: pointer;
							margin-right: 12px;
							height: 24px;
							width: 24px;
						}
					}

					input:checked + label:after {
						content: '';
						display: block;
						position: absolute;
						top: 5px;
						left: 10px;
						width: 5px;
						height: 10px;
						border: solid $black;
						border-width: 0 1px 1px 0;
						transform: rotate(45deg);
					}
				}

				.order-print {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					flex-direction: column;
					margin-bottom: $vertical-space-mob;

					@media(min-width: $max-tablet) {
						flex-direction: row;
						margin-bottom: $vertical-space;
					}

					a {
						display: flex;

						&.print {
							@include font-size(12,24);
							letter-spacing: .6px;
							font-family: 'GT-America-Expanded-Medium';
							text-decoration: none;
							color: $black;
						}

						&.button {

							&:hover {
								color: $white;
							}

							@media(min-width: $max-tablet) {
								margin-right: 8rem;
							}
						}
					}
				}
			}

			.form-cta-btn {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-top: $horizontal-space-mob;
				margin-bottom: $vertical-space-mob;

				@media(min-width: 400px) {
					flex-direction: row-reverse;
				}

				@media(min-width: $max-tablet) {
					justify-content: space-between;
					margin-bottom: $vertical-space;
				}

				button {
					margin-bottom: 2rem;
					height: 48px;
					width: fit-content;
					min-width: fit-content;
					margin: 0 8rem 20px auto;

					@media(min-width: 400px) {
						flex: 1;
						margin: 0 5rem 1rem auto;
					}

					&::before {
						width: 55px;
						height: 48px;
						right: -28px;
					}
				}

				a {
					font-weight: 600;
					width: 100%;
					margin-bottom: 10px;
					text-align: center;

					@media(min-width: 400px) {
						text-align: initial;
					}
				}
			}
		}
	}
}
