﻿.inspiration-pod {
    position: relative;    
    overflow: hidden;  
   
    &:before {
        position: absolute;
        content: '';
        width: 200%;
        height: 100%;
        z-index: 1;
        display: block;
        top: 0;
        left: auto;
        right: -10%;
        background: $bg-light-grey;
        transform: skewX(-45deg);
    }   

    .background-image {
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    &__text {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        z-index: 2;
        padding: 72px 64px;  
        color: $black;  

        .sub-heading  {
            color: $text-light;

            &.white {
                color: $white;
            }
        }
         

        h2 {
            color: $black;            
            margin-bottom: 32px;
        }

        .button {
            align-self: flex-start;
        }
    }

    @media(min-width: $max-tablet) {
        height: 430px;

        &:before {
           right: 20%;
        }
       

        .container {
            display: flex;
            align-items: center;
            height: 100%;
        }

         .background-image {
            width: 60%;        
            
        }

        &__text {
            box-sizing: content-box;
            max-width: 450px;
            padding: 80px 164px;
        }
    }

    @media(min-width: $small-desktop) {
        &:before {
            
           
            right: 40%;
        }
    }

    &.dark {
        position: relative;    
        

        &:before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            z-index: 1;
            display: block;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.5);
            transform: none;
        }   

        .background-image {
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }

        .inspiration-pod__text {        
            color: $white;                  

            h2 {
                color: $white;            
                
            }

            
        }

    
    }
}