﻿.general-content {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: $white;
    padding: 64px 32px;
    background-attachment: fixed;

    &:before {
        content:'';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba( 0, 0, 0, .5 );
        z-index: 0;
    }

    &__title { 
        z-index: 1;
        position: relative;

        h3 {
            color: $white;            
            padding-right: 25%;
            margin-bottom: 32px;
        }
    }

    &__text {
        display: flex;
        flex-direction: column;
        z-index: 1;
        position: relative;

        p {
            color: $white;
            margin-bottom: 32px;
        }
    }

    .button {
        align-self: flex-start;
    }

    @media(min-width: $max-tablet) {
        display: flex;
        padding: 160px 32px 100px;
        justify-content: space-around;

        .container {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
        }

        &__title {
            width: 100%;
            max-width: 380px;
            margin-top: -48px;


            h3 {
                padding-right: 24px;        
            }
        }

        &__text {
            width: 100%;
            max-width: 600px;
        }
    }
}