.productdetail {
	.image-gallery-carousel {
		.sub-heading {
			justify-content: center;

			&::before {
				content: none;
			}
		}
	}

	.main-nav {
		.icon {
			&.main-logo {
				fill: $white;

				@media(min-width:$breakpoint) {
					fill: $black;
				}
			}
		}
	}
}

.product-breadcrumb {
	@media (min-width: $breakpoint) {
		margin-top: 200px;
	}
}

.product-detail {
	display: flex;
	flex-direction: column;

	h2 {
		margin-bottom: 12px;
	}

	.sub-title {
		@include font-size(12, 15);
		opacity: .7;
		margin-bottom: 24px;
	}

	&__info {

		&.mobile {
			.sub-heading {
				margin-top: $spacing;
				color: $text-light;
			}

			h2 {
				@include font-size(32,40);
				margin-bottom: 16px;
			}

			.product-price {
				&._mobile {
					p {
						@include font-size(12,24);
						color: $black;
						letter-spacing: normal;
						margin-bottom: $spacing;

						span {
							@include font-size(20,24);
						}
					}
				}

				&__from {
					&.hide {
						display: none;
					}
				}
			}
		}
	}

	.product-details {
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;

		.add-to-basket {
			margin: 40px 0;

			@media (min-width: $max-tablet) {
				margin: 40px 0 48px;
			}
		}
	}

	#product-finish {
		display: none;
	}

	&__left {
		order: 3;

		.desktop {
			display: none;
		}

		.sub-heading {
			color: $text-light;
		}
	}

	&__image {
		.images {
			display: flex;
			justify-content: left;
			position: relative;
		}

		&--img {
			overflow: hidden;
			border-bottom-right-radius: $curve-mob;
			border: 1px solid $lighter-grey;
			margin-bottom: 0;


			img {
				display: block;
				max-width: 100%;
				margin: 0 auto;
			}
		}

		&--secondary {
			display: none;
		}

		&--icon {
			position: absolute;
			bottom: 20px;
			border-radius: 50px;
			height: 48px;
			min-width: 48px;
			background-color: #F4F4F4;
			display: flex;
			align-items: center;
			justify-content: center;
			left: 20px;
		}

		.finish-select {
			margin: $spacing 0;


			&__title {
				@include font-size(16, 1.5);
				color: $black;
				padding: $grid-spacing 0;
			}

			ul {
				padding: 0;

				li {
					display: inline-block;
					margin-right: 10px;
				}
			}

			&__item {
				@include font-size(10,1.6);
				letter-spacing: .6px;
				cursor: pointer;
				display: flex;
				flex-direction: column;
				align-items: center;
				color: rgba(0,0,0,0.7);

				img {
					width: 40px;
					height: 40px;
					border-radius: 50px;
					margin-bottom: 10px;
					border: none;
					padding: 8px;
				}
			}

			.border {
				color: $black;

				img {
					border: 1px solid $black;
				}
			}
		}
	}

	&__accordians {
		order: 3;
		display: flex;
		flex-direction: column;

		&--wrapper {
			padding: 10px 0;
			border-bottom: 1px solid $lighter-grey;

			ul {
				list-style: none;

				li {
					letter-spacing: 0.6px;
					color: $content-txt;

					&:before {
						content: "\2022";
						color: rgba(0,0,0,.7);
						display: inline-block;
						width: 1em;
						margin-left: -1em;
					}
				}
			}
		}

		&--single {
			padding: 10px 0;

			h4 {
				margin-bottom: 0;
			}

			.accordion {
				cursor: pointer;
				width: 100%;
				transition: $duration $easing;
				display: flex;
				justify-content: space-between;
				align-items: center;
				background-color: transparent;
				border: 0;
				padding: 0;

				.plus-icon {
					width: 20px;
					height: 20px;
					position: relative;

					&:before,
					&:after {
						content: "";
						position: absolute;
						background-color: $footer-text;
						transition: transform $duration $easing;
					}

					&:before {
						top: 0;
						left: 50%;
						width: 2px;
						height: 100%;
						margin-left: -1px;
					}

					&:after {
						top: 50%;
						left: 0;
						width: 100%;
						height: 2px;
						margin-top: -1px;
					}
				}


				&.active {
					.plus-icon {
						&:before {
							transform: rotate(90deg);
						}

						&:after {
							transform: rotate(180deg);
						}
					}
				}
			}

			.panel {
				max-height: 0;
				overflow: hidden;
				transition: $duration $easing;

				&.active {
					max-height: 600px;
				}

				.wrapper {
					padding: $grid-spacing 0 $grid-spacing $grid-spacing;



					ul {
						list-style: none;

						li {
							letter-spacing: 0.6px;
							color: $content-txt;

							&:before {
								content: "\2022";
								color: rgba(0,0,0,.7);
								display: inline-block;
								width: 1em;
								margin-left: -1em;
							}

							a {
								color: $content-txt;
							}
						}
					}

					&.download-panel {
						padding-bottom: 0;
						padding-left: 0;

						.product-download {
							padding: $grid-spacing;
							font-weight: 700;
							border-top: 1px solid $lighter-grey;

							&:nth-child(even) {
								background-color: $pale-grey;
							}

							a {
								margin-bottom: 0;
								display: flex;
								justify-content: space-between;
								align-items: center;

								span {
									font-size: 11px;
									line-height: 16px;
									text-transform: uppercase;
									font-weight: 400;
									letter-spacing: 1.6px;
									display: flex;
									align-items: center;

									svg {
										margin-right: 16px;
									}
								}
							}
						}

						.download-button {
							cursor: pointer;
							display: flex;
							justify-content: center;
							align-items: center;
							border-top: 1px solid $lighter-grey;
							margin: 0;
							padding: 15px;
							text-transform: uppercase;

							p {
								display: flex;
								align-items: center;
								font-size: 11px;
								line-height: 16px;
								letter-spacing: 1.6px;
								text-transform: uppercase;

								svg {
									margin-right: 16px;
								}
							}
						}
					}
				}

				p:last-child {
					margin: 0;
				}
			}
		}

		.make-enquiry {
			margin-top: 30px;
		}
	}

	.product-details {
		.product-price {
			margin-top: $grid-spacing;

			p {
				@include font-size(16,24);
				color: $black;
				letter-spacing: normal;
				margin-bottom: $spacing;

				span {
					@include font-size(20,24);
				}
			}

			@media (min-width: $small-desktop) {
				margin-top: initial;
			}

			&__from {
				&.hide {
					display: none;
				}
			}
		}

		.product-quantity {
			margin-top: $grid-spacing;

			p {
				@include font-size(16,24);
				color: $black;
			}

			@media (min-width: $max-tablet) {
				margin-top: $spacing;
			}
		}

		.product-bulk-msg {
			margin-top: $grid-spacing;

			p {
				@include font-size(12,24);
				letter-spacing: .6px;
				opacity: .7;

				a {
					text-decoration: underline;
				}
			}
		}

		.customise-buy-buttons {
			.finish-error {
				display: flex;
				align-items: baseline;
				margin-top: -16px;
				margin-bottom: $horizontal-space;

				@media(min-width: $min-tablet) {
					margin-top: -30px;
				}

				span {
					@include font-size(10);
					color: $warning-text;
				}

				svg {
					margin-right: 10px;
					width: 15px;
					height: 10px;
				}
			}

			.add-to-basket-error {
				display: flex;
				align-items: baseline;
				margin-top: -32px;
				margin-bottom: $horizontal-space;
				display: none;

				span {
					@include font-size(10);
					color: $warning-text;
				}

				svg {
					margin-right: 10px;
					width: 15px;
					height: 10px;
				}
			}
		}
	}

	@media (min-width: $breakpoint) {
		flex-direction: row;
		padding-top: $spacing;

		.mobile {
			display: none;
		}

		&__left {
			order: 0;
			flex: 1;
			margin-right: 10px;

			.desktop {
				display: block;
			}
		}

		&__image {
			width: 50%;
			flex: 1;
			margin-left: 40px;

			&.product-finishes {
				margin-left: 0;
				width: 100%;
			}

			.images {
				display: flex;
				flex-direction: column;
			}
			
			&--img {
				border-bottom-right-radius: $curve-desktop;
			}
		}
	}
}

