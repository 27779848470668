footer {
    background: $footer;
    color: $footer-text;
    border-top: 1px solid $light-grey;

    &.interim {
        border-top: none;
    }

    .footer-content {
        @include font-size(14);
        margin-bottom: 40px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @media (min-width: $small-desktop) {
            margin-bottom: 0;
        }

        .footer-columns {
            display: flex;
            flex-direction: column;

            @media (min-width: $small-desktop) {
                flex-direction: row;
                justify-content: space-between;
                padding: 50px 0;

                .footer-column-single {
                    flex: 1;
                }
            }

            .accordion {
                cursor: pointer;
                padding: 15px 30px 0;
                width: 100%;
                transition: $duration $easing;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 24px;

                h4 {
                    font-size: 14px;
                    margin-bottom: 5px;
                }

                .plus-icon {
                    width: 20px;
                    height: 20px;
                    position: relative;

                    &:before,
                    &:after {
                        content: "";
                        position: absolute;
                        background-color: $footer-text;
                        transition: transform $duration $easing;
                    }

                    &:before {
                        top: 0;
                        left: 50%;
                        width: 1px;
                        height: 100%;
                        margin-left: 0;
                    }

                    &:after {
                        top: 50%;
                        left: 0;
                        width: 100%;
                        height: 1px;
                        margin-top: -1px;
                    }

                    @media (min-width: $small-desktop) {
                        display: none;
                    }
                }


                &.active {
                    .plus-icon {
                        &:before {
                            transform: rotate(90deg);
                        }

                        &:after {
                            transform: rotate(180deg);
                        }
                    }
                }

                @media (min-width: $small-desktop) {
                    cursor: auto;
                    margin-top: 0;
                }
            }

            .panel {
                max-height: 0;
                overflow: hidden;
                transition: $duration $easing;

                &.active {
                    max-height: 600px;
                }

                .wrapper {
                    padding: 0 30px;

                    ul {
                        padding: 0;
                        list-style-type: none;

                        li {
                            a {
                                color: $text-light;
                                @include font-size(12, 28);

                                &:hover {
                                    color: black;
                                }
                            }
                        }
                    }
                }

                p:last-child {
                    margin: 0;
                }

                @media (min-width: $small-desktop) {
                    max-height: 600px;
                }
            }
        }
    }

    .footer-bottom {
        flex-direction: column;

        > *:first-child {
            margin-top: 30px;
        }

        .footer-logo {
            border-top: 1px solid $light-grey;
            padding: 32px 30px 0;
            margin-left: 30px;
            margin-right: 30px;
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;

            svg {
                min-width: 120px;
                height: 25px;
            }

            img {
                width: auto;
                height: 16px;
            }

            .copyright-content {
                flex: 1;
                color: $text-light;
                padding: 0;
                @include font-size(10);
                text-align: center;

                p {
                    margin-bottom: 0;
                    @include font-size(10);
                }
            }
        }

        .footer-social {
            border-top: 1px solid #d8d8d8;
            padding: 30px 0;
            margin-left: 30px;
            margin-right: 30px;

            ul {
                display: flex;
                justify-content: center;
                list-style-type: none;
                padding: 0 15px;
                margin: 0;

                li {
                    padding: 5px;

                    a {
                        color: $text-light;

                        &:hover,
                        &:focus {
                            color: $black;
                        }
                    }

                    svg {
                        display: block;
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }

        .partner-links {
            border-top: 1px solid $light-grey;
            padding-top: 15px;
            margin-left: 30px;
            margin-right: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;

            &__text {
                flex: 1;
                color: $text-light;
                padding: 0;
                font-size: 0.8em;
                text-align: center;
                @include font-size(10);
                margin-bottom: 8px;
            }

            &__img {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                a {
                    margin-bottom: 0;
                    margin-left: 15px;
                    height: 40px;
                    max-width: 130px;

                    img, svg {
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }

        @media (min-width: $small-desktop) {
            flex-direction: row;
            display: flex;
            border-top: 1px solid $light-grey;
            padding: 20px 0;
            justify-content: space-between;
            align-content: center;

            .footer-logo {
                order: 1;
                border-top: none;
                padding: 0;
                margin: 0;
                flex-direction: row;

                img {
                    margin-right: 20px;
                }
            }

            .footer-social {
                order: 2;
                border: none;
                padding: 0;
                margin: 0;
            }

            .partner-links {
                order: 3;
                border-top: none;
                padding: 0;
                margin: 0;
                flex-direction: row;
            }
        }
    }
}


.videogallery  {

    footer {
        background: $dark-theme-bg;
        color: white;
    }

     .footer-content {
      
        .footer-columns {         

            @media (min-width: $small-desktop) {               
                border-top: 1px solid rgba(225,225,225, .15);               
            }

            .accordion {
               
                h4 {
                    color: white;
                }

                .plus-icon {                   

                    &:before,
                    &:after {                       
                        background-color: $dark-theme-text;                        
                    }
                }              
            }

            .panel {                
                .wrapper {
                   
                    ul {                    

                        li {
                            a {
                                color: white;
                                opacity: .6;

                                &:hover {
                                    color: white; 
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .footer-bottom {
        .footer-logo {
            border-top: 1px solid $dark-theme-text;  

            @media (min-width: $small-desktop) {
                border-top: 0;        
            }

            a {
                color: white;

                svg {
                    fill: $white;
                }
            } 

            .copyright-content p {
                color: $dark-theme-text;
               
            }
        }

        .partner-links {
            border-top: 1px solid $dark-theme-text;  

            @media (min-width: $small-desktop) {
                border-top: 0;        
            }         

            &__text {
                color: $dark-theme-text;              
            }  
            
            &__img {
                a {
                    svg {
                        fill: #fff;
                    }
                }
            }
        }

        @media (min-width: $small-desktop) {
             border-top: 1px solid rgba(225,225,225, .15);           
        }
    }

}
