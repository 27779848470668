.imgZoom {

	background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    transition: $duration $easing;

    &:hover {
    	transform: scale(1.1);
    }
}


.img {

    &:hover {
        .imgZoom {
            transform: scale(1.1);
        }
    }

}

section.is-active {


}

.fadeUp {
    visibility: hidden; 
}