//layout

$vertical-space: 80px;
$horizontal-space: 32px;

$vertical-space-mob: 64px;
$horizontal-space-mob: 32px;


$base-font-size: 12;
$font-size: 12px;
$max-desktop: 1200px; 
$small-desktop: 980px;
$min-desktop: 769px;
$max-tablet: 768px;
$min-tablet: 767px;
$max-mobile: 420px;
$min-mobile-landscape: 600px;
$large-desktop: 1400px;
$max-lg-desktop: 1366px;

$max-content-width: 1192px;

$block-padding: 24px;

$spacing: 32px;
$grid-spacing: 16px; //make this 50% of spacing
$spacing-small: 24px;

$curve-mob: 80px;
$curve-desktop: 160px;

//buttons

$button-padding: 19px 32px 17px 32px;

//navigation
$menu-link-padding:                 12px 20px;
$sub-menu-link-padding:             5px 0;
$breakpoint:                        1023px;
$mega-menu-multiLevel-colWidth:     100/3 + 0%;
$mobile-menu-back-height:           calc(1.4em + 40px);
$mobile-menu-back-offset:           calc(0px - (1.4em + 40px));
$menu-mobile-width:                 350px;
$dropdown-bg: #252525;
$top-nav-color: rgba(0, 0, 0, .7);
$sub-nav-color: rgba(255, 255, 255, .7);
$sub-nav-hover-color: rgba(255, 255, 255, 1);
$sub-nav-hover: #3B3B3B;
$nav-scroll-bg: #000;



//ANIMATIONS 
$duration: .2s;

$easing: cubic-bezier(0, 0, 0, 1);


//colours

$black: #000;
$background-black: #1B1B1B;
$white: #fff;
$black-accent: #222222;

$text: #000000;
$text-light: #767676;
$text-opacity: rgba(0, 0, 0, .7);

$accent: #36C593; //green
$accent-text: #FFF; // text colour on accent bg

$dark-grey: #252525;
$grey: #2C2C2C;
$light-grey: #D8D8D8;
$lighter-grey: #e8e8e8;
$pale-grey: #f4f4f4;
$grey-on-white: #757575;

$btn-hover-grey: #EEEEEE;
$border: #DBDBDB;
$border-basket: #e1e1e1;

$mobile-nav-bg: $dark-grey;
$mobile-sub-bg: #292929;

$dark-grey-button: #3C3C3C;
$dark-grey-button-hover: #E3E3E3;

$bg-notification-dark: #262626;
$bg-dark-grey: #ECECEC;
$bg-light-grey: #F5F5F5;

$warning-text: #dd1b1b;

//content
$content-txt: rgba(0, 0, 0, .7);
$content-txt-white: rgba(255, 255, 255, .7);
$caption-txt-grey: #767676;
$pod-grey-bg: #e3e3e3;

//form

$input-border: rgba(0, 0, 0, .2);
$txt-area-bg: #eaeaea;
$error-msg: #dd1515;
$error-msg-bg: #fedede;
$checkout-input: #e1e1e1;
$radio-button-container-size: 24px;
$radio-button-fill-size: 16px;

//footer vars

$footer-spacing-mob: 40px 0;
$footer-spacing: 100px 0;
$disclaimer-spacing-mob: 20px 0;
$disclaimer-spacing: 30px 0;

$footer: #FFF;
$footer-text: #2A2A2A;
$disclaimer-text: rgba(255, 255, 255, .6);
$disclaimer: #161616;

$dark-theme-bg: #1b1b1b;
$dark-theme-text: #a7a7a7;

//basket
$header-basket-bg: #ffffff;
$header-basket-img-border: #e8e8e8;
$success-message-bg: #d4f8d7;
$success-message-txt: #3a8d41;
$border-basket: #e1e1e1;

//showroom
$gold: #84754E;