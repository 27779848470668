@charset "UTF-8";
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
  width: 100%; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: row nowrap; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* convert to em from px */
/* convert to rem from px */
/* unit conversion used by font size */
/* Slightly lighten a color
 * @access public
 * @param {Color} $color - color to tint
 * @param {Number} $percentage - percentage of `$color` in returned color
 * @return {Color}
 */
/* Slightly darken a color
 * @access public
 * @param {Color} $color - color to shade
 * @param {Number} $percentage - percentage of `$color` in returned color
 * @return {Color}
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

textarea {
  overflow: auto; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@font-face {
  font-family: 'GT-America-Extended-Light';
  src: url("/assets/src/css/fonts/GT-America-Extended-Light.woff") format("woff"), url("/assets/src/css/fonts/GT-America-Extended-Light.woff2") format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'GT-America-Expanded-Medium';
  src: url("/assets/src/css/fonts/GT-America-Expanded-Medium.woff") format("woff"), url("/assets/src/css/fonts/GT-America-Expanded-Medium.woff2") format("woff2");
  font-display: swap; }

p, ul, a {
  margin-bottom: 24px; }

h1, h2, h3, h4, h5, h6, p, ul, ol {
  margin-top: 0;
  margin-bottom: 16px;
  font-weight: normal; }

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 1rem;
  color: #000; }

h1, .h1 {
  font-size: 32px;
  font-size: 2.66667rem;
  line-height: 40px;
  line-height: 3.33333rem;
  margin-bottom: 2rem; }
  h1.homepage, .h1.homepage {
    font-size: 32px;
    font-size: 2.66667rem;
    line-height: 40px;
    line-height: 3.33333rem; }

h2, .h2 {
  font-size: 24px;
  font-size: 2rem;
  line-height: 32px;
  line-height: 2.66667rem;
  margin-bottom: 24px; }

h3, .h3 {
  font-size: 24px;
  font-size: 2rem;
  line-height: 32px;
  line-height: 2.66667rem; }

h4, .h4 {
  font-size: 18px;
  font-size: 1.5rem;
  line-height: 24px;
  line-height: 2rem; }

p {
  font-size: 16px;
  font-size: 1.33333rem;
  line-height: 24px;
  line-height: 2rem;
  letter-spacing: 0.6px;
  color: rgba(0, 0, 0, 0.7); }

@media (min-width: 768px) {
  h1, .h1 {
    font-size: 40px;
    font-size: 3.33333rem;
    line-height: 48px;
    line-height: 4rem;
    margin-bottom: 2rem; }
    h1.homepage, .h1.homepage {
      font-size: 56px;
      font-size: 4.66667rem;
      line-height: 64px;
      line-height: 5.33333rem; }
  h2, .h2 {
    font-size: 32px;
    font-size: 2.66667rem;
    line-height: 40px;
    line-height: 3.33333rem; }
  h3, .h3 {
    font-size: 24px;
    font-size: 2rem;
    line-height: 32px;
    line-height: 2.66667rem; }
  h4, .h4 {
    font-size: 16px;
    font-size: 1.33333rem;
    line-height: 24px;
    line-height: 2rem; } }

.sub-heading {
  font-size: 12px;
  font-size: 1rem;
  line-height: 12px;
  line-height: 1rem;
  letter-spacing: .6px;
  display: flex;
  align-items: center;
  padding-left: 11px;
  margin-bottom: 16px; }
  .sub-heading:before {
    content: '';
    width: 1px;
    height: 32px;
    background-color: black;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-right: 24px; }
  .sub-heading.semi-trans {
    opacity: .5; }
  .sub-heading.white {
    opacity: .5;
    color: #fff; }
    .sub-heading.white:before {
      background-color: white; }

html,
body {
  min-height: 100%;
  font-size: 12px; }

body {
  position: relative;
  width: 100%;
  font-family: 'GT-America-Extended-Light';
  font-weight: 400;
  font-style: normal; }

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.svg-sprite {
  display: none; }

b,
strong {
  font-family: 'GT-America-Expanded-Medium'; }

.svg-sprite {
  display: none; }

a {
  text-decoration: none;
  color: #000; }
  a:hover {
    text-decoration: none; }
  a.link-w-icon {
    display: flex;
    align-items: center; }
    a.link-w-icon span {
      margin-right: 8px; }

a:active,
a:hover,
button {
  outline: 0; }

img {
  border: 0; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

section {
  margin: 64px auto; }
  @media (min-width: 768px) {
    section {
      margin: 80px auto; } }
  section.fadeUp {
    visibility: hidden; }
  section.no-top {
    margin-top: 0; }
  section.no-bottom {
    margin-bottom: 0; }

.container {
  width: 100%;
  max-width: 1192px;
  margin: 0 auto;
  padding: 0 32px;
  position: relative; }
  .container.max-1300 {
    max-width: 1300px;
    padding: 0; }

.border-top {
  border-top: 1px solid #DBDBDB;
  padding-top: 60px; }

.parallax {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed; }

svg.icon {
  width: 16px;
  height: 16px; }
  svg.icon.white {
    fill: #fff; }
  svg.icon.black {
    fill: #000; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }

.site-header {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  right: 0; }
  .site-header .main-nav {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .site-header .main-nav .logo {
      position: absolute;
      top: 48px; }
      .site-header .main-nav .logo .main-logo {
        width: 90px;
        height: 30px; }
        .site-header .main-nav .logo .main-logo.mobile {
          display: block; }
        .site-header .main-nav .logo .main-logo.desktop {
          display: none; }
      .site-header .main-nav .logo a {
        color: white; }
      @media (min-width: 1024px) {
        .site-header .main-nav .logo {
          top: 16px;
          margin: 43px auto 28px;
          position: relative; }
          .site-header .main-nav .logo a {
            color: black; }
          .site-header .main-nav .logo .main-logo {
            width: 105px;
            height: 45px; }
            .site-header .main-nav .logo .main-logo.desktop {
              display: block; }
            .site-header .main-nav .logo .main-logo.mobile {
              display: none; } }
    .site-header .main-nav.dark .logo a {
      color: white; }
      .site-header .main-nav.dark .logo a svg {
        fill: white; }
  .site-header .search-panel {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    transition: 0.2s cubic-bezier(0, 0, 0, 1);
    overflow: hidden;
    z-index: 9;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 0; }
    @media (min-width: 1023px) {
      .site-header .search-panel {
        top: 0; } }
    .site-header .search-panel.active {
      max-height: 300px; }
    .site-header .search-panel form {
      width: 100%;
      max-width: 498px;
      display: flex;
      align-items: center;
      margin: 5px 0;
      padding: 24px; }
      @media (min-width: 1023px) {
        .site-header .search-panel form {
          padding: 0; } }
      .site-header .search-panel form .search-close {
        width: 12px;
        margin-left: 24px;
        cursor: pointer; }
        .site-header .search-panel form .search-close svg {
          width: 12px;
          height: 12px; }
    .site-header .search-panel .explore-links {
      background-color: #f4f4f4;
      width: 100%;
      padding: 40px 45px; }
      @media (min-width: 1023px) {
        .site-header .search-panel .explore-links {
          padding: 32px 0; } }
      .site-header .search-panel .explore-links .wrapper {
        width: 100%;
        max-width: 498px;
        margin: 0 auto; }
      .site-header .search-panel .explore-links .title {
        opacity: 0.3;
        font-size: 14px;
        font-size: 1.16667rem;
        line-height: 22px;
        line-height: 1.83333rem; }
      .site-header .search-panel .explore-links ul {
        width: 100%;
        margin: 16px 0;
        padding: 0; }
        .site-header .search-panel .explore-links ul li {
          list-style: none;
          margin: 16px 0;
          padding: 0; }
          .site-header .search-panel .explore-links ul li a {
            color: #000000;
            opacity: 0.7;
            letter-spacing: .6px; }
  .site-header .upper-nav {
    display: grid;
    grid-template: "one" auto "two" auto / auto max-content max-content;
    font-size: 11px;
    background-color: #252525;
    height: 96px;
    color: #fff;
    padding: 0 32px; }
    @media (max-width: 1022px) {
      .site-header .upper-nav {
        justify-items: end; } }
    @media (min-width: 1023px) {
      .site-header .upper-nav {
        height: 40px;
        grid-template: "one two three" auto / auto max-content max-content; } }
    .site-header .upper-nav a {
      display: flex;
      align-items: center;
      opacity: 0.6;
      color: #fff;
      margin-bottom: 0px;
      padding: 0 32px;
      border-right: 1px solid #fff; }
      .site-header .upper-nav a:first-child {
        padding-left: 0px; }
      .site-header .upper-nav a:last-child {
        border-right: none;
        padding-right: 0; }
      .site-header .upper-nav a:hover {
        opacity: 1; }
    .site-header .upper-nav .upper-links {
      display: flex;
      align-items: center; }
      .site-header .upper-nav .upper-links .header-icons button {
        background-color: transparent;
        padding: 0px;
        border: 0;
        outline: none; }
      .site-header .upper-nav .upper-links .header-icons .shopping-basket {
        position: relative;
        margin-right: 15px; }
        .site-header .upper-nav .upper-links .header-icons .shopping-basket.basket-active, .site-header .upper-nav .upper-links .header-icons .shopping-basket:hover {
          /*border-bottom: 1px solid $black;*/
          opacity: 1; }
          .site-header .upper-nav .upper-links .header-icons .shopping-basket.basket-active:after, .site-header .upper-nav .upper-links .header-icons .shopping-basket:hover:after {
            width: 100%; }
        .site-header .upper-nav .upper-links .header-icons .shopping-basket svg {
          width: 24px;
          height: 26px;
          margin-top: 4px;
          opacity: .6;
          transition: all .2s ease-in-out; }
          .site-header .upper-nav .upper-links .header-icons .shopping-basket svg:hover {
            opacity: 1; }
        .site-header .upper-nav .upper-links .header-icons .shopping-basket .items {
          font-size: 10px;
          font-size: 0.83333rem;
          line-height: 10px;
          line-height: 0.83333rem;
          border-radius: 50%;
          background-color: #fff;
          color: #000;
          width: 20px;
          height: 20px;
          text-align: center;
          position: absolute;
          top: 0;
          left: -12px;
          padding-top: 6px;
          padding-right: 1px; }
      @media (min-width: 1024px) {
        .site-header .upper-nav .upper-links .header-icons .shopping-basket {
          margin-right: 23px; }
          .site-header .upper-nav .upper-links .header-icons .shopping-basket svg {
            width: 16px;
            height: 18px; }
          .site-header .upper-nav .upper-links .header-icons .shopping-basket .items {
            font-size: 8px;
            font-size: 0.66667rem;
            line-height: 10px;
            line-height: 0.83333rem;
            width: 17px;
            height: 17px;
            text-align: center;
            padding-top: 4px; } }
      .site-header .upper-nav .upper-links.language-selector {
        display: flex;
        grid-area: one;
        margin-right: -20px; }
        @media (min-width: 1023px) {
          .site-header .upper-nav .upper-links.language-selector {
            position: relative;
            grid-area: auto;
            margin-right: 0; } }
        .site-header .upper-nav .upper-links.language-selector .language-filter {
          cursor: pointer;
          display: flex;
          align-items: center;
          opacity: .6;
          text-transform: uppercase;
          padding: 0 10px;
          transition: opacity 0.2s cubic-bezier(0, 0, 0, 1); }
          .site-header .upper-nav .upper-links.language-selector .language-filter > .icon {
            transition: transform 0.2s cubic-bezier(0, 0, 0, 1); }
          .site-header .upper-nav .upper-links.language-selector .language-filter:hover {
            opacity: 1; }
          .site-header .upper-nav .upper-links.language-selector .language-filter.language-active {
            opacity: 1; }
            .site-header .upper-nav .upper-links.language-selector .language-filter.language-active > .icon {
              transform: rotate(180deg); }
          @media (min-width: 767px) {
            .site-header .upper-nav .upper-links.language-selector .language-filter {
              padding: 0 32px; } }
          .site-header .upper-nav .upper-links.language-selector .language-filter .flag {
            margin-right: 10px; }
            .site-header .upper-nav .upper-links.language-selector .language-filter .flag img {
              width: 22px;
              margin-top: 2px; }
          .site-header .upper-nav .upper-links.language-selector .language-filter span {
            margin-right: 8px; }
        .site-header .upper-nav .upper-links.language-selector .language-dropdown {
          position: absolute;
          display: none;
          justify-content: space-between;
          flex-wrap: wrap;
          flex-direction: column;
          top: 40px;
          right: 0;
          width: 100%;
          z-index: 5; }
          @media (min-width: 1023px) {
            .site-header .upper-nav .upper-links.language-selector .language-dropdown {
              top: 40px;
              right: 30px; } }
          .site-header .upper-nav .upper-links.language-selector .language-dropdown ul {
            padding-left: 0;
            margin: 0;
            list-style-type: none; }
            .site-header .upper-nav .upper-links.language-selector .language-dropdown ul li.region {
              padding: 30px 0 28px;
              background-color: #252525; }
              .site-header .upper-nav .upper-links.language-selector .language-dropdown ul li.region span {
                font-size: 18px;
                font-size: 1.5rem;
                line-height: 26px;
                line-height: 2.16667rem;
                color: #fff;
                display: inline-block;
                padding: 0 30px 12px 30px; }
            .site-header .upper-nav .upper-links.language-selector .language-dropdown ul li a {
              font-size: 12px;
              font-size: 1rem;
              line-height: 20px;
              line-height: 1.66667rem;
              width: 100%;
              padding: 12px 30px;
              background-color: #252525;
              text-transform: uppercase; }
              .site-header .upper-nav .upper-links.language-selector .language-dropdown ul li a:hover {
                background-color: #3B3B3B; }
              .site-header .upper-nav .upper-links.language-selector .language-dropdown ul li a .flag {
                margin-right: 10px; }
                .site-header .upper-nav .upper-links.language-selector .language-dropdown ul li a .flag img {
                  width: 22px;
                  margin-top: 3px; }
          .site-header .upper-nav .upper-links.language-selector .language-dropdown.language-filter-active {
            display: flex; }
      .site-header .upper-nav .upper-links.quick-links {
        display: none; }
        @media (min-width: 1024px) {
          .site-header .upper-nav .upper-links.quick-links {
            display: flex; } }
      .site-header .upper-nav .upper-links.cta-links {
        grid-area: two;
        margin-right: -14px; }
        @media (min-width: 1023px) {
          .site-header .upper-nav .upper-links.cta-links {
            grid-area: auto;
            margin-right: 0; } }
        .site-header .upper-nav .upper-links.cta-links .search-open {
          margin-right: 2px;
          opacity: 0.6;
          transition: 0.2s cubic-bezier(0, 0, 0, 1);
          cursor: pointer; }
          .site-header .upper-nav .upper-links.cta-links .search-open:hover {
            opacity: 1; }
          .site-header .upper-nav .upper-links.cta-links .search-open svg {
            width: 24px;
            height: 24px;
            margin-top: 4px; }
            @media (min-width: 1024px) {
              .site-header .upper-nav .upper-links.cta-links .search-open svg {
                width: 16px;
                height: 16px; } }
        .site-header .upper-nav .upper-links.cta-links .icon {
          opacity: 1; }
  .site-header .search {
    margin-bottom: 0; }
    .site-header .search ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: rgba(0, 0, 0, 0.7); }
    .site-header .search ::-moz-placeholder {
      /* Firefox 19+ */
      color: rgba(0, 0, 0, 0.7); }
    .site-header .search :-ms-input-placeholder {
      /* IE 10+ */
      color: rgba(0, 0, 0, 0.7); }
    .site-header .search :-moz-placeholder {
      /* Firefox 18- */
      color: rgba(0, 0, 0, 0.7); }
    .site-header .search input[type="text"] {
      font-size: 16px;
      font-size: 1.33333rem;
      line-height: 24px;
      line-height: 2rem;
      height: 30px;
      letter-spacing: 0.6px;
      display: inline-block;
      border: none;
      outline: none;
      padding: 3px;
      background: none;
      z-index: 3;
      cursor: pointer;
      flex-grow: 2;
      width: 100%; }
      @media (min-width: 1024px) {
        .site-header .search input[type="text"] {
          font-size: 12px;
          font-size: 1rem;
          line-height: 20px;
          line-height: 1.66667rem; } }
    .site-header .search input[type="submit"] {
      height: 20px;
      width: 20px;
      display: inline-block;
      margin-right: 24px;
      background: url(../../img/icon-search-black.svg) center center no-repeat;
      text-indent: -10000px;
      border: none;
      outline: none;
      z-index: 2;
      cursor: pointer; }

nav ul, nav li {
  list-style: none;
  padding: 0;
  margin: 0; }

nav a {
  display: block;
  text-decoration: none; }
  nav a:hover, nav a:visited {
    text-decoration: none; }

.menu-bar {
  display: flex; }

.menu-link {
  font-size: 11px;
  font-size: 0.91667rem;
  line-height: 16px;
  line-height: 1.33333rem;
  padding: 12px 20px;
  color: rgba(0, 0, 0, 0.7);
  transition: background .2s, color .2s;
  position: relative;
  z-index: 1;
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 1px; }
  .menu-link .icon {
    display: none; }
  @media (min-width: 1023px) {
    .menu-link {
      height: 100%; } }
  .menu-link.mega-menu-link {
    color: rgba(255, 255, 255, 0.7); }

.mega-menu-header {
  font-size: 1.2em;
  text-transform: uppercase;
  font-weight: bold; }

@media all and (min-width: 1024px) {
  .nav > nav {
    max-width: 1192px;
    margin: 0 auto;
    display: flex;
    justify-content: center; }
  .menu > li > [aria-haspopup="true"] ~ ul {
    display: none; }
  .menu-bar > li > [aria-haspopup="true"] span {
    display: flex; }
  .menu-bar > li > [aria-haspopup="true"] .icon {
    display: block;
    width: 13px;
    height: 13px;
    margin-left: 6px; }
  .menu-bar > li > [aria-haspopup="true"]:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-width: 0 8.5px 7px 8.5px;
    border-color: transparent transparent #2c2c2c transparent;
    border-style: solid;
    bottom: 0px;
    right: 9px;
    transform: translateX(-50%);
    transition: none;
    opacity: 0;
    z-index: 1; }
  .menu-bar > li > [aria-haspopup="true"]:hover:before {
    opacity: 1;
    transition: opacity .2s; }
  .menu-bar > li > [aria-haspopup="true"]:hover ~ ul {
    display: flex;
    transform-origin: top;
    animation: dropdown .2s ease-out; }
  .menu-bar > li > [aria-haspopup="true"] ~ ul:hover {
    display: flex; }
  .menu-bar > li:focus-within > [aria-haspopup="true"] ~ ul {
    display: flex; }
  .menu-bar > li:hover > [aria-haspopup="true"]:before {
    opacity: 1;
    transition: opacity .2s; }
  .mega-menu {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 790px;
    background: #252525;
    padding: 34px 40px;
    z-index: 10; }
    .mega-menu:hover {
      display: flex; }
    .mega-menu a:hover {
      background: #3B3B3B;
      color: white; }
  .mega-menu--flat > * {
    flex: 1; }
  .menu-list {
    display: flex; }
  .menu-column {
    flex: 1 1 29%; }
    .menu-column + .menu-column {
      margin-left: 20px; }
    .menu-column a {
      margin: 0;
      transition: opacity 0.2s cubic-bezier(0, 0, 0, 1); }
    .menu-column-image {
      flex: 0 1 42%;
      margin-left: 50px; }
      .menu-column-image a:hover {
        background: none; }
      .menu-column-image img {
        max-width: 100%; }
    .menu-column .menu-column-title {
      font-size: 18px;
      font-size: 1.5rem;
      line-height: 24px;
      line-height: 2rem;
      color: #fff;
      margin-bottom: 10px; }
      .menu-column .menu-column-title:hover {
        background-color: transparent; }
    .menu-column .menu-column-highlight {
      font-size: 12px;
      font-size: 1rem;
      line-height: 28px;
      line-height: 2.33333rem;
      letter-spacing: 1px;
      color: #fff;
      text-transform: uppercase;
      margin-top: 8px;
      transition: opacity 0.2s cubic-bezier(0, 0, 0, 1); }
      .menu-column .menu-column-highlight:hover {
        background: none;
        opacity: 0.7; }
    .menu-column .menu-list-link {
      font-size: 12px;
      font-size: 1rem;
      line-height: 24px;
      line-height: 2rem;
      color: rgba(255, 255, 255, 0.7);
      padding: 5px 0;
      margin: 0;
      text-transform: initial;
      letter-spacing: 0.6px; }
      .menu-column .menu-list-link:hover {
        background: none;
        opacity: 0.7; }
  .mobile-menu-trigger, .mobile-menu-header, .mobile-menu-back-item {
    display: none; } }

@media all and (max-width: 1023px) {
  .nav {
    padding: 20px; }
  .mobile-menu-trigger, .mobile-menu-header, .mobile-menu-back-item {
    display: block; }
  .mobile-menu-trigger {
    border: 0; }
  .mobile-menu-header {
    order: -1;
    background: grey; }
    .mobile-menu-header a {
      padding: 12px 20px;
      color: #FFF;
      visibility: visible; }
  .menu-bar {
    flex-direction: column;
    position: fixed;
    top: 0;
    left: -100%;
    height: 100vh;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    transition: left .3s; }
    .menu-bar > li {
      position: relative; }
      .menu-bar > li > [aria-haspopup="true"] ~ ul {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        background: #292929;
        max-height: 0;
        overflow: hidden;
        transition: 0.2s cubic-bezier(0, 0, 0, 1); }
        .menu-bar > li > [aria-haspopup="true"] ~ ul.active {
          max-height: 100vh; }
        .menu-bar > li > [aria-haspopup="true"] ~ ul > li > ul > li {
          position: relative;
          background: #292929; }
          .menu-bar > li > [aria-haspopup="true"] ~ ul > li > ul > li > ul {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            max-height: 0;
            overflow: hidden;
            transition: 0.2s cubic-bezier(0, 0, 0, 1); }
            .menu-bar > li > [aria-haspopup="true"] ~ ul > li > ul > li > ul.active {
              max-height: 100vh; }
  .mega-menu-content {
    padding: 12px 20px; }
  .mobile-menu-back-item {
    order: -1; }
    .mobile-menu-back-item a {
      background: #d9d9d9;
      max-height: calc(1.4em + 40px);
      margin-top: calc(0px - (1.4em + 40px));
      pointer-events: none; }
      .mobile-menu-back-item a:before {
        content: "";
        width: 14px;
        height: 12px;
        background-size: 14px;
        margin-right: 10px;
        display: inline-block; }
  .mobile-menu-trigger {
    position: absolute;
    top: 52px;
    left: 24px;
    z-index: 1; }
    .mobile-menu-trigger.active ~ ul {
      left: 0; }
  .menu-bar {
    top: 0;
    padding-top: 96px;
    background-color: #252525; }
    .menu-bar .menu-link {
      color: white;
      padding: 24px 32px; }
      .menu-bar .menu-link span {
        opacity: 1; }
      .menu-bar .menu-link[aria-haspopup="true"] {
        margin-right: 80px; }
      .menu-bar .menu-link:hover span, .menu-bar .menu-link:focus span {
        opacity: .7; }
    .menu-bar .menu-column-image {
      display: none; }
    .menu-bar .menu-column-title {
      font-size: 11px;
      font-size: 0.91667rem;
      line-height: 16px;
      line-height: 1.33333rem;
      text-transform: uppercase;
      color: #fff;
      padding: 24px 32px;
      margin-bottom: 0;
      transition: cubic-bezier(0, 0, 0, 1) 0.2s opacity; }
      .menu-bar .menu-column-title:is(a):hover {
        opacity: 0.7; }
      .menu-bar .menu-column-title[aria-haspopup="true"] {
        margin-right: 80px; }
    .menu-bar .menu-list-link {
      font-size: 12px;
      font-size: 1rem;
      line-height: 20px;
      line-height: 1.66667rem;
      text-transform: none;
      padding: 12px 32px 12px 52px;
      opacity: 0.7; }
      .menu-bar .menu-list-link:hover, .menu-bar .menu-list-link:focus {
        opacity: 1; }
    .menu-bar .menu-column-highlight {
      font-size: 12px;
      font-size: 1rem;
      line-height: 20px;
      line-height: 1.66667rem;
      color: #fff;
      padding: 12px 32px;
      margin-bottom: 0;
      transition: cubic-bezier(0, 0, 0, 1) 0.2s opacity; }
      .menu-bar .menu-column-highlight:hover, .menu-bar .menu-column-highlight:focus {
        opacity: 0.7; }
    .menu-bar .show-child {
      color: white;
      position: absolute;
      right: 24px;
      top: 24px; }
      .menu-bar .show-child .plus-icon {
        width: 20px;
        height: 20px;
        position: relative; }
        .menu-bar .show-child .plus-icon:before, .menu-bar .show-child .plus-icon:after {
          content: "";
          position: absolute;
          background-color: white;
          transition: transform 0.2s cubic-bezier(0, 0, 0, 1); }
        .menu-bar .show-child .plus-icon:before {
          top: 0;
          left: 50%;
          width: 1px;
          height: 100%;
          margin-left: 0; }
        .menu-bar .show-child .plus-icon:after {
          top: 50%;
          left: 0;
          width: 100%;
          height: 1px;
          margin-top: 0; }
      .menu-bar .show-child.active .plus-icon:before {
        transform: rotate(90deg); }
      .menu-bar .show-child.active .plus-icon:after {
        transform: rotate(180deg); }
    .menu-bar > li > [aria-haspopup="true"]:focus ~ ul {
      left: 0; }
    .menu-bar > li > [aria-haspopup="true"] ~ ul:hover, .menu-bar > li > [aria-haspopup="true"] ~ ul:focus-within {
      left: 0; }
    .menu-bar > li > [aria-haspopup="true"] ~ ul li:focus-within > [aria-haspopup="true"] ~ ul {
      max-height: 500px;
      transform: scaleY(1); }
    .menu-bar > li:focus-within ~ .mobile-menu-header a {
      visibility: hidden; } }

@media all and (max-width: 1023px) and (hover: none) {
  .mobile-menu-trigger.active ~ ul {
    left: 0; }
  .menu-bar > li > [aria-haspopup="true"]:hover ~ ul {
    left: 0; }
  .menu-bar > li > [aria-haspopup="true"] ~ ul:hover {
    left: 0; }
  .menu-bar > li > [aria-haspopup="true"] ~ ul [aria-haspopup="true"]:hover ~ ul {
    max-height: 500px;
    animation: dropdown .3s forwards; }
  .menu-bar > li > [aria-haspopup="true"] ~ ul [aria-haspopup="true"] ~ ul:hover {
    max-height: 500px;
    transform: scaleY(1); }
  .menu-bar > li:hover ~ .mobile-menu-header a {
    visibility: hidden; } }

@keyframes dropdown {
  0% {
    opacity: 0;
    transform: scaleY(0) translateX(-50%); }
  50% {
    opacity: 1; }
  100% {
    transform: scaleY(1) translateX(-50%); } }

@keyframes flyout {
  0% {
    opacity: 0;
    transform: scaleX(0); }
  100% {
    opacity: 1;
    transform: scaleX(1); } }

.menu-icon-wrapper {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2; }
  .menu-icon-wrapper .menu-icon {
    position: relative;
    opacity: 0.6; }
    .menu-icon-wrapper .menu-icon::before, .menu-icon-wrapper .menu-icon::after {
      position: absolute;
      left: 0;
      content: '';
      display: block;
      transition: transform 0.2s ease-in, top 0.2s linear 0.2s; }
    .menu-icon-wrapper .menu-icon::before {
      top: -4px; }
    .menu-icon-wrapper .menu-icon::after {
      top: 4px; }
  .menu-icon-wrapper .menu-icon {
    width: 25px; }
  .menu-icon-wrapper .menu-icon::before, .menu-icon-wrapper .menu-icon::after {
    width: 24px;
    height: 1px;
    background-color: white; }
  .menu-icon-wrapper .menu-icon-active {
    background-color: transparent; }
    .menu-icon-wrapper .menu-icon-active::before, .menu-icon-wrapper .menu-icon-active::after {
      top: 0;
      transition: top 0.2s linear, transform 0.2s ease-in 0.2s;
      background-color: #FFF; }
    .menu-icon-wrapper .menu-icon-active::before {
      transform: rotate(45deg); }
    .menu-icon-wrapper .menu-icon-active::after {
      transform: rotate(-45deg); }

.main-nav.dark .nav .menu-link, .videogallery .main-nav .nav .menu-link {
  color: white;
  transition: 0.2s cubic-bezier(0, 0, 0, 1); }
  .main-nav.dark .nav .menu-link span, .videogallery .main-nav .nav .menu-link span {
    transition: opacity 0.2s cubic-bezier(0, 0, 0, 1); }
  .main-nav.dark .nav .menu-link:hover span, .videogallery .main-nav .nav .menu-link:hover span {
    opacity: .7; }

.videogallery header .main-logo {
  color: white; }

body:before {
  content: '';
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: 1s ease; }

body.overlay-open {
  overflow: hidden; }
  body.overlay-open::before {
    opacity: 1;
    transition: 1s ease;
    z-index: 3; }

footer {
  background: #FFF;
  color: #2A2A2A;
  border-top: 1px solid #D8D8D8; }
  footer.interim {
    border-top: none; }
  footer .footer-content {
    font-size: 14px;
    font-size: 1.16667rem;
    line-height: 22px;
    line-height: 1.83333rem;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    flex-direction: column; }
    @media (min-width: 980px) {
      footer .footer-content {
        margin-bottom: 0; } }
    footer .footer-content .footer-columns {
      display: flex;
      flex-direction: column; }
      @media (min-width: 980px) {
        footer .footer-content .footer-columns {
          flex-direction: row;
          justify-content: space-between;
          padding: 50px 0; }
          footer .footer-content .footer-columns .footer-column-single {
            flex: 1; } }
      footer .footer-content .footer-columns .accordion {
        cursor: pointer;
        padding: 15px 30px 0;
        width: 100%;
        transition: 0.2s cubic-bezier(0, 0, 0, 1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 24px; }
        footer .footer-content .footer-columns .accordion h4 {
          font-size: 14px;
          margin-bottom: 5px; }
        footer .footer-content .footer-columns .accordion .plus-icon {
          width: 20px;
          height: 20px;
          position: relative; }
          footer .footer-content .footer-columns .accordion .plus-icon:before, footer .footer-content .footer-columns .accordion .plus-icon:after {
            content: "";
            position: absolute;
            background-color: #2A2A2A;
            transition: transform 0.2s cubic-bezier(0, 0, 0, 1); }
          footer .footer-content .footer-columns .accordion .plus-icon:before {
            top: 0;
            left: 50%;
            width: 1px;
            height: 100%;
            margin-left: 0; }
          footer .footer-content .footer-columns .accordion .plus-icon:after {
            top: 50%;
            left: 0;
            width: 100%;
            height: 1px;
            margin-top: -1px; }
          @media (min-width: 980px) {
            footer .footer-content .footer-columns .accordion .plus-icon {
              display: none; } }
        footer .footer-content .footer-columns .accordion.active .plus-icon:before {
          transform: rotate(90deg); }
        footer .footer-content .footer-columns .accordion.active .plus-icon:after {
          transform: rotate(180deg); }
        @media (min-width: 980px) {
          footer .footer-content .footer-columns .accordion {
            cursor: auto;
            margin-top: 0; } }
      footer .footer-content .footer-columns .panel {
        max-height: 0;
        overflow: hidden;
        transition: 0.2s cubic-bezier(0, 0, 0, 1); }
        footer .footer-content .footer-columns .panel.active {
          max-height: 600px; }
        footer .footer-content .footer-columns .panel .wrapper {
          padding: 0 30px; }
          footer .footer-content .footer-columns .panel .wrapper ul {
            padding: 0;
            list-style-type: none; }
            footer .footer-content .footer-columns .panel .wrapper ul li a {
              color: #767676;
              font-size: 12px;
              font-size: 1rem;
              line-height: 28px;
              line-height: 2.33333rem; }
              footer .footer-content .footer-columns .panel .wrapper ul li a:hover {
                color: black; }
        footer .footer-content .footer-columns .panel p:last-child {
          margin: 0; }
        @media (min-width: 980px) {
          footer .footer-content .footer-columns .panel {
            max-height: 600px; } }
  footer .footer-bottom {
    flex-direction: column; }
    footer .footer-bottom > *:first-child {
      margin-top: 30px; }
    footer .footer-bottom .footer-logo {
      border-top: 1px solid #D8D8D8;
      padding: 32px 30px 0;
      margin-left: 30px;
      margin-right: 30px;
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: center; }
      footer .footer-bottom .footer-logo svg {
        min-width: 120px;
        height: 25px; }
      footer .footer-bottom .footer-logo img {
        width: auto;
        height: 16px; }
      footer .footer-bottom .footer-logo .copyright-content {
        flex: 1;
        color: #767676;
        padding: 0;
        font-size: 10px;
        font-size: 0.83333rem;
        line-height: 18px;
        line-height: 1.5rem;
        text-align: center; }
        footer .footer-bottom .footer-logo .copyright-content p {
          margin-bottom: 0;
          font-size: 10px;
          font-size: 0.83333rem;
          line-height: 18px;
          line-height: 1.5rem; }
    footer .footer-bottom .footer-social {
      border-top: 1px solid #d8d8d8;
      padding: 30px 0;
      margin-left: 30px;
      margin-right: 30px; }
      footer .footer-bottom .footer-social ul {
        display: flex;
        justify-content: center;
        list-style-type: none;
        padding: 0 15px;
        margin: 0; }
        footer .footer-bottom .footer-social ul li {
          padding: 5px; }
          footer .footer-bottom .footer-social ul li a {
            color: #767676; }
            footer .footer-bottom .footer-social ul li a:hover, footer .footer-bottom .footer-social ul li a:focus {
              color: #000; }
          footer .footer-bottom .footer-social ul li svg {
            display: block;
            width: 24px;
            height: 24px; }
    footer .footer-bottom .partner-links {
      border-top: 1px solid #D8D8D8;
      padding-top: 15px;
      margin-left: 30px;
      margin-right: 30px;
      display: flex;
      flex-direction: column;
      align-items: center; }
      footer .footer-bottom .partner-links__text {
        flex: 1;
        color: #767676;
        padding: 0;
        font-size: 0.8em;
        text-align: center;
        font-size: 10px;
        font-size: 0.83333rem;
        line-height: 18px;
        line-height: 1.5rem;
        margin-bottom: 8px; }
      footer .footer-bottom .partner-links__img {
        display: flex;
        flex-wrap: wrap;
        justify-content: center; }
        footer .footer-bottom .partner-links__img a {
          margin-bottom: 0;
          margin-left: 15px;
          height: 40px;
          max-width: 130px; }
          footer .footer-bottom .partner-links__img a img, footer .footer-bottom .partner-links__img a svg {
            height: 100%;
            width: 100%; }
    @media (min-width: 980px) {
      footer .footer-bottom {
        flex-direction: row;
        display: flex;
        border-top: 1px solid #D8D8D8;
        padding: 20px 0;
        justify-content: space-between;
        align-content: center; }
        footer .footer-bottom .footer-logo {
          order: 1;
          border-top: none;
          padding: 0;
          margin: 0;
          flex-direction: row; }
          footer .footer-bottom .footer-logo img {
            margin-right: 20px; }
        footer .footer-bottom .footer-social {
          order: 2;
          border: none;
          padding: 0;
          margin: 0; }
        footer .footer-bottom .partner-links {
          order: 3;
          border-top: none;
          padding: 0;
          margin: 0;
          flex-direction: row; } }

.videogallery footer {
  background: #1b1b1b;
  color: white; }

@media (min-width: 980px) {
  .videogallery .footer-content .footer-columns {
    border-top: 1px solid rgba(225, 225, 225, 0.15); } }

.videogallery .footer-content .footer-columns .accordion h4 {
  color: white; }

.videogallery .footer-content .footer-columns .accordion .plus-icon:before, .videogallery .footer-content .footer-columns .accordion .plus-icon:after {
  background-color: #a7a7a7; }

.videogallery .footer-content .footer-columns .panel .wrapper ul li a {
  color: white;
  opacity: .6; }
  .videogallery .footer-content .footer-columns .panel .wrapper ul li a:hover {
    color: white;
    opacity: 1; }

.videogallery .footer-bottom .footer-logo {
  border-top: 1px solid #a7a7a7; }
  @media (min-width: 980px) {
    .videogallery .footer-bottom .footer-logo {
      border-top: 0; } }
  .videogallery .footer-bottom .footer-logo a {
    color: white; }
    .videogallery .footer-bottom .footer-logo a svg {
      fill: #fff; }
  .videogallery .footer-bottom .footer-logo .copyright-content p {
    color: #a7a7a7; }

.videogallery .footer-bottom .partner-links {
  border-top: 1px solid #a7a7a7; }
  @media (min-width: 980px) {
    .videogallery .footer-bottom .partner-links {
      border-top: 0; } }
  .videogallery .footer-bottom .partner-links__text {
    color: #a7a7a7; }
  .videogallery .footer-bottom .partner-links__img a svg {
    fill: #fff; }

@media (min-width: 980px) {
  .videogallery .footer-bottom {
    border-top: 1px solid rgba(225, 225, 225, 0.15); } }

.imgZoom {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  transition: 0.2s cubic-bezier(0, 0, 0, 1); }
  .imgZoom:hover {
    transform: scale(1.1); }

.img:hover .imgZoom {
  transform: scale(1.1); }

.fadeUp {
  visibility: hidden; }

.main {
  padding-top: 30px; }
  @media (min-width: 1023px) {
    .main {
      padding-top: 5px; } }
  .main.interim {
    padding-top: 0px; }

.hero {
  position: relative;
  min-height: 298px;
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  flex-direction: column;
  justify-content: center; }
  .hero.simple-header {
    margin-top: 32px; }
  .hero .has-mobile-img {
    padding: 0; }
    .hero .has-mobile-img .image-bg-header {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 324px; }
  .hero .hero-content {
    margin-top: 0;
    margin-bottom: 32px; }
  .hero.has-img {
    min-height: 540px; }
    .hero.has-img .hero-content {
      margin-top: 0; }
  @media (min-width: 980px) {
    .hero {
      min-height: 400px; }
      .hero .has-mobile-img {
        padding: 0 32px; }
        .hero .has-mobile-img .image-bg-header {
          height: 440px;
          border-bottom-left-radius: 160px; }
      .hero .hero-content {
        margin-top: 200px; }
      .hero.has-img {
        min-height: 540px; }
        .hero.has-img .hero-content {
          margin-top: 180px; }
      .hero.home-header {
        min-height: 680px; } }
  .hero .hero-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  @media (max-width: 979px) {
    .hero.has-mobile-img .hero-img.desktop {
      display: none; } }
  @media (min-width: 980px) {
    .hero.has-desktop-img .hero-img.mobile {
      display: none; } }
  .hero .hero-content {
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    max-width: 700px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .hero .hero-content p {
      font-size: 16px;
      font-size: 1.33333rem;
      line-height: 24px;
      line-height: 2rem;
      letter-spacing: 0.6px; }
    .hero .hero-content h2, .hero .hero-content h3, .hero .hero-content h4, .hero .hero-content p {
      opacity: .7; }
    .hero .hero-content h1, .hero .hero-content p {
      color: black; }
    .hero .hero-content .button {
      margin-top: 40px;
      align-self: center; }
  .hero .explore {
    letter-spacing: 0.86px;
    opacity: .7; }
  .hero.dark .hero-content h1, .hero.dark .hero-content h2, .hero.dark .hero-content h3, .hero.dark .hero-content h4, .hero.dark .hero-content p {
    color: white; }
  .hero.dark .explore {
    color: white; }
    .hero.dark .explore .wrap {
      color: white; }
  .hero.dark:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: black;
    background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 100%);
    background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
    z-index: 1; }
  .hero .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2; }

.explore {
  position: absolute;
  bottom: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  letter-spacing: 0.86px; }
  .explore .wrap {
    height: 16px;
    width: 16px;
    text-align: center;
    position: absolute;
    bottom: -30px; }
  .explore svg {
    width: auto; }
  .explore .wrap svg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 16px;
    height: 16px; }
  .explore .bounce {
    animation-iteration-count: infinite;
    animation-duration: 1.5s;
    animation: bounce 3.6s ease infinite;
    transform-origin: 50% 50%; }

@keyframes bounce {
  0% {
    transform: translateY(0); }
  5.55556% {
    transform: translateY(0); }
  11.11111% {
    transform: translateY(0); }
  22.22222% {
    transform: translateY(-5px); }
  27.77778% {
    transform: translateY(0); }
  33.33333% {
    transform: translateY(-5px); }
  44.44444% {
    transform: translateY(0); }
  100% {
    transform: translateY(0); } }

.videogallery .hero-content h1, .videogallery .hero-content p {
  color: white; }

.videogallery .hero-content .explore {
  color: white; }
  .videogallery .hero-content .explore .wrap {
    color: white; }

.videogallery .hero-content:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: black;
  background: -moz-linear-gradient(180deg, black 0%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(180deg, black 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(180deg, black 0%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
  z-index: 1; }

.button:not(.text-link) {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  display: flex;
  align-items: center;
  padding: 19px 32px 17px 32px;
  border: 1px solid #000;
  border-right-color: transparent;
  transition: color 0.2s cubic-bezier(0, 0, 0, 1);
  text-decoration: none;
  letter-spacing: 1.6px;
  position: relative;
  outline: none;
  text-transform: uppercase;
  align-self: flex-start;
  cursor: pointer;
  background: #fff;
  height: 47px; }
  .button:not(.text-link) .btn-text {
    font-size: 11px;
    font-size: 0.91667rem;
    line-height: 11px;
    line-height: 0.91667rem;
    color: #000;
    z-index: 2; }
  .button:not(.text-link):before {
    content: '';
    width: 48px;
    height: 47px;
    border: 0;
    border-top: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    transform: skewX(-45deg);
    position: absolute;
    right: -25px;
    top: -1px;
    z-index: 1;
    background-color: #fff; }
  .button:not(.text-link):after {
    content: '';
    width: 56px;
    height: 1px;
    background: #000;
    position: absolute;
    top: 50%;
    right: -52px;
    z-index: 2;
    /* mix-blend-mode: difference;*/
    filter: invert(50%); }
  .button:not(.text-link) div.hover {
    position: absolute;
    left: -1px;
    top: -1px;
    bottom: -1px;
    width: calc(100% + 51px);
    z-index: 1;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    overflow: hidden;
    transition: 0.2s cubic-bezier(0, 0, 0, 1); }
    .button:not(.text-link) div.hover:before {
      content: '';
      background: #000;
      height: 100%;
      width: 0;
      position: absolute;
      left: -25px;
      top: 0;
      bottom: 0;
      right: 0;
      transition: 0.2s cubic-bezier(0, 0, 0, 1);
      transform: skewX(-45deg);
      z-index: 1; }
  .button:not(.text-link):hover .btn-text {
    color: white; }
  .button:not(.text-link):hover div.hover:before {
    width: 100%; }
  .button:not(.text-link).back svg {
    margin-right: 16px;
    z-index: 2; }
  .button:not(.text-link).button-black {
    color: #000;
    background: #fff;
    border: 1px solid #000; }
    .button:not(.text-link).button-black:hover {
      background: #000;
      color: #fff; }
      .button:not(.text-link).button-black:hover:before {
        background: #000; }
  .button:not(.text-link).white-solid {
    color: black;
    background: #fff;
    border: 1px solid #fff; }
    .button:not(.text-link).white-solid:before {
      border-color: #fff;
      background: white; }
    .button:not(.text-link).white-solid:after {
      background: #fff;
      mix-blend-mode: difference;
      filter: none; }
    .button:not(.text-link).white-solid div.hover:before {
      background: #EEEEEE; }
    .button:not(.text-link).white-solid:hover .btn-text {
      color: black; }
  .button:not(.text-link).light-grey {
    background: #EEEEEE;
    border: 1px #EEEEEE; }
    .button:not(.text-link).light-grey:before {
      background: #EEEEEE;
      border-color: #EEEEEE; }
    .button:not(.text-link).light-grey div.hover:before {
      background: #E0E0E0; }
    .button:not(.text-link).light-grey:hover {
      border-color: #EEEEEE; }
      .button:not(.text-link).light-grey:hover .btn-text {
        color: black; }
  .button:not(.text-link).grey-solid {
    color: white;
    background: #3C3C3C;
    border: 1px solid #3C3C3C; }
    .button:not(.text-link).grey-solid .btn-text {
      color: white; }
    .button:not(.text-link).grey-solid:before {
      border-right-color: #3C3C3C;
      border-top-color: #3C3C3C;
      background: #3C3C3C; }
    .button:not(.text-link).grey-solid:after {
      background: #7C7C7C;
      mix-blend-mode: initial; }
    .button:not(.text-link).grey-solid div.hover:before {
      background: #E3E3E3; }
    .button:not(.text-link).grey-solid:hover .btn-text {
      color: black; }
  .button:not(.text-link).btn-disabled {
    color: #000;
    background: #fff;
    border: 1px solid #000;
    opacity: .3; }
    .button:not(.text-link).btn-disabled .btn-text {
      color: #000; }
    .button:not(.text-link).btn-disabled div.hover {
      cursor: not-allowed; }
      .button:not(.text-link).btn-disabled div.hover::before {
        background: none; }
    .button:not(.text-link).btn-disabled:hover {
      cursor: not-allowed; }
      .button:not(.text-link).btn-disabled:hover .btn-text {
        color: none; }
  .button:not(.text-link).transparent {
    color: black;
    background: transparent; }
    .button:not(.text-link).transparent:before {
      border-right-color: #000;
      border-top-color: #000;
      background: transparent; }
    .button:not(.text-link).transparent:after {
      background: #000;
      mix-blend-mode: difference; }
    .button:not(.text-link).transparent div.hover:before {
      background: #000; }
    .button:not(.text-link).transparent:hover .btn-text {
      color: #fff; }

.text-link {
  background: none;
  border: none;
  outline: none;
  letter-spacing: 0.6px; }

.faqSection.container {
  padding: 0; }
  @media (min-width: 768px) {
    .faqSection.container {
      padding: 0 32px; } }
  .faqSection.container h2 {
    padding: 0 32px;
    margin-bottom: 3rem; }
    @media (min-width: 768px) {
      .faqSection.container h2 {
        padding: 0; } }
  .faqSection.container .faqs .accordion,
  .faqSection.container .faqs .accordion-multi {
    background: white;
    cursor: pointer;
    padding: 24px 32px;
    width: 100%;
    transition: 0.2s cubic-bezier(0, 0, 0, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #DBDBDB;
    font-family: 'GT-America-Expanded-Medium';
    font-size: 12px;
    font-size: 1rem;
    line-height: 20px;
    line-height: 1.66667rem;
    letter-spacing: .6px;
    border-bottom: none;
    border-left: none;
    border-right: none; }
    .faqSection.container .faqs .accordion .plus-icon,
    .faqSection.container .faqs .accordion-multi .plus-icon {
      width: 20px;
      height: 20px;
      position: relative; }
      .faqSection.container .faqs .accordion .plus-icon:before, .faqSection.container .faqs .accordion .plus-icon:after,
      .faqSection.container .faqs .accordion-multi .plus-icon:before,
      .faqSection.container .faqs .accordion-multi .plus-icon:after {
        content: "";
        position: absolute;
        background-color: #000;
        transition: transform 0.2s cubic-bezier(0, 0, 0, 1); }
      .faqSection.container .faqs .accordion .plus-icon:before,
      .faqSection.container .faqs .accordion-multi .plus-icon:before {
        top: 0;
        left: 50%;
        width: 1px;
        height: 100%;
        margin-left: -1px; }
      .faqSection.container .faqs .accordion .plus-icon:after,
      .faqSection.container .faqs .accordion-multi .plus-icon:after {
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        margin-top: -1px; }
    .faqSection.container .faqs .accordion .arrow-icon,
    .faqSection.container .faqs .accordion-multi .arrow-icon {
      height: 20px;
      width: 35px;
      display: block;
      position: relative;
      cursor: pointer; }
      .faqSection.container .faqs .accordion .arrow-icon .left-bar,
      .faqSection.container .faqs .accordion .arrow-icon .right-bar,
      .faqSection.container .faqs .accordion-multi .arrow-icon .left-bar,
      .faqSection.container .faqs .accordion-multi .arrow-icon .right-bar {
        position: absolute;
        background-color: transparent;
        top: 50%;
        width: 20px;
        height: 5px;
        display: block;
        border-radius: 2px; }
        .faqSection.container .faqs .accordion .arrow-icon .left-bar:after,
        .faqSection.container .faqs .accordion .arrow-icon .right-bar:after,
        .faqSection.container .faqs .accordion-multi .arrow-icon .left-bar:after,
        .faqSection.container .faqs .accordion-multi .arrow-icon .right-bar:after {
          content: "";
          background-color: #f4f4f4;
          width: 20px;
          height: 5px;
          display: block;
          transition: all 0.2s cubic-bezier(0, 0, 0, 1);
          z-index: -1; }
      .faqSection.container .faqs .accordion .arrow-icon .left-bar,
      .faqSection.container .faqs .accordion-multi .arrow-icon .left-bar {
        left: 0;
        transform: translateY(-50%) rotate(35deg); }
        .faqSection.container .faqs .accordion .arrow-icon .left-bar:after,
        .faqSection.container .faqs .accordion-multi .arrow-icon .left-bar:after {
          border-radius: 6px 10px 10px 6px; }
      .faqSection.container .faqs .accordion .arrow-icon .right-bar,
      .faqSection.container .faqs .accordion-multi .arrow-icon .right-bar {
        left: 15px;
        transform: translateY(-50%) rotate(-35deg); }
        .faqSection.container .faqs .accordion .arrow-icon .right-bar:after,
        .faqSection.container .faqs .accordion-multi .arrow-icon .right-bar:after {
          border-radius: 10px 6px 6px 10px; }
    .faqSection.container .faqs .accordion.active, .faqSection.container .faqs .accordion:hover,
    .faqSection.container .faqs .accordion-multi.active,
    .faqSection.container .faqs .accordion-multi:hover {
      background-color: #f4f4f4; }
      .faqSection.container .faqs .accordion.active .plus-icon:before, .faqSection.container .faqs .accordion.active .plus-icon:after, .faqSection.container .faqs .accordion:hover .plus-icon:before, .faqSection.container .faqs .accordion:hover .plus-icon:after,
      .faqSection.container .faqs .accordion-multi.active .plus-icon:before,
      .faqSection.container .faqs .accordion-multi.active .plus-icon:after,
      .faqSection.container .faqs .accordion-multi:hover .plus-icon:before,
      .faqSection.container .faqs .accordion-multi:hover .plus-icon:after {
        background-color: #000; }
      .faqSection.container .faqs .accordion.active .arrow-icon .left-bar:after,
      .faqSection.container .faqs .accordion.active .arrow-icon .right-bar:after, .faqSection.container .faqs .accordion:hover .arrow-icon .left-bar:after,
      .faqSection.container .faqs .accordion:hover .arrow-icon .right-bar:after,
      .faqSection.container .faqs .accordion-multi.active .arrow-icon .left-bar:after,
      .faqSection.container .faqs .accordion-multi.active .arrow-icon .right-bar:after,
      .faqSection.container .faqs .accordion-multi:hover .arrow-icon .left-bar:after,
      .faqSection.container .faqs .accordion-multi:hover .arrow-icon .right-bar:after {
        background-color: #000; }
    .faqSection.container .faqs .accordion.active,
    .faqSection.container .faqs .accordion-multi.active {
      border-bottom: 1px solid #DBDBDB; }
      .faqSection.container .faqs .accordion.active .plus-icon:before,
      .faqSection.container .faqs .accordion-multi.active .plus-icon:before {
        transform: rotate(90deg); }
      .faqSection.container .faqs .accordion.active .plus-icon:after,
      .faqSection.container .faqs .accordion-multi.active .plus-icon:after {
        transform: rotate(180deg); }
      .faqSection.container .faqs .accordion.active .arrow-icon .left-bar:after,
      .faqSection.container .faqs .accordion-multi.active .arrow-icon .left-bar:after {
        transform-origin: center center;
        transform: rotate(-70deg); }
      .faqSection.container .faqs .accordion.active .arrow-icon .right-bar:after,
      .faqSection.container .faqs .accordion-multi.active .arrow-icon .right-bar:after {
        transform-origin: center center;
        transform: rotate(70deg); }
  .faqSection.container .faqs .panel {
    max-height: 0;
    overflow: hidden;
    transition: 0.2s cubic-bezier(0, 0, 0, 1); }
    .faqSection.container .faqs .panel.active {
      max-height: 600px; }
    .faqSection.container .faqs .panel .wrapper {
      padding: 2.5rem; }
      @media (min-width: 768px) {
        .faqSection.container .faqs .panel .wrapper {
          width: 74%; } }
    .faqSection.container .faqs .panel p:last-child {
      margin: 0; }
  .faqSection.container div:last-of-type .accordion,
  .faqSection.container div:last-of-type .accordion-multi {
    border-bottom: 1px solid #DBDBDB; }

.listing {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -30px; }
  @media (min-width: 768px) {
    .listing {
      margin: 0; } }
  .listing .listing-item {
    width: 100%;
    display: flex;
    flex-wrap: wrap; }
    .listing .listing-item .img {
      width: 100%;
      height: 220px;
      overflow: hidden; }
      .listing .listing-item .img .img-holder {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        transition: 0.2s cubic-bezier(0, 0, 0, 1);
        background-color: #DBDBDB; }
      .listing .listing-item .img:hover .img-holder {
        transform: scale(1.1); }
    .listing .listing-item .content {
      padding: 24px;
      display: flex;
      flex-direction: column;
      flex-grow: 1; }
      .listing .listing-item .content .date {
        font-size: 12px;
        font-size: 1rem;
        line-height: 20px;
        line-height: 1.66667rem;
        text-transform: uppercase;
        color: #767676;
        margin-bottom: 10px; }
      .listing .listing-item .content .title {
        font-size: 24px;
        font-size: 2rem;
        line-height: 32px;
        line-height: 2.66667rem;
        margin-bottom: 24px; }
        .listing .listing-item .content .title a {
          text-decoration: none;
          color: inherit;
          transition: 0.2s cubic-bezier(0, 0, 0, 1); }
        @media (min-width: 768px) {
          .listing .listing-item .content .title {
            font-size: 32px;
            font-size: 2.66667rem;
            line-height: 40px;
            line-height: 3.33333rem; } }
      .listing .listing-item .content .author {
        font-size: 14px;
        font-size: 1.16667rem;
        line-height: 22px;
        line-height: 1.83333rem; }
      .listing .listing-item .content .button-wrapper {
        display: flex;
        justify-content: center; }
        .listing .listing-item .content .button-wrapper .view-product {
          margin-left: -48px;
          padding-left: 20px;
          padding-right: 20px; }
      .listing .listing-item .content .button {
        margin-top: 32px; }
  .listing.grid.contact .content {
    padding-bottom: 0 !important; }
  .listing.grid.contact a {
    font-family: 'GT-America-Expanded-Medium'; }
  .listing.grid.contact p:last-child {
    margin-bottom: 0; }
  @media (min-width: 768px) {
    .listing.grid .listing-item {
      max-width: 50%;
      margin-bottom: 32px; }
      .listing.grid .listing-item:nth-child(even) {
        padding: 0 0 0 16px; }
        .listing.grid .listing-item:nth-child(even).product {
          padding: 0; }
      .listing.grid .listing-item .content {
        padding: 24px 0; }
        .listing.grid .listing-item .content .button {
          align-self: start; }
    .listing.grid.three .listing-item {
      max-width: 33.333333%; } }
  @media (min-width: 980px) {
    .listing.grid.four .listing-item {
      max-width: 25%; } }
  @media (min-width: 768px) {
    .listing:not(.grid) .listing-item {
      margin: 32px 0; }
      .listing:not(.grid) .listing-item .img {
        width: 50%;
        order: 2;
        height: 100%; }
      .listing:not(.grid) .listing-item .content {
        order: 1;
        width: 50%; }
        .listing:not(.grid) .listing-item .content .button {
          align-self: start; } }
  .listing:not(.grid).alternate .listing-item {
    padding: 64px 32px;
    margin: 0;
    justify-content: space-between; }
    .listing:not(.grid).alternate .listing-item .container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0;
      align-items: center; }
    .listing:not(.grid).alternate .listing-item.image-text-block {
      padding: 24px 0; }
      .listing:not(.grid).alternate .listing-item.image-text-block:before {
        display: none; }
      .listing:not(.grid).alternate .listing-item.image-text-block .img {
        max-width: 100%; }
        @media (min-width: 768px) {
          .listing:not(.grid).alternate .listing-item.image-text-block .img {
            max-width: 455px; } }
      .listing:not(.grid).alternate .listing-item.image-text-block .img-block-content {
        max-width: 100%; }
        @media (min-width: 768px) {
          .listing:not(.grid).alternate .listing-item.image-text-block .img-block-content {
            max-width: 520px; } }
    .listing:not(.grid).alternate .listing-item .content {
      order: 2;
      padding: 24px 0 0; }
      @media (min-width: 768px) {
        .listing:not(.grid).alternate .listing-item .content {
          max-width: 520px; } }
      .listing:not(.grid).alternate .listing-item .content small {
        font-size: 12px;
        font-size: 1rem;
        line-height: 24px;
        line-height: 2rem;
        color: white;
        opacity: .5;
        letter-spacing: .6px; }
      .listing:not(.grid).alternate .listing-item .content .title {
        font-size: 24px;
        font-size: 2rem;
        line-height: 32px;
        line-height: 2.66667rem; }
      .listing:not(.grid).alternate .listing-item .content .button {
        align-self: flex-start;
        margin: 24px 0 0; }
    .listing:not(.grid).alternate .listing-item .img {
      order: 1;
      height: 137px; }
      @media (min-width: 569px) {
        .listing:not(.grid).alternate .listing-item .img {
          height: 207px; } }
      @media (min-width: 768px) {
        .listing:not(.grid).alternate .listing-item .img {
          max-height: 315px;
          max-width: 455px; } }
      .listing:not(.grid).alternate .listing-item .img.img-text-block {
        height: 216px; }
        @media (min-width: 768px) {
          .listing:not(.grid).alternate .listing-item .img.img-text-block {
            height: 247px;
            padding-top: 24px; } }
        @media (min-width: 980px) {
          .listing:not(.grid).alternate .listing-item .img.img-text-block {
            height: 223px;
            padding-top: 0; } }
      .listing:not(.grid).alternate .listing-item .img.curve {
        border-bottom-left-radius: 80px; }
    .listing:not(.grid).alternate .listing-item:nth-child(even) .img.curve {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 80px; }
    @media (min-width: 768px) {
      .listing:not(.grid).alternate .listing-item {
        padding: 64px; }
        .listing:not(.grid).alternate .listing-item.image-text-block {
          padding: 24px 0; }
        .listing:not(.grid).alternate .listing-item .img {
          width: 48%;
          order: 2;
          height: 315px; }
          .listing:not(.grid).alternate .listing-item .img.curve {
            border-bottom-left-radius: 160px; }
        .listing:not(.grid).alternate .listing-item .content {
          padding: 24px 64px 24px 0;
          width: 50%;
          margin-right: 2%;
          order: 1; }
          .listing:not(.grid).alternate .listing-item .content.img-block-content {
            padding: 24px 24px 24px 0; }
        .listing:not(.grid).alternate .listing-item:nth-child(even) .img {
          order: 1; }
          .listing:not(.grid).alternate .listing-item:nth-child(even) .img.curve {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 160px; }
        .listing:not(.grid).alternate .listing-item:nth-child(even) .content {
          margin: 0 0 0 2%;
          order: 2;
          padding: 24px 0 24px 64px; } }
    @media (min-width: 980px) {
      .listing:not(.grid).alternate .listing-item .content {
        padding: 24px 24px 24px 40px; }
        .listing:not(.grid).alternate .listing-item .content.img-block-content {
          padding: 0 24px 24px 0; }
      .listing:not(.grid).alternate .listing-item:nth-child(even) .content {
        padding: 24px 0 24px 40px; } }
  .listing:not(.grid).alternate.dark {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto; }
    .listing:not(.grid).alternate.dark .listing-item {
      background-color: #1B1B1B;
      color: white; }
      .listing:not(.grid).alternate.dark .listing-item:nth-child(even) {
        background-color: #252525; }
      .listing:not(.grid).alternate.dark .listing-item p {
        color: white;
        opacity: .7;
        margin-bottom: 40px; }
  .listing:not(.grid).alternate.light {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto; }
    .listing:not(.grid).alternate.light .listing-item {
      position: relative; }
      .listing:not(.grid).alternate.light .listing-item:first-child:before {
        content: '';
        height: 1px;
        background-color: #DBDBDB;
        position: absolute;
        top: 0;
        left: 32px;
        right: 32px; }
        @media (min-width: 768px) {
          .listing:not(.grid).alternate.light .listing-item:first-child:before {
            left: 64px;
            right: 64px; } }
    .listing:not(.grid).alternate.light .listing-item:after {
      content: '';
      height: 1px;
      background-color: #DBDBDB;
      position: absolute;
      bottom: 0;
      left: 32px;
      right: 32px; }
      @media (min-width: 768px) {
        .listing:not(.grid).alternate.light .listing-item:after {
          left: 64px;
          right: 64px; } }

.tabs {
  width: 100%; }
  .tabs [role="tablist"] {
    margin: 0 0 -0.1em;
    overflow: visible; }
  .tabs [role="tab"] {
    position: relative;
    margin: 0;
    padding: 15px 24px;
    border: 1px solid #DBDBDB;
    overflow: visible;
    background-color: #f2f2f2; }
  .tabs [role="tab"][aria-selected="true"] {
    border-radius: 0;
    background: #000;
    color: #fff;
    outline: 0; }
  .tabs [role="tab"][aria-selected="false"]:before {
    border-color: #f2f2f2 transparent transparent transparent !important; }
  .tabs [role="tab"]:hover,
  .tabs [role="tab"]:focus,
  .tabs [role="tab"]:active {
    outline: 0;
    border-radius: 0; }
  .tabs [role="tabpanel"]:focus {
    outline: 0; }
  .tabs [role="tabpanel"]:focus::after {
    position: absolute;
    bottom: 0;
    right: -1px;
    left: -1px;
    content: ''; }
  .tabs [role="tabpanel"] p {
    margin: 0; }
  .tabs [role="tabpanel"] * + p {
    margin-top: 1em; }

@media (min-width: 900px) {
  .search-results-page {
    margin-top: 200px; } }

.search {
  position: relative;
  margin-bottom: 40px; }

::selection {
  background: #212129;
  color: #fff; }

.search-wrapper {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%; }

.search-wrapper .input-holder {
  height: 70px;
  width: 70px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0);
  border-radius: 6px;
  position: relative;
  transition: all 0.3s ease-in-out; }

.search-wrapper.active .input-holder {
  width: 450px;
  border-radius: 50px;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.5s cubic-bezier(0, 0.105, 0.035, 1.57); }

.search-wrapper .input-holder .search-input {
  width: 100%;
  height: 50px;
  padding: 0px 70px 0 20px;
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  background: transparent;
  box-sizing: border-box;
  border: none;
  outline: none;
  color: #FFF;
  transform: translate(0, 60px);
  transition: all 0.3s cubic-bezier(0, 0.105, 0.035, 1.57);
  transition-delay: 0.3s; }

.search-wrapper.active .input-holder .search-input {
  opacity: 1;
  transform: translate(0, 10px); }

.search-wrapper .input-holder .search-icon {
  width: 70px;
  height: 70px;
  border: none;
  border-radius: 6px;
  background: #FFF;
  padding: 0px;
  outline: none;
  position: relative;
  z-index: 2;
  float: right;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 9; }

.search-wrapper.active .input-holder .search-icon {
  width: 50px;
  height: 50px;
  margin: 10px;
  border-radius: 30px; }

.search-wrapper .input-holder .search-icon span {
  width: 22px;
  height: 22px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  transform: rotate(45deg);
  transition: all 0.4s cubic-bezier(0.65, -0.6, 0.24, 1.65); }

.search-wrapper.active .input-holder .search-icon span {
  transform: rotate(-45deg); }

.search-wrapper .input-holder .search-icon span::before, .search-wrapper .input-holder .search-icon span::after {
  position: absolute;
  content: ''; }

.search-wrapper .input-holder .search-icon span::before {
  width: 4px;
  height: 11px;
  left: 9px;
  top: 18px;
  border-radius: 2px;
  background: #FE5F55; }

.search-wrapper .input-holder .search-icon span::after {
  width: 14px;
  height: 14px;
  left: 0px;
  top: 0px;
  border-radius: 16px;
  border: 4px solid #FE5F55; }

.search-wrapper .close {
  position: absolute;
  z-index: 1;
  top: 24px;
  right: 20px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  transform: rotate(-180deg);
  transition: all 0.3s cubic-bezier(0.285, -0.45, 0.935, 0.11);
  transition-delay: 0.2s; }

.search-wrapper.active .close {
  right: -50px;
  transform: rotate(45deg);
  transition: all 0.6s cubic-bezier(0, 0.105, 0.035, 1.57);
  transition-delay: 0.5s; }

.search-wrapper .close::before, .search-wrapper .close::after {
  position: absolute;
  content: '';
  background: #FE5F55;
  border-radius: 2px; }

.search-wrapper .close::before {
  width: 5px;
  height: 25px;
  left: 10px;
  top: 0px; }

.search-wrapper .close::after {
  width: 25px;
  height: 5px;
  left: 0px;
  top: 10px; }

.no-results-heading {
  margin-top: 75px; }

.results {
  border-top: 1px solid #DBDBDB; }
  .results .result {
    padding: 32px 0;
    border-bottom: 1px solid #DBDBDB; }
    .results .result .title {
      font-size: 24px;
      font-size: 2rem;
      line-height: 32px;
      line-height: 2.66667rem;
      margin-bottom: 24px; }
    .results .result p {
      opacity: .7;
      word-wrap: break-word; }
    .results .result a {
      font-family: 'GT-America-Expanded-Medium'; }
      .results .result a:hover {
        text-decoration: underline; }

.results-page {
  display: flex;
  align-items: center;
  margin-right: 10px; }
  @media (min-width: 768px) {
    .results-page {
      margin-right: 50px; } }
  @media (min-width: 1400px) {
    .results-page {
      margin-right: 0px; } }
  .results-page .input-wrapper {
    background: #F5F5F5;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    padding: 14px 0 14px 20px;
    width: calc(100% - 50px); }
    @media (min-width: 980px) {
      .results-page .input-wrapper {
        padding: 24px 140px 24px 54px; } }
    .results-page .input-wrapper:after {
      content: '';
      width: 20px;
      height: 1px;
      background: #000;
      position: absolute;
      top: 50%;
      right: 100px;
      z-index: 2;
      filter: invert(50%); }
      @media (min-width: 980px) {
        .results-page .input-wrapper:after {
          width: 56px;
          right: 85px; } }
  .results-page input[type=text] {
    border: 0;
    outline: 0;
    background-color: transparent;
    font-size: 16px;
    font-size: 1.33333rem;
    line-height: 24px;
    line-height: 2rem;
    width: 100%; }
    @media (min-width: 768px) {
      .results-page input[type=text] {
        font-size: 24px;
        font-size: 2rem;
        line-height: 32px;
        line-height: 2.66667rem; } }
  .results-page .submit-wrapper {
    background: #EEEEEE;
    height: 100%;
    width: 88px;
    transform: skewX(-45deg);
    display: flex;
    align-items: center;
    position: absolute;
    right: 20px;
    transition: 0.2s cubic-bezier(0, 0, 0, 1); }
    @media (min-width: 980px) {
      .results-page .submit-wrapper {
        height: 80px;
        width: 110px;
        right: 0; } }
    .results-page .submit-wrapper input[type=submit] {
      transform: skewX(45deg);
      border: 0;
      outline: 0;
      background: transparent url(../../img/icon-search-black.svg) no-repeat center;
      background-size: 24px;
      font-size: 0;
      text-indent: -9999px;
      width: 100%;
      height: 100%; }
    .results-page .submit-wrapper:hover {
      background: black; }
      .results-page .submit-wrapper:hover input[type=submit] {
        background: transparent url(../../img/icon-search-white.svg) no-repeat center;
        background-size: 24px; }

.pagination-container {
  padding: 0; }
  .pagination-container .pagination {
    margin: 32px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; }
    .pagination-container .pagination .button {
      padding: 15px 24px 13px 32px;
      margin: 0 60px 0 0;
      height: 32px; }
      .pagination-container .pagination .button:before {
        height: 32px; }
      .pagination-container .pagination .button.pag-prev {
        order: 2; }
      .pagination-container .pagination .button.pag-next {
        transform: rotate(180deg);
        margin: 0 0 0 60px;
        order: 3; }
        .pagination-container .pagination .button.pag-next:after {
          margin-top: -1px; }
    .pagination-container .pagination .pag-numbers {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%; }
      .pagination-container .pagination .pag-numbers span {
        margin: 0 5px;
        font-weight: 700; }
      .pagination-container .pagination .pag-numbers .pag-number {
        width: 55px;
        height: 55px;
        margin: 0 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000000;
        transition: 0.2s cubic-bezier(0, 0, 0, 1); }
        .pagination-container .pagination .pag-numbers .pag-number:hover, .pagination-container .pagination .pag-numbers .pag-number.active {
          text-decoration: none; }
        .pagination-container .pagination .pag-numbers .pag-number.active {
          font-family: 'GT-America-Expanded-Medium'; }
    @media (min-width: 768px) {
      .pagination-container .pagination {
        flex-wrap: nowrap; }
        .pagination-container .pagination .button {
          height: 47px; }
          .pagination-container .pagination .button.pag-prev {
            order: 1; }
          .pagination-container .pagination .button.pag-next {
            order: 3; }
          .pagination-container .pagination .button:before {
            height: 47px; }
        .pagination-container .pagination .pag-numbers {
          width: auto;
          order: 2; } }

.videogallery .pagination .button {
  background-color: #1b1b1b;
  color: #A4A4A4;
  border: 1px solid #A4A4A4; }
  .videogallery .pagination .button span {
    color: #A4A4A4; }
  .videogallery .pagination .button:before {
    background-color: #1b1b1b;
    border-color: #A4A4A4;
    border-left-color: transparent; }
  .videogallery .pagination .button .hover:before {
    background-color: white; }
  .videogallery .pagination .button:hover {
    color: black; }
    .videogallery .pagination .button:hover span {
      color: black; }

.videogallery .pagination .pag-numbers .pag-number {
  color: #A4A4A4; }
  .videogallery .pagination .pag-numbers .pag-number:hover, .videogallery .pagination .pag-numbers .pag-number.active {
    color: white; }

select {
  display: none; }
  select:focus {
    border-bottom: 1px solid #000;
    outline: none; }
  select::placeholder, select::-webkit-input-placeholder, select:-ms-input-placeholder {
    color: #000;
    font-size: 16px;
    font-size: 1.33333rem;
    line-height: 24px;
    line-height: 2rem;
    letter-spacing: .6px;
    opacity: .7; }

.bv_mainselect {
  position: relative;
  width: 100%;
  border: 0;
  padding: 16px 16px 16px 0; }
  .bv_mainselect .bv_atual {
    position: relative;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    background: transparent;
    width: 100%;
    padding: 8px 40px 16px 0;
    opacity: .7;
    color: #000;
    font-size: 16px;
    font-size: 1.33333rem;
    line-height: 24px;
    line-height: 2rem;
    letter-spacing: .6px;
    border-radius: 0; }
    .bv_mainselect .bv_atual .arrow {
      border: solid #000;
      border-width: 0 1px 1px 0;
      position: absolute;
      right: 8px; }
      .bv_mainselect .bv_atual .arrow.down {
        margin-top: 6px; }
      .bv_mainselect .bv_atual .arrow.up {
        margin-top: 12px; }
  .bv_mainselect .bv_ul_inner {
    text-align: center;
    background-color: #2c2c2c;
    border-radius: 0;
    margin-top: 0;
    border: none;
    z-index: 999; }
    .bv_mainselect .bv_ul_inner.bv_ul_mobile {
      overflow: auto; }
    .bv_mainselect .bv_ul_inner .bv_disabled {
      background-color: #3b3b3b !important; }
    .bv_mainselect .bv_ul_inner > li {
      color: rgba(255, 255, 255, 0.7);
      padding: 16px;
      cursor: pointer;
      user-select: none;
      letter-spacing: .6px; }
      .bv_mainselect .bv_ul_inner > li:first-child {
        display: none !important; }
      .bv_mainselect .bv_ul_inner > li:hover {
        background-color: #3b3b3b; }

.form-pod .form-wrapper {
  background-color: #f4f4f4;
  border-bottom-right-radius: 160px;
  padding: 64px 32px; }

.form-pod .form-pod-header {
  text-align: center;
  margin-bottom: 32px; }
  @media (min-width: 768px) {
    .form-pod .form-pod-header {
      width: 50%;
      margin: 0 auto;
      padding: 0 32px 32px;
      max-width: 456px; } }

.form-pod .umbraco-forms-submitmessage {
  text-align: center;
  display: block;
  font-size: 24px;
  font-size: 2rem;
  line-height: 32px;
  line-height: 2.66667rem;
  max-width: 80%;
  margin: 0 auto; }
  @media (min-width: 768px) {
    .form-pod .umbraco-forms-submitmessage {
      font-size: 32px;
      font-size: 2.66667rem;
      line-height: 40px;
      line-height: 3.33333rem;
      max-width: 50%; } }

.umbraco-forms-fieldset {
  border: none;
  padding: 0; }
  .umbraco-forms-fieldset .umbraco-forms-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto; }
    @media (min-width: 768px) {
      .umbraco-forms-fieldset .umbraco-forms-container {
        max-width: 456px; } }
    .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field {
      margin-bottom: 30px;
      width: 100%; }
      @media (min-width: 768px) {
        .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field {
          max-width: 400px; } }
      .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field .field-validation-error,
      .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field .parsley-errors-list.filled {
        display: block;
        color: #dd1515;
        background-color: #fedede;
        padding: 8px 8px 8px 32px;
        position: relative;
        font-family: 'GT-America-Expanded-Medium';
        font-size: 10px;
        margin-top: 10px; }
        .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field .field-validation-error:before,
        .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field .parsley-errors-list.filled:before {
          position: absolute;
          content: '';
          background-image: url("/assets/img/icon-alert.svg");
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          left: 8px; }
      .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field .parsley-errors-list {
        list-style-type: none; }
      .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field .umbraco-forms-label {
        margin-left: 0;
        font-size: 12px;
        font-size: 1rem;
        line-height: 24px;
        line-height: 2rem;
        letter-spacing: .6px;
        opacity: 0.7; }
      .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field input[type=text],
      .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field input[type=email] {
        border: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        padding: 10px 0;
        background: transparent;
        width: 100%;
        padding: 16px 0;
        color: #000;
        font-size: 16px;
        font-size: 1.33333rem;
        line-height: 24px;
        line-height: 2rem;
        letter-spacing: .6px; }
        @media (min-width: 768px) {
          .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field input[type=text],
          .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field input[type=email] {
            width: 400px; } }
        .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field input[type=text]:focus,
        .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field input[type=email]:focus {
          border-bottom: 1px solid #000;
          outline: none; }
        .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field input[type=text]::placeholder, .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field input[type=text]::-webkit-input-placeholder, .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field input[type=text]:-ms-input-placeholder,
        .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field input[type=email]::placeholder,
        .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field input[type=email]::-webkit-input-placeholder,
        .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field input[type=email]:-ms-input-placeholder {
          color: #000;
          font-size: 16px;
          font-size: 1.33333rem;
          line-height: 24px;
          line-height: 2rem;
          letter-spacing: .6px;
          opacity: .7; }
      .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field.checkboxforsomeinformationrequest {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center; }
        @media (min-width: 768px) {
          .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field.checkboxforsomeinformationrequest {
            width: 400px; } }
        .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field.checkboxforsomeinformationrequest > label {
          margin-top: 12px;
          margin-left: 24px; }
        .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field.checkboxforsomeinformationrequest .umbraco-forms-field-wrapper label {
          margin-top: 7px;
          position: relative;
          padding-left: 13px; }
          .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field.checkboxforsomeinformationrequest .umbraco-forms-field-wrapper label input[type=checkbox] {
            display: none;
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            order: 1;
            margin-top: 3px; }
            .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field.checkboxforsomeinformationrequest .umbraco-forms-field-wrapper label input[type=checkbox]:checked ~ .checkmark:after {
              display: block; }
          .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field.checkboxforsomeinformationrequest .umbraco-forms-field-wrapper label .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 24px;
            width: 24px;
            background-color: transparent;
            border: 1px solid black; }
            .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field.checkboxforsomeinformationrequest .umbraco-forms-field-wrapper label .checkmark:after {
              content: url(../../img/icon-tick.svg);
              position: absolute;
              display: none;
              left: 4px;
              top: 4px;
              width: 16px;
              height: 16px; }
      .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field .checkbox,
      .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field .checkboxlist {
        margin-top: 10px;
        display: flex;
        flex-direction: column; }
        .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field .checkbox a,
        .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field .checkboxlist a {
          text-decoration: underline !important; }
        .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field .checkbox label,
        .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field .checkboxlist label {
          font-size: 14px;
          font-size: 1.16667rem;
          line-height: 22px;
          line-height: 1.83333rem;
          font-weight: 400;
          user-select: none;
          display: block;
          position: relative;
          padding-left: 25px;
          order: 2;
          text-transform: none;
          padding: 3px 32px; }
        .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field .checkbox input,
        .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field .checkboxlist input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
          order: 1;
          margin-top: 3px; }
          .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field .checkbox input:checked ~ .checkmark:after,
          .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field .checkboxlist input:checked ~ .checkmark:after {
            display: block; }
        .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field .checkbox .checkmark,
        .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field .checkboxlist .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 24px;
          width: 24px;
          background-color: transparent;
          border: 1px solid black; }
          .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field .checkbox .checkmark:after,
          .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field .checkboxlist .checkmark:after {
            content: url(../../img/icon-tick.svg);
            position: absolute;
            display: none;
            left: 4px;
            top: 2px;
            width: 16px;
            height: 16px; }
    @media (min-width: 768px) {
      .umbraco-forms-fieldset .umbraco-forms-container .multichoice, .umbraco-forms-fieldset .umbraco-forms-container .radiobuttonlist, .umbraco-forms-fieldset .umbraco-forms-container .singlechoice, .umbraco-forms-fieldset .umbraco-forms-container .dataconsent {
        width: 400px; } }
    .umbraco-forms-fieldset .umbraco-forms-container .multichoice .umbraco-forms-field-wrapper, .umbraco-forms-fieldset .umbraco-forms-container .radiobuttonlist .umbraco-forms-field-wrapper, .umbraco-forms-fieldset .umbraco-forms-container .singlechoice .umbraco-forms-field-wrapper, .umbraco-forms-fieldset .umbraco-forms-container .dataconsent .umbraco-forms-field-wrapper {
      margin-top: 10px; }
      .umbraco-forms-fieldset .umbraco-forms-container .multichoice .umbraco-forms-field-wrapper .checkbox label, .umbraco-forms-fieldset .umbraco-forms-container .multichoice .umbraco-forms-field-wrapper .radiobuttonlist label, .umbraco-forms-fieldset .umbraco-forms-container .multichoice .umbraco-forms-field-wrapper .checkboxlist label, .umbraco-forms-fieldset .umbraco-forms-container .radiobuttonlist .umbraco-forms-field-wrapper .checkbox label, .umbraco-forms-fieldset .umbraco-forms-container .radiobuttonlist .umbraco-forms-field-wrapper .radiobuttonlist label, .umbraco-forms-fieldset .umbraco-forms-container .radiobuttonlist .umbraco-forms-field-wrapper .checkboxlist label, .umbraco-forms-fieldset .umbraco-forms-container .singlechoice .umbraco-forms-field-wrapper .checkbox label, .umbraco-forms-fieldset .umbraco-forms-container .singlechoice .umbraco-forms-field-wrapper .radiobuttonlist label, .umbraco-forms-fieldset .umbraco-forms-container .singlechoice .umbraco-forms-field-wrapper .checkboxlist label, .umbraco-forms-fieldset .umbraco-forms-container .dataconsent .umbraco-forms-field-wrapper .checkbox label, .umbraco-forms-fieldset .umbraco-forms-container .dataconsent .umbraco-forms-field-wrapper .radiobuttonlist label, .umbraco-forms-fieldset .umbraco-forms-container .dataconsent .umbraco-forms-field-wrapper .checkboxlist label {
        letter-spacing: .6px;
        text-transform: none;
        font-size: 12px;
        font-size: 1rem;
        line-height: 20px;
        line-height: 1.66667rem;
        color: #000;
        opacity: .7;
        margin-top: 16px; }
      .umbraco-forms-fieldset .umbraco-forms-container .multichoice .umbraco-forms-field-wrapper .radiobuttonlist, .umbraco-forms-fieldset .umbraco-forms-container .radiobuttonlist .umbraco-forms-field-wrapper .radiobuttonlist, .umbraco-forms-fieldset .umbraco-forms-container .singlechoice .umbraco-forms-field-wrapper .radiobuttonlist, .umbraco-forms-fieldset .umbraco-forms-container .dataconsent .umbraco-forms-field-wrapper .radiobuttonlist {
        display: flex;
        flex-wrap: wrap; }
        .umbraco-forms-fieldset .umbraco-forms-container .multichoice .umbraco-forms-field-wrapper .radiobuttonlist label, .umbraco-forms-fieldset .umbraco-forms-container .radiobuttonlist .umbraco-forms-field-wrapper .radiobuttonlist label, .umbraco-forms-fieldset .umbraco-forms-container .singlechoice .umbraco-forms-field-wrapper .radiobuttonlist label, .umbraco-forms-fieldset .umbraco-forms-container .dataconsent .umbraco-forms-field-wrapper .radiobuttonlist label {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          width: 100%; }
          .umbraco-forms-fieldset .umbraco-forms-container .multichoice .umbraco-forms-field-wrapper .radiobuttonlist label input, .umbraco-forms-fieldset .umbraco-forms-container .radiobuttonlist .umbraco-forms-field-wrapper .radiobuttonlist label input, .umbraco-forms-fieldset .umbraco-forms-container .singlechoice .umbraco-forms-field-wrapper .radiobuttonlist label input, .umbraco-forms-fieldset .umbraco-forms-container .dataconsent .umbraco-forms-field-wrapper .radiobuttonlist label input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0; }
            .umbraco-forms-fieldset .umbraco-forms-container .multichoice .umbraco-forms-field-wrapper .radiobuttonlist label input:checked ~ .checkmark:after, .umbraco-forms-fieldset .umbraco-forms-container .radiobuttonlist .umbraco-forms-field-wrapper .radiobuttonlist label input:checked ~ .checkmark:after, .umbraco-forms-fieldset .umbraco-forms-container .singlechoice .umbraco-forms-field-wrapper .radiobuttonlist label input:checked ~ .checkmark:after, .umbraco-forms-fieldset .umbraco-forms-container .dataconsent .umbraco-forms-field-wrapper .radiobuttonlist label input:checked ~ .checkmark:after {
              display: block; }
          .umbraco-forms-fieldset .umbraco-forms-container .multichoice .umbraco-forms-field-wrapper .radiobuttonlist label .checkmark, .umbraco-forms-fieldset .umbraco-forms-container .radiobuttonlist .umbraco-forms-field-wrapper .radiobuttonlist label .checkmark, .umbraco-forms-fieldset .umbraco-forms-container .singlechoice .umbraco-forms-field-wrapper .radiobuttonlist label .checkmark, .umbraco-forms-fieldset .umbraco-forms-container .dataconsent .umbraco-forms-field-wrapper .radiobuttonlist label .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 24px;
            width: 24px;
            background-color: transparent;
            border-radius: 50%;
            border: 1px solid black; }
            .umbraco-forms-fieldset .umbraco-forms-container .multichoice .umbraco-forms-field-wrapper .radiobuttonlist label .checkmark:after, .umbraco-forms-fieldset .umbraco-forms-container .radiobuttonlist .umbraco-forms-field-wrapper .radiobuttonlist label .checkmark:after, .umbraco-forms-fieldset .umbraco-forms-container .singlechoice .umbraco-forms-field-wrapper .radiobuttonlist label .checkmark:after, .umbraco-forms-fieldset .umbraco-forms-container .dataconsent .umbraco-forms-field-wrapper .radiobuttonlist label .checkmark:after {
              content: "";
              position: absolute;
              display: none;
              left: 3px;
              top: 3px;
              width: 16px;
              height: 16px;
              border-radius: 50%;
              background: black; }
    .umbraco-forms-fieldset .umbraco-forms-container .longanswer textarea {
      width: 100%;
      border-radius: 24px;
      padding: 16px 24px;
      border: none;
      font-size: 16px;
      font-size: 1.33333rem;
      line-height: 24px;
      line-height: 2rem;
      color: #000;
      margin-top: 10px;
      min-height: 184px;
      background-color: #eaeaea;
      resize: vertical; }
      .umbraco-forms-fieldset .umbraco-forms-container .longanswer textarea:focus {
        border-color: #000;
        outline: none; }
    @media (min-width: 768px) {
      .umbraco-forms-fieldset .umbraco-forms-container .longanswer {
        width: 400px; } }
    .umbraco-forms-fieldset .umbraco-forms-container .consentforstoringsubmitteddata > label {
      display: none; }
    .umbraco-forms-fieldset .umbraco-forms-container .consentforstoringsubmitteddata .umbraco-forms-field-wrapper .checkbox label {
      font-size: 10px;
      font-size: 0.83333rem;
      line-height: 18px;
      line-height: 1.5rem; }
    .umbraco-forms-fieldset .umbraco-forms-container .consentforstoringsubmitteddata .umbraco-forms-field-wrapper .checkbox .checkmark:after {
      top: 4px; }

.umbraco-forms-navigation {
  display: flex;
  justify-content: center;
  margin-top: 10px; }
  .umbraco-forms-navigation .button:before {
    background: #fff;
    border-color: #000 #000 #000 transparent; }
  .umbraco-forms-navigation .button input[type=submit] {
    background: none;
    border: none;
    outline: 0;
    min-height: 47px;
    width: 100%;
    left: 0;
    position: absolute;
    color: transparent;
    z-index: 9999;
    padding: 0; }
    .umbraco-forms-navigation .button input[type=submit]:focus, .umbraco-forms-navigation .button input[type=submit]:active {
      outline: 0; }

.form-pod .umbraco-forms-navigation .button {
  background-color: transparent; }
  .form-pod .umbraco-forms-navigation .button:before {
    background: transparent; }

.download-form {
  display: none;
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 10;
  align-items: flex-start;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  overflow-x: auto; }
  .download-form__inner {
    background-color: #fff;
    padding: 40px;
    position: relative;
    margin: 20px;
    max-width: 800px;
    width: 100%; }
    .download-form__inner .form-pod-header {
      text-align: center; }
    .download-form__inner--close {
      cursor: pointer;
      position: absolute;
      border-radius: 50px;
      height: 40px;
      min-width: 40px;
      background-color: #D8D8D8;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 20px;
      top: 20px; }
  .download-form.active {
    display: flex; }

.newsletter-pod__signup form .umbraco-forms-container .umbraco-forms-field .field-validation-error,
.newsletter-pod__signup form .umbraco-forms-container .umbraco-forms-field .parsley-errors-list.filled {
  background-color: #fedede; }

.newsletter-pod__signup form .umbraco-forms-container .umbraco-forms-field input[type=text]::placeholder, .newsletter-pod__signup form .umbraco-forms-container .umbraco-forms-field input[type=text]::-webkit-input-placeholder, .newsletter-pod__signup form .umbraco-forms-container .umbraco-forms-field input[type=text]:-ms-input-placeholder,
.newsletter-pod__signup form .umbraco-forms-container .umbraco-forms-field input[type=email]::placeholder,
.newsletter-pod__signup form .umbraco-forms-container .umbraco-forms-field input[type=email]::-webkit-input-placeholder,
.newsletter-pod__signup form .umbraco-forms-container .umbraco-forms-field input[type=email]:-ms-input-placeholder {
  color: #fff; }

.newsletter-pod__signup form .umbraco-forms-container .umbraco-forms-field.email {
  margin-bottom: 0; }

.newsletter-pod__signup form .umbraco-forms-container .dataconsent label {
  display: none; }

.newsletter-pod__signup form .umbraco-forms-container .dataconsent .umbraco-forms-field-wrapper .checkbox > label {
  font-size: 10px;
  font-size: 0.83333rem;
  line-height: 24px;
  line-height: 2rem;
  color: #fff; }
  .newsletter-pod__signup form .umbraco-forms-container .dataconsent .umbraco-forms-field-wrapper .checkbox > label a {
    color: #fff;
    text-decoration: underline; }
  .newsletter-pod__signup form .umbraco-forms-container .dataconsent .umbraco-forms-field-wrapper .checkbox > label .checkmark {
    border: 1px solid #fff; }
    .newsletter-pod__signup form .umbraco-forms-container .dataconsent .umbraco-forms-field-wrapper .checkbox > label .checkmark:after {
      content: url(../../img/icon-tick-white.svg); }

#success-message {
  display: none; }

/* *****************************

BVSelect Jquery Plugin Styles
Developed by: Bruno Vieira

**************************** */
.bv_mainselect {
  color: #fff;
  padding: 8px 0;
  width: 100%;
  min-width: 300px; }

.bv_atual {
  color: #535353;
  font-size: 11pt;
  padding: 10px;
  border: 2px solid #e1e1e1;
  border-radius: 6px;
  cursor: pointer; }

.bv_disabled {
  background-color: #e6e6e6 !important;
  cursor: default !important;
  opacity: 0.5; }

.bv_separator {
  font-weight: 700;
  color: #282828 !important;
  cursor: default !important;
  background-color: transparent !important; }

.bv_background {
  background-color: #fff; }

.arrow {
  border: solid grey;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  float: right;
  pointer-events: none; }

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  margin-top: 5px;
  transition: 0.2s cubic-bezier(0, 0, 0, 1); }

.down {
  transition: 0.2s cubic-bezier(0, 0, 0, 1);
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-top: 2px; }

.bv_ul_mobile {
  /* width: 80% !important;
    position: fixed !important;
    top: 5% !important;
    left: 10% !important;
    border: 0px !important;
    height: 90% !important;
    margin: 0px !important;
    overflow-y: scroll;
    z-index: 10 !important;
    max-height:100%!important;*/ }

.bv_ul_bg {
  /*  position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.8); z-index: 9;*/ }

.bv_ul_mobile li {
  padding: 20px !important; }

.stop-scrolling {
  height: 100%;
  overflow: hidden; }

.bv_ul_inner {
  position: absolute;
  margin-top: 10px;
  padding: 0;
  display: none;
  border: 2px solid #e1e1e1;
  border-radius: 8px;
  display: none;
  z-index: 1;
  max-height: 400px;
  overflow-x: auto; }

.bv_ul_inner li {
  cursor: pointer;
  list-style: none;
  color: #353535;
  padding: 5px 0 5px 9px;
  font-family: GT-America-Extended-Light;
  font-size: 10pt;
  padding: 12px 28px 10px 10px; }

.bv_ul_inner i {
  color: #464646;
  font-size: 17pt;
  margin-right: 10px;
  float: left;
  margin-top: -1px;
  margin-left: 3px; }

.bv_ul_inner li img {
  float: left;
  margin-right: 11px;
  position: relative;
  bottom: 6px;
  max-width: 30px;
  /* Change to fit your images */ }

.bv_ul_inner li input {
  padding: 0px;
  border: 0px;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: 0px;
  color: black;
  height: 38px;
  width: 100%;
  background-color: transparent;
  font-size: 10pt;
  font-weight: bold; }

.bv_ul_inner li:hover {
  background-color: #eee; }

.nofocus {
  background-color: rgba(255, 255, 255, 0.1) !important;
  padding: 0px !important;
  border-bottom: 2px solid #e1e1e1; }

.innerinput {
  padding: 0px 9px 0px 10px; }

*:focus {
  outline: none !important; }

.container.related-products {
  text-align: center; }
  @media (min-width: 768px) {
    .container.related-products .view-collections-cta {
      margin: 32px auto 0; } }

@media (min-width: 768px) {
  .container .max-840-left {
    max-width: 840px; } }

@media (min-width: 1200px) {
  .container .max-840-left {
    padding: 0; } }

.container.standard-content a {
  text-decoration: underline;
  color: #000;
  font-family: 'GT-America-Expanded-Medium'; }

@media (min-width: 768px) {
  .container.standard-content p {
    max-width: 840px;
    margin-right: auto;
    margin-left: auto; } }

.container.standard-content strong {
  color: #000; }

.container.standard-content ul {
  list-style: none; }
  .container.standard-content ul li {
    font-size: 12px;
    font-size: 1rem;
    line-height: 24px;
    line-height: 2rem;
    letter-spacing: 0.6px;
    color: rgba(0, 0, 0, 0.7);
    margin: .75rem 0; }
    .container.standard-content ul li:before {
      content: "\2022";
      color: rgba(0, 0, 0, 0.7);
      display: inline-block;
      width: 1em;
      margin-left: -1em; }

.container.standard-content ol {
  list-style: none;
  counter-reset: li; }
  .container.standard-content ol li {
    color: rgba(0, 0, 0, 0.7);
    counter-increment: li; }
    .container.standard-content ol li:before {
      content: counter(li);
      color: rgba(0, 0, 0, 0.7);
      display: inline-block;
      width: 1em;
      margin-left: -1.5em;
      margin-right: 0.5em;
      text-align: right;
      direction: rtl; }

.container .curver-border-bottom-right {
  border-radius: 0px 0px 6rem 0px; }
  @media (min-width: 980px) {
    .container .curver-border-bottom-right {
      border-radius: 0px 0px 10rem 0px; } }

.image-gallery-carousel {
  position: relative;
  width: 100%; }
  .image-gallery-carousel.product-page {
    width: calc(100% + 60px);
    margin: 0 -30px; }
    .image-gallery-carousel.product-page #image-gallery .fill {
      height: 324px; }
    @media (min-width: 768px) {
      .image-gallery-carousel.product-page {
        border-bottom-left-radius: 160px;
        overflow: hidden;
        width: 100%;
        margin: 0; }
        .image-gallery-carousel.product-page #image-gallery .fill {
          height: 440px; } }
  .image-gallery-carousel h2 {
    margin-bottom: 3rem;
    font-size: 24px;
    font-size: 2rem;
    line-height: 32px;
    line-height: 2.66667rem; }
    .image-gallery-carousel h2:before {
      height: 43px;
      margin-right: 31px; }
    @media (min-width: 768px) {
      .image-gallery-carousel h2 {
        font-size: 32px;
        font-size: 2.66667rem;
        line-height: 40px;
        line-height: 3.33333rem; } }
  .image-gallery-carousel #image-gallery .fill {
    overflow: hidden;
    background-size: cover;
    background-position: center;
    height: 323px;
    position: relative; }
    @media (min-width: 768px) {
      .image-gallery-carousel #image-gallery .fill {
        height: 650px;
        position: relative; } }
  .image-gallery-carousel #image-gallery .slick-slide {
    position: relative; }
  .image-gallery-carousel #image-gallery .slider-caption {
    position: relative;
    bottom: -1.5rem;
    width: 100%;
    padding: 0 24px;
    text-align: center; }
    @media (min-width: 768px) {
      .image-gallery-carousel #image-gallery .slider-caption {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        bottom: 1rem; }
        .image-gallery-carousel #image-gallery .slider-caption p {
          color: #fff; } }
  .image-gallery-carousel #image-gallery .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    display: none; }
    .image-gallery-carousel #image-gallery .slick-arrow > svg {
      display: none; }
    .image-gallery-carousel #image-gallery .slick-arrow.slick-prev {
      left: 150px;
      z-index: 99; }
    .image-gallery-carousel #image-gallery .slick-arrow.slick-next {
      right: 86px; }
    .image-gallery-carousel #image-gallery .slick-arrow:hover {
      cursor: pointer;
      background: #000;
      transition: 0.2s cubic-bezier(0, 0, 0, 1); }
      .image-gallery-carousel #image-gallery .slick-arrow:hover > svg {
        fill: #fff; }
    @media (min-width: 768px) {
      .image-gallery-carousel #image-gallery .slick-arrow {
        background: #fff;
        border-radius: 50%;
        padding: 24px 24px;
        display: block;
        width: 64px;
        height: 64px; }
        .image-gallery-carousel #image-gallery .slick-arrow > svg {
          display: block; } }
  .image-gallery-carousel .slider-info {
    letter-spacing: .6px;
    text-align: center;
    color: #000;
    display: flex;
    flex-direction: column;
    margin: 3rem 2rem;
    margin-top: 0;
    padding: 0 6rem; }
    @media (min-width: 768px) {
      .image-gallery-carousel .slider-info {
        color: #fff;
        position: absolute;
        bottom: 4rem;
        z-index: 9;
        transform: translate(-50%, -50%);
        left: 50%;
        flex-direction: column-reverse;
        margin: 0;
        padding: 0; } }
    .image-gallery-carousel .slider-info .custom_paging {
      margin-top: .75rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'GT-America-Expanded-Medium'; }
      @media (min-width: 768px) {
        .image-gallery-carousel .slider-info .custom_paging {
          margin-bottom: .75rem; } }
      .image-gallery-carousel .slider-info .custom_paging .slider-pag-first {
        margin-right: 1.5rem; }
      .image-gallery-carousel .slider-info .custom_paging .slider-pag-second {
        margin-left: 1.25rem;
        color: #767676; }
        @media (min-width: 768px) {
          .image-gallery-carousel .slider-info .custom_paging .slider-pag-second {
            color: #fff;
            opacity: 0.5; } }
      .image-gallery-carousel .slider-info .custom_paging .pad-divider {
        display: inline-flex;
        margin: 0 2rem; }
        .image-gallery-carousel .slider-info .custom_paging .pad-divider:before {
          content: '';
          width: 1px;
          height: 35px;
          background-color: #000;
          opacity: 0.5;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }
          @media (min-width: 768px) {
            .image-gallery-carousel .slider-info .custom_paging .pad-divider:before {
              background-color: #fff; } }

.circular-block-wrapper {
  margin: 0; }
  .circular-block-wrapper .circular-block-item {
    margin-top: 3rem;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center; }
    @media (min-width: 768px) {
      .circular-block-wrapper .circular-block-item {
        padding: 0 24px;
        align-items: flex-start; } }
    .circular-block-wrapper .circular-block-item .content {
      padding: 24px 0 0 0;
      width: calc( 100% - 100px);
      width: 100%; }
      @media (min-width: 980px) {
        .circular-block-wrapper .circular-block-item .content {
          width: calc( 100% - 144px);
          padding: 0 0 0 24px !important; } }
    .circular-block-wrapper .circular-block-item .cta {
      width: 100%;
      display: flex;
      margin-top: 32px; }
      @media (min-width: 980px) {
        .circular-block-wrapper .circular-block-item .cta {
          padding-left: 168px;
          margin-top: 0; } }
    .circular-block-wrapper .circular-block-item .img {
      height: 100px;
      border-radius: 50%;
      width: 100px;
      border: 1px solid #DBDBDB; }
      @media (min-width: 980px) {
        .circular-block-wrapper .circular-block-item .img {
          height: 144px;
          max-width: 144px;
          min-width: 144px; } }

.image-block {
  overflow: hidden; }
  .image-block img {
    max-width: 100%;
    height: auto; }

.listing-item.image-text-block {
  padding: 0; }

.listing.alternate.light .listing-item.image-text-block:after {
  content: normal; }

.image-caption-container {
  display: flex;
  justify-content: center; }
  .image-caption-container .media-wrapper {
    padding: 0px;
    text-align: center;
    margin: 30px 0;
    max-width: 100%;
    flex-grow: 1; }
    .image-caption-container .media-wrapper .lightbox-youtube {
      position: relative;
      display: block; }
      .image-caption-container .media-wrapper .lightbox-youtube-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center; }
        .image-caption-container .media-wrapper .lightbox-youtube-overlay img {
          width: 25%;
          max-width: 136px;
          border-radius: 0 0 4rem 0; }
    .image-caption-container .media-wrapper .image-caption-img {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 137px; }
      @media (min-width: 768px) {
        .image-caption-container .media-wrapper .image-caption-img {
          height: 268px; } }
      @media (min-width: 980px) {
        .image-caption-container .media-wrapper .image-caption-img {
          height: 440px; } }
    .image-caption-container .media-wrapper .image-caption {
      color: #767676;
      padding-top: 1.5rem;
      justify-content: center; }
      .image-caption-container .media-wrapper .image-caption:before {
        opacity: .5; }
    .image-caption-container .media-wrapper:hover .image-caption-zoom {
      opacity: 1;
      transition: opacity 0.2s ease-out; }

.caption-wrapper {
  display: flex;
  align-items: center;
  width: auto; }
  .caption-wrapper.video-caption {
    position: initial;
    padding: 0; }
  .caption-wrapper .caption {
    margin-bottom: 0;
    font-style: italic;
    font-size: 12px;
    font-size: 1rem;
    line-height: 20px;
    line-height: 1.66667rem; }
  .caption-wrapper .caption-line {
    display: none; }

.collection-carousel {
  display: flex; }
  .collection-carousel h2 {
    margin-bottom: 32px; }
  .collection-carousel .container {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .collection-carousel .container .carousel-toggle {
      display: flex;
      justify-content: center; }
      .collection-carousel .container .carousel-toggle__toggle {
        cursor: pointer;
        border: 0; }
        .collection-carousel .container .carousel-toggle__toggle .btn-text {
          position: relative;
          z-index: 3; }
        .collection-carousel .container .carousel-toggle__toggle:first-of-type {
          border-bottom-left-radius: 30px;
          border-top-left-radius: 30px;
          position: relative;
          padding: 17px 20px 16px 40px; }
          @media (min-width: 768px) {
            .collection-carousel .container .carousel-toggle__toggle:first-of-type {
              padding: 17px 30px 16px 50px; } }
          .collection-carousel .container .carousel-toggle__toggle:first-of-type:before {
            content: '';
            position: absolute;
            height: 0;
            border-style: solid;
            border-width: 48px 45px 0 0;
            border-color: #000 transparent transparent transparent;
            top: 0;
            right: -44px;
            z-index: 1; }
        .collection-carousel .container .carousel-toggle__toggle:last-child {
          border-bottom-right-radius: 30px;
          border-top-right-radius: 30px;
          padding: 17px 40px 16px 50px; }
          @media (min-width: 768px) {
            .collection-carousel .container .carousel-toggle__toggle:last-child {
              padding: 17px 50px 16px 60px; } }
          .collection-carousel .container .carousel-toggle__toggle:last-child div.hover {
            bottom: 0;
            left: 0;
            width: 100%;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0; }
            .collection-carousel .container .carousel-toggle__toggle:last-child div.hover:before {
              left: 23px; }
        .collection-carousel .container .carousel-toggle__toggle div.hover {
          position: absolute;
          left: -1px;
          top: 0;
          bottom: -1px;
          width: calc(100% + 51px);
          z-index: 1;
          border-top-left-radius: 30px;
          border-bottom-left-radius: 30px;
          overflow: hidden;
          transition: 0.2s cubic-bezier(0, 0, 0, 1); }
          .collection-carousel .container .carousel-toggle__toggle div.hover:before {
            content: '';
            background: #E0E0E0;
            height: 100%;
            width: 0;
            position: absolute;
            left: -25px;
            top: 0;
            bottom: 0;
            right: 0;
            transition: 0.2s cubic-bezier(0, 0, 0, 1);
            transform: skewX(-40deg);
            z-index: 1; }
        .collection-carousel .container .carousel-toggle__toggle:hover div.hover:before {
          width: 100%; }
      .collection-carousel .container .carousel-toggle [role="tab"][aria-selected="true"] {
        cursor: auto; }
        .collection-carousel .container .carousel-toggle [role="tab"][aria-selected="true"] div.hover:before {
          background: transparent; }
  .collection-carousel .hide-carousel {
    position: absolute;
    left: -9999px;
    width: 100%; }
  .collection-carousel__container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    .collection-carousel__container .button {
      align-self: center;
      margin-left: -47px; }
    .collection-carousel__container--slider {
      display: flex;
      align-items: center;
      margin: 30px 0;
      justify-content: center; }
      .collection-carousel__container--slider .slick-arrow {
        border-radius: 50px;
        height: 50px;
        min-width: 50px;
        border: none;
        background-color: #D8D8D8;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.2s cubic-bezier(0, 0, 0, 1); }
        .collection-carousel__container--slider .slick-arrow:hover {
          background-color: #000;
          color: white; }
          .collection-carousel__container--slider .slick-arrow:hover .icon {
            fill: #fff; }
      .collection-carousel__container--slider .slide .collection-container {
        display: flex;
        justify-content: center; }
        .collection-carousel__container--slider .slide .collection-container .collection-item {
          margin: 30px 10px;
          border: 1px solid #e8e8e8;
          max-width: 360px;
          width: 100%; }
          .collection-carousel__container--slider .slide .collection-container .collection-item a .img {
            width: 100%;
            height: 280px;
            overflow: hidden; }
            @media (min-width: 768px) {
              .collection-carousel__container--slider .slide .collection-container .collection-item a .img {
                height: 319px; } }
            .collection-carousel__container--slider .slide .collection-container .collection-item a .img .slide-image {
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              width: 100%;
              height: 100%;
              transition: 0.2s cubic-bezier(0, 0, 0, 1); }
            .collection-carousel__container--slider .slide .collection-container .collection-item a .img:hover .slide-image {
              transform: scale(1.1); }
          .collection-carousel__container--slider .slide .collection-container .collection-item .content {
            display: flex !important;
            align-items: center;
            flex-direction: column;
            padding: 25px 0; }
            .collection-carousel__container--slider .slide .collection-container .collection-item .content h3 {
              font-size: 18px;
              margin-top: 1rem;
              margin-bottom: 0; }
            .collection-carousel__container--slider .slide .collection-container .collection-item .content p {
              font-size: 12px; }

/*.container-pod {
   
    .curver-border-bottom-left {
        border-radius: 0px 0px 0px 6rem;

        @media (min-width: $small-desktop) {
            border-radius: 0px 0px 0px 10rem;
        }
    }

    h2 {
        color: $white;

        @media (min-width: $max-desktop) {
            @include font-size(40,48);
        }
    }

    span, p {
        color: $content-txt-white;
    }

    .pod-cta {
        width: 194px;
        margin: 2.5rem 0;
    }
   
    

}*/
.pod-promo {
  background-color: #1B1B1B;
  position: relative;
  display: flex;
  flex-direction: column; }
  .pod-promo .__text {
    position: relative;
    z-index: 2;
    order: 2;
    padding: 40px 64px; }
    .pod-promo .__text h2 {
      color: #fff;
      margin-bottom: 30px; }
  .pod-promo .promo-content-img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 375px;
    width: 285px;
    height: 158px;
    border-bottom-left-radius: 80px;
    order: 1;
    align-self: flex-end; }
    @media (min-width: 980px) {
      .pod-promo .promo-content-img {
        height: 300px;
        width: 44%; } }
  @media (min-width: 768px) {
    .pod-promo {
      flex-direction: row;
      align-items: center;
      height: 100%;
      justify-content: space-between; }
      .pod-promo .__text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        order: 1;
        max-width: 450px;
        margin-left: 64px; }
      .pod-promo .promo-content-img {
        border-bottom-left-radius: 160px;
        order: 2;
        align-self: flex-start;
        height: 375px;
        width: 45%;
        margin-bottom: 64px; } }

.newsletter-pod {
  background-color: #000;
  padding: 160px 0 40px;
  color: #fff;
  position: relative; }
  .newsletter-pod .umbraco-forms-navigation {
    width: 100%;
    margin: 0 auto;
    justify-content: flex-start; }
  .newsletter-pod .background {
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    width: 100%;
    height: 140px;
    position: relative;
    z-index: 0; }
  .newsletter-pod .content {
    display: flex;
    flex-direction: column;
    background: #252525;
    padding: 48px 32px;
    z-index: 1;
    position: relative; }
  .newsletter-pod__text {
    margin-bottom: 30px; }
    .newsletter-pod__text h2 {
      font-size: 32px;
      font-size: 2.66667rem;
      line-height: 32px;
      line-height: 2.66667rem;
      color: #fff; }
    .newsletter-pod__text p {
      color: #fff;
      opacity: .7; }
  .newsletter-pod__signup {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    @media (min-width: 768px) {
      .newsletter-pod__signup {
        align-items: center; } }
    .newsletter-pod__signup .newslettersignup {
      width: 100%; }
    .newsletter-pod__signup .umbraco-forms-page {
      display: flex;
      flex-direction: column;
      align-items: flex-start; }
      @media (min-width: 420px) {
        .newsletter-pod__signup .umbraco-forms-page {
          align-items: center; } }
      .newsletter-pod__signup .umbraco-forms-page .button div.hover:before {
        background: #EEEEEE; }
      .newsletter-pod__signup .umbraco-forms-page .button:hover .btn-text {
        color: black; }
      .newsletter-pod__signup .umbraco-forms-page .umbraco-forms-fieldset {
        border: 0;
        width: 100%; }
        .newsletter-pod__signup .umbraco-forms-page .umbraco-forms-fieldset .umbraco-forms-container {
          justify-content: flex-start;
          margin: 0;
          align-items: flex-start; }
          @media (min-width: 420px) {
            .newsletter-pod__signup .umbraco-forms-page .umbraco-forms-fieldset .umbraco-forms-container {
              justify-content: center;
              margin: 0 auto; } }
        .newsletter-pod__signup .umbraco-forms-page .umbraco-forms-fieldset .umbraco-forms-field-wrapper {
          position: relative; }
          .newsletter-pod__signup .umbraco-forms-page .umbraco-forms-fieldset .umbraco-forms-field-wrapper .umbraco-forms-label {
            transition: 0.2s cubic-bezier(0, 0, 0, 1);
            position: absolute;
            left: 0; }
          .newsletter-pod__signup .umbraco-forms-page .umbraco-forms-fieldset .umbraco-forms-field-wrapper input[type=text] {
            width: 100%;
            background-color: transparent;
            border: none;
            border-bottom: 1px solid rgba(255, 255, 255, 0.7);
            margin-bottom: 0;
            padding-bottom: 5px;
            color: white; }
            .newsletter-pod__signup .umbraco-forms-page .umbraco-forms-fieldset .umbraco-forms-field-wrapper input[type=text]:focus {
              border-bottom: 1px solid #fff; }
          .newsletter-pod__signup .umbraco-forms-page .umbraco-forms-fieldset .umbraco-forms-field-wrapper input:focus + .umbraco-forms-label {
            top: -18px; }
          .newsletter-pod__signup .umbraco-forms-page .umbraco-forms-fieldset .umbraco-forms-field-wrapper--check {
            display: flex;
            width: 100%;
            font-size: 10px;
            font-size: 0.83333rem;
            line-height: 18px;
            line-height: 1.5rem;
            margin-bottom: 20px; }
            .newsletter-pod__signup .umbraco-forms-page .umbraco-forms-fieldset .umbraco-forms-field-wrapper--check label {
              margin-left: 10px; }
            .newsletter-pod__signup .umbraco-forms-page .umbraco-forms-fieldset .umbraco-forms-field-wrapper--check a {
              color: #fff; }
            .newsletter-pod__signup .umbraco-forms-page .umbraco-forms-fieldset .umbraco-forms-field-wrapper--check input[type=checkbox] {
              position: relative;
              cursor: pointer; }
            .newsletter-pod__signup .umbraco-forms-page .umbraco-forms-fieldset .umbraco-forms-field-wrapper--check input[type=checkbox]:before {
              content: "";
              display: block;
              position: absolute;
              width: 16px;
              height: 16px;
              top: 0;
              left: 0;
              border: 1px solid #D8D8D8;
              background-color: #000; }
            .newsletter-pod__signup .umbraco-forms-page .umbraco-forms-fieldset .umbraco-forms-field-wrapper--check input[type=checkbox]:checked:after {
              content: "";
              display: block;
              width: 5px;
              height: 10px;
              border: solid #fff;
              border-width: 0 2px 2px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
              position: absolute;
              top: 2px;
              left: 6px; }
  @media (min-width: 768px) {
    .newsletter-pod {
      background-size: cover;
      background-repeat: no-repeat;
      padding: 120px 0; }
      .newsletter-pod .umbraco-forms-navigation {
        width: 400px;
        margin: 0 auto; }
      .newsletter-pod .background {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%; }
        .newsletter-pod .background:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(90deg, transparent 25%, rgba(0, 0, 0, 0.8) 45%);
          z-index: 1; }
        .newsletter-pod .background:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.3);
          z-index: 1; }
      .newsletter-pod .content {
        background: none;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        padding: 64px; }
      .newsletter-pod__text {
        width: 100%;
        max-width: 320px;
        margin-bottom: 0;
        padding-right: 32px; }
        .newsletter-pod__text h2 {
          font-size: 48px;
          font-size: 4rem;
          line-height: 48px;
          line-height: 4rem; }
      .newsletter-pod__signup {
        width: 100%;
        max-width: 600px; }
        .newsletter-pod__signup input[type=text] {
          margin-bottom: 40px;
          padding-bottom: 15px; }
          .newsletter-pod__signup input[type=text]::placeholder {
            color: #fff; }
        .newsletter-pod__signup--check {
          margin-bottom: 40px; } }

@media screen and (max-height: 575px) {
  #rc-imageselect, .g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0; } }

.general-content {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #fff;
  padding: 64px 32px;
  background-attachment: fixed; }
  .general-content:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 0; }
  .general-content__title {
    z-index: 1;
    position: relative; }
    .general-content__title h3 {
      color: #fff;
      padding-right: 25%;
      margin-bottom: 32px; }
  .general-content__text {
    display: flex;
    flex-direction: column;
    z-index: 1;
    position: relative; }
    .general-content__text p {
      color: #fff;
      margin-bottom: 32px; }
  .general-content .button {
    align-self: flex-start; }
  @media (min-width: 768px) {
    .general-content {
      display: flex;
      padding: 160px 32px 100px;
      justify-content: space-around; }
      .general-content .container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between; }
      .general-content__title {
        width: 100%;
        max-width: 380px;
        margin-top: -48px; }
        .general-content__title h3 {
          padding-right: 24px; }
      .general-content__text {
        width: 100%;
        max-width: 600px; } }

.inspiration-pod {
  position: relative;
  overflow: hidden; }
  .inspiration-pod:before {
    position: absolute;
    content: '';
    width: 200%;
    height: 100%;
    z-index: 1;
    display: block;
    top: 0;
    left: auto;
    right: -10%;
    background: #F5F5F5;
    transform: skewX(-45deg); }
  .inspiration-pod .background-image {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }
  .inspiration-pod__text {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 2;
    padding: 72px 64px;
    color: #000; }
    .inspiration-pod__text .sub-heading {
      color: #767676; }
      .inspiration-pod__text .sub-heading.white {
        color: #fff; }
    .inspiration-pod__text h2 {
      color: #000;
      margin-bottom: 32px; }
    .inspiration-pod__text .button {
      align-self: flex-start; }
  @media (min-width: 768px) {
    .inspiration-pod {
      height: 430px; }
      .inspiration-pod:before {
        right: 20%; }
      .inspiration-pod .container {
        display: flex;
        align-items: center;
        height: 100%; }
      .inspiration-pod .background-image {
        width: 60%; }
      .inspiration-pod__text {
        box-sizing: content-box;
        max-width: 450px;
        padding: 80px 164px; } }
  @media (min-width: 980px) {
    .inspiration-pod:before {
      right: 40%; } }
  .inspiration-pod.dark {
    position: relative; }
    .inspiration-pod.dark:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      z-index: 1;
      display: block;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      transform: none; }
    .inspiration-pod.dark .background-image {
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover; }
    .inspiration-pod.dark .inspiration-pod__text {
      color: #fff; }
      .inspiration-pod.dark .inspiration-pod__text h2 {
        color: #fff; }

.contact-sales {
  background-color: #1B1B1B;
  position: relative;
  padding: 160px 0 60px; }
  .contact-sales:after {
    background-color: #222222;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 70%;
    height: 60%;
    content: '';
    clip-path: polygon(100% 100%, 0 100%, 100% 0);
    z-index: 1; }
  .contact-sales__img {
    width: 60%;
    height: 175px;
    border-bottom-right-radius: 80px;
    background-size: cover;
    background-repeat: no-repeat; }
  .contact-sales__text {
    z-index: 2;
    position: relative;
    text-align: center;
    padding: 40px 64px;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .contact-sales__text h2 {
      color: #fff; }
    .contact-sales__text p {
      color: #fff;
      margin-bottom: 30px;
      opacity: .7; }
    .contact-sales__text .button {
      align-self: center;
      margin-left: -48px; }
  @media (min-width: 768px) {
    .contact-sales {
      padding: 64px !important;
      display: flex;
      justify-content: space-around; }
      .contact-sales:after {
        clip-path: polygon(54% 0, 100% 0, 100% 100%, 0 100%);
        height: 100%;
        width: 85%; }
      .contact-sales__img {
        position: relative;
        max-width: 400px;
        height: 266px;
        border-bottom-right-radius: 160px;
        z-index: 2; }
      .contact-sales__text {
        max-width: 470px;
        justify-content: center;
        padding: 0 40px; } }

.download-pod {
  background-color: #e3e3e3;
  border-bottom-right-radius: 80px;
  margin: 0 32px; }
  .download-pod__img {
    width: 100%;
    height: 246px;
    background-size: cover;
    background-repeat: no-repeat; }
  .download-pod__text {
    z-index: 2;
    position: relative;
    text-align: center;
    padding: 40px 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .download-pod__text h3 {
      color: #000;
      margin-top: 24px; }
    .download-pod__text p {
      color: #000;
      margin-bottom: 30px;
      opacity: .7; }
    .download-pod__text .button {
      align-self: center;
      margin-left: -48px; }
  @media (min-width: 768px) {
    .download-pod {
      display: flex;
      justify-content: space-around;
      margin: 0 auto;
      border-bottom-right-radius: 160px; }
      .download-pod__img {
        flex: 1;
        height: initial;
        max-width: 456px; }
      .download-pod__text {
        flex: 1; }
        .download-pod__text p {
          max-width: 80%; } }

.listing-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 0; }
  .listing-header .sub-heading {
    display: none;
    margin-bottom: 0; }
    @media (min-width: 768px) {
      .listing-header .sub-heading {
        display: flex; } }
  .listing-header .filter-btn {
    font-size: 10px;
    font-size: 0.83333rem;
    line-height: 16px;
    line-height: 1.33333rem;
    text-transform: uppercase;
    letter-spacing: 1.6px;
    color: black;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    cursor: pointer; }
    .listing-header .filter-btn svg {
      margin-left: 30px; }
    @media (min-width: 768px) {
      .listing-header .filter-btn {
        border: 1px solid black;
        padding: 16px 32px; }
        .listing-header .filter-btn:hover {
          color: white;
          background: black; } }

.listing.product-list {
  margin: 0; }
  .listing.product-list .product {
    margin: 12px 10px;
    max-width: 100%; }
    @media (min-width: 580px) {
      .listing.product-list .product {
        max-width: calc(50% - 20px); } }
    .listing.product-list .product a {
      width: 100%;
      margin-bottom: 0; }
    .listing.product-list .product .img {
      border: 1px solid #e8e8e8;
      width: 100%;
      height: 320px; }
      .listing.product-list .product .img .img-holder {
        background-size: contain;
        background-color: #fff; }
    .listing.product-list .product .content {
      padding: 24px 8px 48px;
      text-align: center; }
      .listing.product-list .product .content .prod-name {
        font-size: 14px;
        font-size: 1.16667rem;
        line-height: 24px;
        line-height: 2rem;
        letter-spacing: .6px;
        color: black; }
      .listing.product-list .product .content .category {
        color: black;
        opacity: .7;
        letter-spacing: .6px; }
      .listing.product-list .product .content .price {
        font-size: 18px;
        font-size: 1.5rem;
        line-height: 24px;
        line-height: 2rem;
        letter-spacing: .8px;
        margin-top: 16px; }
        .listing.product-list .product .content .price span {
          font-size: 12px;
          font-size: 1rem;
          line-height: 20px;
          line-height: 1.66667rem;
          letter-spacing: .6px; }
  @media (min-width: 768px) {
    .listing.product-list .product {
      border: 1px solid #e8e8e8;
      max-width: calc(33.333333% - 20px) !important;
      margin-left: 10px;
      margin-right: 10px; }
      .listing.product-list .product:last-child {
        margin-left: 10px; }
      .listing.product-list .product:nth-child(3n) {
        margin-right: 0px; }
      .listing.product-list .product .img {
        border: 0; } }
  @media (min-width: 980px) {
    .listing.product-list .product .img {
      height: 300px; }
    .listing.product-list .product .content .prod-name {
      font-size: 18px;
      font-size: 1.5rem;
      line-height: 24px;
      line-height: 2rem; } }

.no-results h2 {
  margin-bottom: 16px; }

.no-results .button {
  display: inline-flex; }

table {
  text-align: center;
  width: 100%;
  display: block;
  overflow-x: auto;
  padding-bottom: 2.5rem; }
  table::-webkit-scrollbar {
    width: 3px;
    height: 3px; }
  table::-webkit-scrollbar-track {
    background: #fff; }
  table::-webkit-scrollbar-thumb {
    background: #000; }
  table::-webkit-scrollbar-thumb:hover {
    background: #000; }
  @media (min-width: 768px) {
    table {
      overflow-x: hidden;
      padding-bottom: 0; } }
  table th, table td {
    border: 1px solid #DBDBDB;
    padding: 1.75rem 0;
    font-size: 12px;
    font-size: 1rem;
    line-height: 24px;
    line-height: 2rem;
    letter-spacing: 0.6px;
    color: rgba(0, 0, 0, 0.7);
    min-width: 350px; }
    @media (min-width: 768px) {
      table th, table td {
        min-width: auto; } }
  table th {
    color: #000; }

.filter {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  overflow: hidden;
  width: 100%; }
  .filter.show {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms; }
  .filter.hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms; }
  .filter:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
    opacity: 0;
    transition: opacity 0.2s cubic-bezier(0, 0, 0, 1); }
  .filter .filter-wrapper {
    width: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    z-index: 100;
    background: white;
    transition: width 0.2s cubic-bezier(0, 0, 0, 1); }
    .filter .filter-wrapper::-webkit-scrollbar {
      width: 3px;
      height: 3px; }
    .filter .filter-wrapper::-webkit-scrollbar-track {
      background: #fff; }
    .filter .filter-wrapper::-webkit-scrollbar-thumb {
      background: #000; }
    .filter .filter-wrapper::-webkit-scrollbar-thumb:hover {
      background: #000; }
    .filter .filter-wrapper .filters {
      width: 100%;
      padding: 128px 32px 32px; }
    .filter .filter-wrapper .close {
      background: #f4f4f4;
      padding: 16px 40px 13px 24px;
      color: black;
      position: absolute;
      top: 32px;
      right: 0;
      border-radius: 24px 0 0 24px;
      cursor: pointer; }
      .filter .filter-wrapper .close:hover {
        background: black;
        color: white; }
        .filter .filter-wrapper .close:hover svg {
          fill: #fff; }
    @media (min-width: 768px) {
      .filter .filter-wrapper .filters {
        padding: 128px 64px 64px;
        display: block; } }
  .filter.show:before {
    opacity: 1; }
  .filter.show .filter-wrapper {
    width: 100%; }
    @media (min-width: 768px) {
      .filter.show .filter-wrapper {
        width: 415px; } }
  .filter form {
    width: 100%; }
  .filter .buttons {
    display: flex;
    justify-content: flex-end; }
  .filter .faqSection.container .faqs .accordion-multi {
    padding: 0 0 16px;
    border: 0;
    border-bottom: 1px solid #DBDBDB; }
    .filter .faqSection.container .faqs .accordion-multi.active {
      background-color: transparent; }
    .filter .faqSection.container .faqs .accordion-multi:hover {
      background-color: transparent; }
  .filter .umbraco-forms-fieldset .umbraco-forms-container {
    padding: 0; }
  .filter .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field {
    width: 100%;
    border: 0; }
    .filter .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field .filter-group {
      font-size: 16px;
      font-size: 1.33333rem;
      line-height: 24px;
      line-height: 2rem; }
    .filter .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field .checkboxlist {
      display: flex;
      flex-direction: column;
      margin: 0; }
      .filter .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field .checkboxlist label {
        font-size: 10px;
        font-size: 0.83333rem;
        line-height: 24px;
        line-height: 2rem;
        display: flex;
        align-items: center;
        opacity: .7;
        padding: 12px 0;
        border-bottom: 1px solid #DBDBDB;
        letter-spacing: .6px; }
      .filter .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field .checkboxlist .checkmark {
        top: 50%;
        right: 0;
        left: auto;
        transform: translateY(-50%); }

section.gallery {
  position: relative; }
  section.gallery:before {
    content: '';
    position: absolute;
    top: -130px;
    left: 0;
    background: url(../../img/bg-arch.svg) no-repeat top left;
    background-size: 100% auto;
    width: 329px;
    height: 621px;
    z-index: 0; }

.filter-gallery {
  position: relative;
  margin-bottom: 24px;
  z-index: 2; }
  @media (min-width: 768px) {
    .filter-gallery {
      margin-bottom: 40px; } }
  .filter-gallery form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column; }
    .filter-gallery form fieldset {
      border: 0;
      padding: 0; }
      .filter-gallery form fieldset label {
        letter-spacing: 0.6px;
        opacity: 0.7; }
      .filter-gallery form fieldset .bv_mainselect {
        padding: 8px 0px 24px 0;
        min-width: 300px; }
        @media (min-width: 980px) {
          .filter-gallery form fieldset .bv_mainselect {
            min-width: 360px; } }
    @media (min-width: 768px) {
      .filter-gallery form {
        align-items: center;
        flex-direction: row; }
        .filter-gallery form fieldset .bv_mainselect {
          padding-right: 24px; } }

.gallary-page .sub-heading {
  margin-bottom: 24px; }

.listing.gallery {
  justify-content: space-between;
  z-index: 1; }
  .listing.gallery .listing-item {
    z-index: 1;
    margin-bottom: 48px; }
    @media (min-width: 768px) {
      .listing.gallery .listing-item {
        width: calc(50% - 12px); } }
    .listing.gallery .listing-item .img {
      height: 310px; }
      .listing.gallery .listing-item .img .img-holder {
        display: flex;
        align-items: center;
        justify-content: center; }
      .listing.gallery .listing-item .img .video-link {
        position: relative;
        width: 100%;
        height: 100%;
        display: block; }
      .listing.gallery .listing-item .img .video-icon {
        width: 78px;
        height: 78px;
        border-radius: 50%;
        color: black;
        background: white;
        border: 1px solid white;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.2s cubic-bezier(0, 0, 0, 1);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2; }
        .listing.gallery .listing-item .img .video-icon svg {
          width: 22px;
          height: 24px; }
      .listing.gallery .listing-item .img:hover .video-icon {
        color: white;
        background: transparent; }
    .listing.gallery .listing-item .content {
      align-items: center; }
      .listing.gallery .listing-item .content .title {
        font-size: 16px;
        font-size: 1.33333rem;
        line-height: 24px;
        line-height: 2rem; }
      .listing.gallery .listing-item .content .categories {
        display: flex;
        align-items: center; }
        .listing.gallery .listing-item .content .categories span {
          opacity: 0.7;
          display: flex;
          position: relative;
          padding-right: 24px;
          margin-right: 24px; }
          .listing.gallery .listing-item .content .categories span:after {
            content: '';
            width: 1px;
            height: 20px;
            background-color: black;
            transform: rotate(45deg);
            position: absolute;
            right: 0;
            opacity: 0.3; }
          .listing.gallery .listing-item .content .categories span:last-child {
            margin-right: 0;
            padding-right: 0; }
            .listing.gallery .listing-item .content .categories span:last-child:after {
              display: none; }

.videogallery {
  background: #1b1b1b;
  color: white; }
  .videogallery .buttons {
    display: flex; }
    .videogallery .buttons .button {
      padding: 19px 20px 17px 44px; }
      .videogallery .buttons .button .btn-text {
        position: relative; }
        .videogallery .buttons .button .btn-text:before {
          content: '';
          background-image: url("/assets/img/icon-arrow-left.png");
          width: 14px;
          height: 14px;
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          top: -3px;
          left: -18px; }
        .videogallery .buttons .button .btn-text.hide-arrow:before {
          background-image: none; }
      .videogallery .buttons .button:hover .btn-text:before {
        background-image: url("/assets/img/icon-arrow-left-white.svg"); }
  .videogallery .sub-heading {
    opacity: .7;
    z-index: 1; }
    .videogallery .sub-heading:before {
      background-color: white; }
  .videogallery .bv_mainselect .bv_atual {
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
    .videogallery .bv_mainselect .bv_atual .arrow {
      border-color: #fff; }
  .videogallery .listing.gallery .content p {
    color: rgba(255, 255, 255, 0.7); }
  .videogallery .listing.gallery .content .categories {
    display: flex;
    align-items: center; }
    .videogallery .listing.gallery .content .categories span:after {
      content: '';
      background-color: #fff;
      opacity: 0.7; }

.breadcrumb {
  width: 100%;
  background-color: #f4f4f4;
  padding: 24px 0;
  display: none; }
  @media (min-width: 768px) {
    .breadcrumb {
      display: block; } }
  .breadcrumb .container ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0; }
    .breadcrumb .container ul li {
      font-size: 12px;
      font-size: 1rem;
      line-height: 24px;
      line-height: 2rem; }
      .breadcrumb .container ul li span {
        opacity: 0.7; }
        .breadcrumb .container ul li span.breadcrumb-arrow {
          padding: 0 14px; }
          .breadcrumb .container ul li span.breadcrumb-arrow svg {
            height: 8px; }
        .breadcrumb .container ul li span.active {
          opacity: 1;
          font-family: 'GT-America-Expanded-Medium'; }
      .breadcrumb .container ul li a {
        margin: 0; }

.breadcrumb-no-bg .breadcrumb {
  background-color: transparent;
  padding: 40px 0; }

.download-group {
  margin-top: 32px; }

.downloads-header {
  display: flex;
  flex-direction: column; }
  @media (min-width: 768px) {
    .downloads-header {
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .downloads-header.no-cta {
        justify-content: flex-end; } }

.download-links.listing {
  flex-direction: column;
  align-items: center; }
  .download-links.listing .listing-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 20px;
    border-bottom: 1px solid #D8D8D8;
    width: calc(100% - 40px);
    align-items: center; }
    .download-links.listing .listing-item:nth-child(1) {
      border-top: 1px solid #D8D8D8; }
    .download-links.listing .listing-item span {
      flex: 1; }
    .download-links.listing .listing-item .download-item {
      margin: 10px 48px 10px 10px;
      align-self: center; }
  @media (min-width: 768px) {
    .download-links.listing {
      flex-direction: row; }
      .download-links.listing .listing-item {
        flex-direction: row;
        width: calc(50% - 10px);
        margin: 0; }
        .download-links.listing .listing-item:nth-child(2n) {
          margin: 0 0 0 20px;
          padding: 15px 20px; }
        .download-links.listing .listing-item:nth-child(-n+2) {
          border-top: 1px solid #D8D8D8; } }

.download-all {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px; }
  .download-all .button {
    align-self: flex-end;
    margin-right: 66px; }
  @media (min-width: 768px) {
    .download-all {
      justify-content: flex-end; } }

#CybotCookiebotDialog {
  font-family: 'GT-America-Extended-Light' !important;
  font-size: 14px !important; }

@media (min-width: 1200px) {
  .CybotCookiebotDialogContentWrapper {
    width: 1200px;
    max-width: 1200px !important; } }

.CybotCookiebotDialogContentWrapper #CybotCookiebotDialogHeader {
  display: none !important; }

#CybotCookiebotDialogBodyButtonAccept.CybotCookiebotDialogBodyButton {
  background-color: #000000 !important;
  border-color: #000000 !important; }

#CybotCookiebotDialogBodyButtonDecline.CybotCookiebotDialogBodyButton {
  border: 1px solid #000000 !important; }

#CybotCookiebotDialogBodyEdgeMoreDetails #CybotCookiebotDialogBodyEdgeMoreDetailsLink {
  color: #000000 !important; }

.basket-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 0px; }
  .basket-wrapper .basket {
    background-color: #ffffff;
    position: fixed;
    height: auto;
    top: 63px;
    width: 100%;
    padding: 16px;
    display: none;
    box-shadow: 0 32px 44px 0 rgba(0, 0, 0, 0.15); }
    @media (min-width: 1023px) {
      .basket-wrapper .basket {
        padding: 0 40px;
        top: 40px;
        width: 386px;
        right: 114px;
        overflow-x: hidden;
        z-index: 9; } }
    .basket-wrapper .basket .basket-error {
      color: #dd1515;
      font-size: 14px;
      font-size: 1.16667rem;
      line-height: 22px;
      line-height: 1.83333rem; }
    .basket-wrapper .basket .basket-items {
      max-height: 400px;
      overflow-y: auto;
      margin-bottom: 10px;
      border-bottom: 1px solid #e1e1e1;
      padding: 16px 16px 0; }
      .basket-wrapper .basket .basket-items::-webkit-scrollbar {
        width: 6px; }
      .basket-wrapper .basket .basket-items::-webkit-scrollbar-button {
        width: 68px; }
      .basket-wrapper .basket .basket-items::-webkit-scrollbar-track {
        background: transparent; }
      .basket-wrapper .basket .basket-items::-webkit-scrollbar-thumb {
        background: #d8d8d8;
        border: 1px solid transparent;
        border-radius: 3.5px; }
      .basket-wrapper .basket .basket-items::-webkit-scrollbar-thumb:hover {
        background: #000; }
      .basket-wrapper .basket .basket-items ul {
        padding: 0;
        margin: 0; }
        .basket-wrapper .basket .basket-items ul li {
          list-style: none; }
    .basket-wrapper .basket .basket-item {
      padding-bottom: 24px; }
      .basket-wrapper .basket .basket-item .basket-item-wrapper {
        display: flex; }
        .basket-wrapper .basket .basket-item .basket-item-wrapper.basket-item-content .basket-item-details .basket-item-name {
          font-size: 14px;
          font-size: 1.16667rem;
          line-height: 18px;
          line-height: 1.5rem;
          padding-bottom: 6px; }
        .basket-wrapper .basket .basket-item .basket-item-wrapper.basket-item-content .basket-item-details .basket-item-ref {
          font-size: 12px;
          font-size: 1rem;
          line-height: 24px;
          line-height: 2rem;
          padding-bottom: 6px;
          letter-spacing: .4px;
          opacity: .7; }
        .basket-wrapper .basket .basket-item .basket-item-wrapper.basket-item-content .basket-item-details .basket-item-price {
          font-size: 14px;
          font-size: 1.16667rem;
          line-height: 24px;
          line-height: 2rem;
          padding-bottom: 6px;
          letter-spacing: .7px; }
        .basket-wrapper .basket .basket-item .basket-item-wrapper.basket-item-content .basket-item-details .basket-item-update {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 12px 0; }
          .basket-wrapper .basket .basket-item .basket-item-wrapper.basket-item-content .basket-item-details .basket-item-update .quantity {
            display: flex;
            margin-right: 16px; }
            .basket-wrapper .basket .basket-item .basket-item-wrapper.basket-item-content .basket-item-details .basket-item-update .quantity.buttons_added input {
              font-size: 12px;
              font-size: 1rem;
              line-height: 24px;
              line-height: 2rem;
              width: 32px;
              height: 32px; }
            .basket-wrapper .basket .basket-item .basket-item-wrapper.basket-item-content .basket-item-details .basket-item-update .quantity.buttons_added input::-webkit-outer-spin-button,
            .basket-wrapper .basket .basket-item .basket-item-wrapper.basket-item-content .basket-item-details .basket-item-update .quantity.buttons_added input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0; }
            .basket-wrapper .basket .basket-item .basket-item-wrapper.basket-item-content .basket-item-details .basket-item-update .quantity.buttons_added input[type=number] {
              -moz-appearance: textfield; }
          .basket-wrapper .basket .basket-item .basket-item-wrapper.basket-item-content .basket-item-details .basket-item-update .basket-item-remove a {
            font-size: 12px;
            font-size: 1rem;
            line-height: 24px;
            line-height: 2rem;
            letter-spacing: .6px;
            text-decoration: none;
            color: #000;
            opacity: .7;
            display: flex;
            align-items: center;
            margin-bottom: 0; }
            .basket-wrapper .basket .basket-item .basket-item-wrapper.basket-item-content .basket-item-details .basket-item-update .basket-item-remove a .icon-remove {
              width: 10px;
              height: 11px;
              margin-right: 10px;
              opacity: .7; }
        .basket-wrapper .basket .basket-item .basket-item-wrapper.basket-item-content .basket-item-details .basket-item-msg {
          font-size: 10px;
          font-size: 0.83333rem;
          line-height: 18px;
          line-height: 1.5rem;
          letter-spacing: .5px;
          display: flex;
          align-items: center;
          background-color: #d4f8d7;
          color: #3a8d41;
          padding: 12px;
          margin: 16px 0 24px;
          display: none; }
          .basket-wrapper .basket .basket-item .basket-item-wrapper.basket-item-content .basket-item-details .basket-item-msg svg {
            margin-right: 10px;
            width: 12px;
            height: 10px; }
            .basket-wrapper .basket .basket-item .basket-item-wrapper.basket-item-content .basket-item-details .basket-item-msg svg g {
              fill: #3a8d41 !important; }
        .basket-wrapper .basket .basket-item .basket-item-wrapper.basket-item-content .basket-item-image {
          width: 64px;
          height: 64px;
          min-width: 64px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          margin-right: 20px;
          border: 1px solid #e1e1e1;
          border-bottom-right-radius: 40px; }
        .basket-wrapper .basket .basket-item .basket-item-wrapper.basket-item-pricing {
          width: 100px;
          font-size: 14px;
          font-size: 1.16667rem;
          line-height: 22px;
          line-height: 1.83333rem; }
          .basket-wrapper .basket .basket-item .basket-item-wrapper.basket-item-pricing .basket-item-quantity {
            margin-right: 10px; }
    .basket-wrapper .basket .basket-controls .basket-total {
      font-size: 16px;
      font-size: 1.33333rem;
      line-height: 24px;
      line-height: 2rem;
      letter-spacing: .53px;
      text-align: right;
      border-bottom: 1px solid #e1e1e1;
      padding: 10px 0 16px;
      margin-bottom: 28px; }
      .basket-wrapper .basket .basket-controls .basket-total .basket-total-txt {
        margin-right: 3px; }
    .basket-wrapper .basket .basket-controls .basket-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .basket-wrapper .basket .basket-controls .basket-buttons button, .basket-wrapper .basket .basket-controls .basket-buttons a {
        font-size: 10px;
        font-size: 0.83333rem;
        line-height: 16px;
        line-height: 1.33333rem;
        letter-spacing: 1.6px;
        height: 40px;
        padding: 11px 24px;
        border-radius: 20px;
        text-transform: uppercase; }
      .basket-wrapper .basket .basket-controls .basket-buttons .basket-close {
        background: rgba(216, 216, 216, 0.6);
        border: none;
        opacity: .6;
        min-width: 91px;
        margin-right: 8px; }
        .basket-wrapper .basket .basket-controls .basket-buttons .basket-close:hover {
          background: #000;
          transition: 0.2s cubic-bezier(0, 0, 0, 1);
          color: #fff;
          opacity: 1; }
      .basket-wrapper .basket .basket-controls .basket-buttons .basket-button {
        font-size: 10px;
        font-size: 0.83333rem;
        line-height: 18px;
        line-height: 1.5rem;
        margin: 0;
        background-color: #1b1b1b;
        min-width: 138px;
        color: #fff; }
    .basket-wrapper .basket .basket-empty-wrapper {
      padding: 1.5rem; }
      .basket-wrapper .basket .basket-empty-wrapper h4 {
        text-align: center;
        padding-bottom: 1rem; }
      .basket-wrapper .basket .basket-empty-wrapper .basket-buttons {
        justify-content: center; }
        .basket-wrapper .basket .basket-empty-wrapper .basket-buttons button {
          margin: 0; }
  @media (min-width: 769px) {
    .basket-wrapper .basket {
      padding: 40px; } }
  @media (min-width: 1200px) {
    .basket-wrapper {
      padding: 0 40px; } }

.basket-container table {
  text-align: left;
  overflow-x: initial; }
  .basket-container table td, .basket-container table th {
    min-width: initial; }
  .basket-container table .basket-title {
    min-width: 325px; }
  @media (min-width: 980px) {
    .basket-container table thead tr th:first-child {
      width: 60%; } }

.basket-container .sub-heading {
  color: #767676; }
  @media (min-width: 980px) {
    .basket-container .sub-heading {
      margin-bottom: 0; } }
  @media (min-width: 980px) {
    .basket-container .sub-heading._mobile {
      display: none; } }

.basket-container .basket-empty-wrapper h2 {
  text-align: center;
  margin-bottom: 40px; }

.basket-container .basket-empty-wrapper .basket-empty-cta a {
  width: fit-content;
  margin: 0 auto; }
  .basket-container .basket-empty-wrapper .basket-empty-cta a:hover > span {
    color: #fff;
    z-index: 9; }

@media (min-width: 769px) {
  .basket-container .basket-empty-wrapper .basket-empty-cta {
    width: 50%;
    margin: 0 auto; } }

.basket-container form {
  margin-left: 0; }
  .basket-container form input {
    margin-bottom: 0px;
    padding-bottom: 0.25rem; }
  .basket-container form .shopping-cart {
    display: block;
    width: 100%; }
    @media (min-width: 980px) {
      .basket-container form .shopping-cart {
        display: table; } }
    @media (min-width: 980px) {
      .basket-container form .shopping-cart .options-title {
        display: none; } }
    .basket-container form .shopping-cart .text-right {
      text-align: left; }
      @media (min-width: 767px) {
        .basket-container form .shopping-cart .text-right {
          text-align: right; } }
    .basket-container form .shopping-cart .text-center {
      text-align: center; }
    .basket-container form .shopping-cart thead {
      display: none; }
      @media (min-width: 980px) {
        .basket-container form .shopping-cart thead {
          display: table-row-group; } }
      .basket-container form .shopping-cart thead tr:first-child {
        background-color: transparent;
        font-weight: normal; }
      .basket-container form .shopping-cart thead th {
        font-size: 12px;
        font-size: 1rem;
        line-height: 22px;
        line-height: 1.83333rem;
        color: #000;
        padding-bottom: 10px;
        letter-spacing: .6px;
        border: none;
        border-bottom: 1px solid #e1e1e1; }
        .basket-container form .shopping-cart thead th .pl-6 {
          padding-left: 1.5rem; }
    .basket-container form .shopping-cart tbody {
      display: block; }
      @media (min-width: 980px) {
        .basket-container form .shopping-cart tbody {
          display: table-row-group; } }
      @media (max-width: 980px) {
        .basket-container form .shopping-cart tbody td {
          width: 100% !important;
          display: block !important; } }
  @media (max-width: 980px) and (min-width: 980px) {
    .basket-container form .shopping-cart tbody td {
      width: auto !important;
      display: table-cell !important; } }
      .basket-container form .shopping-cart tbody tr {
        background-color: transparent;
        padding: 2rem 0; }
        @media (max-width: 980px) {
          .basket-container form .shopping-cart tbody tr {
            display: flex;
            flex-direction: column; } }
        .basket-container form .shopping-cart tbody tr.basket-items {
          border-bottom: 1px solid #e1e1e1; }
          @media (max-width: 980px) {
            .basket-container form .shopping-cart tbody tr.basket-items .desktop {
              padding-left: 116px; } }
      .basket-container form .shopping-cart tbody td {
        font-size: 14px;
        font-size: 1.16667rem;
        line-height: 20px;
        line-height: 1.66667rem;
        padding: 0 15px 0 0;
        border: none; }
        @media (min-width: 980px) {
          .basket-container form .shopping-cart tbody td {
            padding: 2rem 0 2.25rem 0; } }
        .basket-container form .shopping-cart tbody td.basket-price {
          font-size: 16px;
          font-size: 1.33333rem;
          line-height: 24px;
          line-height: 2rem;
          color: #000;
          letter-spacing: .53px;
          margin-bottom: 22px; }
          .basket-container form .shopping-cart tbody td.basket-price span {
            font-size: 12px;
            font-size: 1rem;
            line-height: 20px;
            line-height: 1.66667rem;
            letter-spacing: .6px;
            display: inline-block;
            margin-right: 29px; }
            @media (min-width: 980px) {
              .basket-container form .shopping-cart tbody td.basket-price span {
                display: none; } }
          @media (min-width: 980px) {
            .basket-container form .shopping-cart tbody td.basket-price {
              text-align: right; } }
        .basket-container form .shopping-cart tbody td.basket-total {
          padding-right: 0;
          text-align: right; }
          .basket-container form .shopping-cart tbody td.basket-total .basket-remove {
            font-size: 12px;
            font-size: 1rem;
            line-height: 17px;
            line-height: 1.41667rem;
            color: rgba(0, 0, 0, 0.7);
            letter-spacing: .6px;
            display: flex;
            align-items: center; }
            .basket-container form .shopping-cart tbody td.basket-total .basket-remove svg {
              height: 12px;
              width: 12px;
              margin: 0 10px 0 0;
              opacity: .7;
              vertical-align: middle; }
            .basket-container form .shopping-cart tbody td.basket-total .basket-remove span {
              display: block; }
            @media (min-width: 980px) {
              .basket-container form .shopping-cart tbody td.basket-total .basket-remove {
                margin-bottom: 0;
                justify-content: flex-end; } }
          @media (max-width: 980px) {
            .basket-container form .shopping-cart tbody td.basket-total {
              text-align: left; } }
          @media (min-width: 980px) {
            .basket-container form .shopping-cart tbody td.basket-total {
              width: 6%; } }
        .basket-container form .shopping-cart tbody td.basket-qty {
          margin-bottom: 16px; }
          .basket-container form .shopping-cart tbody td.basket-qty .quantity {
            display: flex; }
            @media (min-width: 980px) {
              .basket-container form .shopping-cart tbody td.basket-qty .quantity {
                justify-content: center; } }
          @media (min-width: 980px) {
            .basket-container form .shopping-cart tbody td.basket-qty {
              width: 18%; } }
        .basket-container form .shopping-cart tbody td.basket-option-sm {
          font-size: 16px;
          font-size: 1.33333rem;
          line-height: 26px;
          line-height: 2.16667rem;
          color: #000; }
          .basket-container form .shopping-cart tbody td.basket-option-sm p {
            padding-left: 7rem; }
          @media (min-width: 980px) {
            .basket-container form .shopping-cart tbody td.basket-option-sm {
              display: none; } }
        .basket-container form .shopping-cart tbody td .items-start {
          align-items: flex-start; }
        .basket-container form .shopping-cart tbody td .flex {
          display: flex; }
          .basket-container form .shopping-cart tbody td .flex .basket-remove {
            color: #000;
            font-size: 16px;
            font-size: 1.33333rem;
            line-height: 21px;
            line-height: 1.75rem;
            display: flex;
            align-items: center;
            text-decoration: none; }
            .basket-container form .shopping-cart tbody td .flex .basket-remove svg {
              height: 16px;
              width: 16px;
              margin: 0 10px 6px 0; }
          .basket-container form .shopping-cart tbody td .flex .flex-shrink-0 {
            width: 96px;
            height: 96px;
            min-width: 96px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 1.75rem; }
            .basket-container form .shopping-cart tbody td .flex .flex-shrink-0.shopping-cart__product-image {
              border: solid 1px #e8e8e8;
              border-bottom-right-radius: 60px; }
              @media (min-width: 980px) {
                .basket-container form .shopping-cart tbody td .flex .flex-shrink-0.shopping-cart__product-image {
                  border-bottom-right-radius: 120px; } }
            @media (min-width: 980px) {
              .basket-container form .shopping-cart tbody td .flex .flex-shrink-0 {
                width: 224px;
                height: 224px; } }
          .basket-container form .shopping-cart tbody td .flex .shopping-cart__product-name {
            display: block;
            font-size: 16px;
            font-size: 1.33333rem;
            line-height: 28px;
            line-height: 2.33333rem;
            margin-bottom: 10px;
            text-decoration: none;
            text-align: left; }
            @media (min-width: 980px) {
              .basket-container form .shopping-cart tbody td .flex .shopping-cart__product-name {
                font-size: 24px;
                font-size: 2rem;
                line-height: 32px;
                line-height: 2.66667rem; } }
          .basket-container form .shopping-cart tbody td .flex .shopping-cart__product-model {
            font-size: 10px;
            font-size: 0.83333rem;
            line-height: 18px;
            line-height: 1.5rem; }
            @media (min-width: 980px) {
              .basket-container form .shopping-cart tbody td .flex .shopping-cart__product-model {
                font-size: 12px;
                font-size: 1rem;
                line-height: 24px;
                line-height: 2rem; } }
          @media (min-width: 600px) {
            .basket-container form .shopping-cart tbody td .flex .shopping-cart__product-info {
              width: 50%; } }
          .basket-container form .shopping-cart tbody td .flex .shopping-cart__cart-mobile .shopping-cart__product-price {
            font-size: 16px;
            font-size: 1.33333rem;
            line-height: 24px;
            line-height: 2rem;
            color: #000;
            letter-spacing: .53px;
            margin-bottom: 22px; }
            .basket-container form .shopping-cart tbody td .flex .shopping-cart__cart-mobile .shopping-cart__product-price span {
              font-size: 12px;
              font-size: 1rem;
              line-height: 20px;
              line-height: 1.66667rem;
              letter-spacing: .6px;
              display: inline-block;
              margin-right: 29px; }
          .basket-container form .shopping-cart tbody td .flex .shopping-cart__cart-mobile .shopping-cart__product-qty {
            margin-bottom: 16px; }
            .basket-container form .shopping-cart tbody td .flex .shopping-cart__cart-mobile .shopping-cart__product-qty .quantity {
              display: flex; }
          @media (min-width: 980px) {
            .basket-container form .shopping-cart tbody td .flex .shopping-cart__cart-mobile .shopping-cart__product-remove {
              margin-top: 20px; } }
          .basket-container form .shopping-cart tbody td .flex .shopping-cart__cart-mobile .shopping-cart__product-remove .basket-remove {
            font-size: 12px;
            font-size: 1rem;
            line-height: 17px;
            line-height: 1.41667rem;
            color: rgba(0, 0, 0, 0.7);
            letter-spacing: .6px;
            justify-content: left; }
            .basket-container form .shopping-cart tbody td .flex .shopping-cart__cart-mobile .shopping-cart__product-remove .basket-remove svg {
              height: 12px;
              width: 12px;
              margin: 0 5px 0 0;
              opacity: .7; }
          @media (min-width: 980px) {
            .basket-container form .shopping-cart tbody td .flex .shopping-cart__cart-mobile {
              display: none; } }
          .basket-container form .shopping-cart tbody td .flex .basket-product-detail {
            text-transform: uppercase;
            font-size: 13px;
            font-size: 1.08333rem;
            line-height: 21px;
            line-height: 1.75rem;
            letter-spacing: 2.32px;
            color: #000; }
            .basket-container form .shopping-cart tbody td .flex .basket-product-detail.basket-option {
              display: none; }
              @media (min-width: 980px) {
                .basket-container form .shopping-cart tbody td .flex .basket-product-detail.basket-option {
                  display: block; } }
          .basket-container form .shopping-cart tbody td .flex .shopping-cart__product-description {
            font-size: 16px;
            font-size: 1.33333rem;
            line-height: 26px;
            line-height: 2.16667rem;
            color: #000;
            margin: 0;
            font-family: 'HelveticaNeueLTStd-Lt';
            margin-bottom: 1rem; }
          .basket-container form .shopping-cart tbody td .flex .shopping-cart__remove {
            text-decoration: underline;
            margin-top: .75rem;
            display: inline-block; }
            .basket-container form .shopping-cart tbody td .flex .shopping-cart__remove:hover {
              text-decoration: none; }
        .basket-container form .shopping-cart tbody td .shopping-cart__quantity-input {
          text-align: center;
          color: #757575;
          font-size: 16px;
          font-size: 1.33333rem;
          line-height: 26px;
          line-height: 2.16667rem; }
          @media (max-width: 980px) {
            .basket-container form .shopping-cart tbody td .shopping-cart__quantity-input {
              padding: 0; } }
    .basket-container form .shopping-cart tfoot tr, .basket-container form .shopping-cart tfoot td {
      padding: 0;
      background-color: transparent;
      border: none; }
      @media (max-width: 980px) {
        .basket-container form .shopping-cart tfoot tr, .basket-container form .shopping-cart tfoot td {
          display: block; } }
    .basket-container form .shopping-cart tfoot > tr {
      display: flex; }
      @media (min-width: 980px) {
        .basket-container form .shopping-cart tfoot > tr {
          display: revert; } }
      .basket-container form .shopping-cart tfoot > tr td {
        flex: 1; }
        @media (min-width: 767px) {
          .basket-container form .shopping-cart tfoot > tr td:first-child {
            min-width: 80%; } }
      .basket-container form .shopping-cart tfoot > tr td:not(:first-child).text-right {
        text-align: right; }
    @media (min-width: 980px) {
      .basket-container form .shopping-cart tfoot .vat-wrapper td:first-child {
        padding-right: 80px; } }
    @media (max-width: 980px) {
      .basket-container form .shopping-cart tfoot {
        display: block; } }
    .basket-container form .shopping-cart tfoot .basket-vat {
      font-size: 12px;
      font-size: 1rem;
      line-height: 24px;
      line-height: 2rem;
      text-transform: uppercase;
      color: #000;
      letter-spacing: .6px;
      padding: 2rem 0 0; }
      .basket-container form .shopping-cart tfoot .basket-vat.cost {
        letter-spacing: .4px; }
    .basket-container form .shopping-cart tfoot .shopping-cart__subtotal-row td {
      font-size: 16px;
      font-size: 1.33333rem;
      line-height: 24px;
      line-height: 2rem;
      letter-spacing: .53px;
      color: #000;
      padding: 1rem 0 0; }
    @media (min-width: 980px) {
      .basket-container form .shopping-cart tfoot .shopping-cart__subtotal-row td:first-child {
        padding-right: 80px; } }
    .basket-container form .shopping-cart tfoot .discount-text td {
      font-size: 12px;
      font-size: 1rem;
      line-height: 20px;
      line-height: 1.66667rem;
      padding: 3rem 0;
      color: rgba(0, 0, 0, 0.7); }
      @media (min-width: 767px) {
        .basket-container form .shopping-cart tfoot .discount-text td {
          padding-left: 20%; } }
    .basket-container form .shopping-cart tfoot .shopping-cart__cta-row td {
      padding-right: 0;
      width: 100%; }
      @media (max-width: 980px) {
        .basket-container form .shopping-cart tfoot .shopping-cart__cta-row td {
          display: block; } }
    .basket-container form .shopping-cart tfoot .shopping-cart__cta-row .shopping-cart__cta-row-inner {
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      align-items: center; }
      @media (min-width: 600px) {
        .basket-container form .shopping-cart tfoot .shopping-cart__cta-row .shopping-cart__cta-row-inner {
          flex-direction: row;
          align-items: baseline; } }
      @media (min-width: 768px) {
        .basket-container form .shopping-cart tfoot .shopping-cart__cta-row .shopping-cart__cta-row-inner {
          justify-content: flex-end;
          margin-right: 50px; } }
      .basket-container form .shopping-cart tfoot .shopping-cart__cta-row .shopping-cart__cta-row-inner a.underline {
        order: 2; }
        @media (min-width: 600px) {
          .basket-container form .shopping-cart tfoot .shopping-cart__cta-row .shopping-cart__cta-row-inner a.underline {
            order: 1; } }
        @media (min-width: 768px) {
          .basket-container form .shopping-cart tfoot .shopping-cart__cta-row .shopping-cart__cta-row-inner a.underline {
            margin-right: 28px; } }
      .basket-container form .shopping-cart tfoot .shopping-cart__cta-row .shopping-cart__cta-row-inner .cta-row-inner {
        display: flex;
        order: 1; }
        @media (min-width: 600px) {
          .basket-container form .shopping-cart tfoot .shopping-cart__cta-row .shopping-cart__cta-row-inner .cta-row-inner {
            order: 2; } }
        .basket-container form .shopping-cart tfoot .shopping-cart__cta-row .shopping-cart__cta-row-inner .cta-row-inner #basket-button:hover span {
          color: #fff;
          z-index: 9; }
        .basket-container form .shopping-cart tfoot .shopping-cart__cta-row .shopping-cart__cta-row-inner .cta-row-inner button {
          margin: 1rem 0; }
          @media (min-width: 980px) {
            .basket-container form .shopping-cart tfoot .shopping-cart__cta-row .shopping-cart__cta-row-inner .cta-row-inner button {
              margin: 0;
              margin-right: 1.75rem; } }

.page-size-filter {
  display: flex;
  align-items: center; }
  .page-size-filter label {
    font-size: 12px;
    font-size: 1rem;
    line-height: 2px;
    line-height: 0.16667rem;
    margin-right: 8px; }
    @media (min-width: 768px) {
      .page-size-filter label {
        font-size: 14px;
        font-size: 1.16667rem;
        line-height: 1.14px;
        line-height: 0.095rem;
        margin-right: 16px; } }
  .page-size-filter .bv_mainselect {
    min-width: 70px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    display: flex;
    align-items: center;
    padding: 19px 32px 17px 32px;
    border: 1px solid #000;
    border-right-color: transparent;
    text-decoration: none;
    letter-spacing: 1.6px;
    position: relative;
    outline: none;
    text-transform: uppercase;
    align-self: flex-start;
    cursor: pointer;
    height: 32px;
    color: black;
    background: transparent;
    margin-right: 45px;
    z-index: 9; }
    @media (min-width: 768px) {
      .page-size-filter .bv_mainselect {
        min-width: 122px;
        height: 47px;
        margin-right: 52px; } }
    .page-size-filter .bv_mainselect:before {
      content: '';
      width: 48px;
      height: 38px;
      border: 0;
      border-top: 1px solid black;
      border-right: 1px solid black;
      border-bottom: 1px solid black;
      transform: skewX(-45deg);
      position: absolute;
      right: -20px;
      top: -1px;
      background-color: transparent; }
      @media (min-width: 768px) {
        .page-size-filter .bv_mainselect:before {
          height: 47px;
          right: -25px; } }
    .page-size-filter .bv_mainselect .bv_atual {
      font-size: 10px;
      font-size: 0.83333rem;
      line-height: 1.6px;
      line-height: 0.13333rem;
      padding: 15px 0 15px 0;
      border-bottom: none;
      letter-spacing: 1.6px; }
      .page-size-filter .bv_mainselect .bv_atual .arrow {
        right: -14px;
        z-index: 99; }
        .page-size-filter .bv_mainselect .bv_atual .arrow.down {
          margin-top: -5px; }
        .page-size-filter .bv_mainselect .bv_atual .arrow.up {
          margin-top: -1px; }
        @media (min-width: 768px) {
          .page-size-filter .bv_mainselect .bv_atual .arrow {
            right: 17px; } }
    .page-size-filter .bv_mainselect .bv_ul_inner {
      top: 37px;
      width: 74px;
      min-width: 74px;
      left: 8px; }
      @media (min-width: 768px) {
        .page-size-filter .bv_mainselect .bv_ul_inner {
          top: 46px;
          width: 100px;
          min-width: 100px;
          left: 19px; } }

.quantity {
  display: inline-block; }
  .quantity input[type=number]::-webkit-inner-spin-button,
  .quantity input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0; }
  .quantity input[type=number] {
    -moz-appearance: textfield; }
  .quantity.buttons_added {
    text-align: left;
    position: relative;
    white-space: nowrap;
    vertical-align: top; }
    .quantity.buttons_added input {
      display: inline-block;
      margin: 0;
      vertical-align: top;
      box-shadow: none;
      font-size: 16px;
      font-size: 1.33333rem;
      line-height: 24px;
      line-height: 2rem; }
    .quantity.buttons_added .minus, .quantity.buttons_added .plus {
      font-size: 20px;
      font-size: 1.66667rem;
      line-height: 28px;
      line-height: 2.33333rem;
      height: 48px;
      width: 48px;
      background-color: #ffffff;
      border: 1px solid #e1e1e1;
      cursor: pointer; }
      .quantity.buttons_added .minus:hover:not([disabled]), .quantity.buttons_added .plus:hover:not([disabled]) {
        background: #eeeeee; }
      .quantity.buttons_added .minus[disabled], .quantity.buttons_added .plus[disabled] {
        background: #eeeeee;
        opacity: .4; }
        .quantity.buttons_added .minus[disabled]:hover, .quantity.buttons_added .plus[disabled]:hover {
          cursor: not-allowed; }
      .quantity.buttons_added .minus:focus, .quantity.buttons_added .plus:focus {
        outline: none; }
    .quantity.buttons_added .minus {
      border-right: 0; }
    .quantity.buttons_added .plus {
      border-left: 0; }
    .quantity.buttons_added .input-text.qty {
      width: 48px;
      height: 48px;
      padding: 0 5px;
      text-align: center;
      background-color: transparent;
      border: 1px solid #e1e1e1; }

.product-breadcrumb {
  display: none; }
  @media (min-width: 980px) {
    .product-breadcrumb {
      margin-bottom: 48px;
      display: block; } }

@media (max-width: 1023px) {
  .vendrcheckoutcheckoutsteppage header .icon.main-logo {
    fill: #ffffff; } }

.checkout-container {
  margin-top: -2.5rem; }
  @media (min-width: 980px) {
    .checkout-container .checkout-wrapper {
      display: flex;
      flex-direction: row-reverse; } }
  .checkout-container .checkout-wrapper .order-summary-wrapper {
    background-color: #f4f4f4;
    padding: 27px 32px;
    margin-top: 31px;
    z-index: 9;
    position: relative; }
    .checkout-container .checkout-wrapper .order-summary-wrapper .rotate-svg {
      transform: rotate(180deg); }
    .checkout-container .checkout-wrapper .order-summary-wrapper.close-summary {
      z-index: 0; }
    @media (min-width: 980px) {
      .checkout-container .checkout-wrapper .order-summary-wrapper {
        z-index: initial; } }
    .checkout-container .checkout-wrapper .order-summary-wrapper .hidden {
      display: none; }
      @media (min-width: 980px) {
        .checkout-container .checkout-wrapper .order-summary-wrapper .hidden {
          display: block;
          z-index: initial; } }
    @media (min-width: 980px) {
      .checkout-container .checkout-wrapper .order-summary-wrapper {
        margin-top: 3rem;
        width: 40%;
        padding: 1rem 2.5rem;
        border-top-left-radius: 24px; } }
    @media (min-width: 1366px) {
      .checkout-container .checkout-wrapper .order-summary-wrapper {
        width: 20%;
        min-width: 400px;
        padding: 1rem 2.5rem;
        margin-top: 7rem; } }
    .checkout-container .checkout-wrapper .order-summary-wrapper .order-summary {
      font-size: 12px;
      font-size: 1rem;
      line-height: 24px;
      line-height: 2rem;
      color: #000;
      letter-spacing: .6px;
      font-family: 'GT-America-Expanded-Medium';
      text-decoration: none; }
      @media (min-width: 980px) {
        .checkout-container .checkout-wrapper .order-summary-wrapper .order-summary {
          display: none; } }
      .checkout-container .checkout-wrapper .order-summary-wrapper .order-summary:hover {
        cursor: pointer; }
      .checkout-container .checkout-wrapper .order-summary-wrapper .order-summary svg {
        height: 8px;
        width: 12px;
        opacity: .7; }
      .checkout-container .checkout-wrapper .order-summary-wrapper .order-summary .summary-total {
        float: right; }
    @media (min-width: 980px) {
      .checkout-container .checkout-wrapper .order-summary-wrapper #order-summary {
        display: block; } }
    .checkout-container .checkout-wrapper .order-summary-wrapper #order-summary .discount-codes {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      border-bottom: 1px solid #DBDBDB;
      padding-bottom: 1rem;
      margin-bottom: 2rem; }
      .checkout-container .checkout-wrapper .order-summary-wrapper #order-summary .discount-codes > li {
        list-style: none;
        padding: 0.25rem 0;
        color: #000000;
        font-size: 14px;
        font-size: 1.16667rem;
        line-height: 18px;
        line-height: 1.5rem;
        text-decoration: none;
        letter-spacing: 2px;
        text-transform: uppercase;
        font-family: 'HelveticaNeueLTStd-Md'; }
        .checkout-container .checkout-wrapper .order-summary-wrapper #order-summary .discount-codes > li .basket-remove {
          display: flex;
          align-items: center;
          text-decoration: none;
          font-size: 13px;
          font-size: 1.08333rem;
          line-height: 21px;
          line-height: 1.75rem;
          text-transform: none; }
          .checkout-container .checkout-wrapper .order-summary-wrapper #order-summary .discount-codes > li .basket-remove svg {
            height: 16px;
            width: 16px;
            margin: 0 10px 6px 0; }
    .checkout-container .checkout-wrapper .order-summary-wrapper #order-summary .order-summary-item-list {
      padding: 0;
      list-style-type: none;
      margin-top: 32px; }
      .checkout-container .checkout-wrapper .order-summary-wrapper #order-summary .order-summary-item-list .order-list-items {
        list-style: none;
        border-bottom: 1px solid #DBDBDB; }
        .checkout-container .checkout-wrapper .order-summary-wrapper #order-summary .order-summary-item-list .order-list-items .order-list-items-wrapper {
          padding: 2rem 0;
          display: flex; }
          .checkout-container .checkout-wrapper .order-summary-wrapper #order-summary .order-summary-item-list .order-list-items .order-list-items-wrapper .checkout-item {
            display: flex;
            margin-right: 1rem; }
            @media (min-width: 767px) {
              .checkout-container .checkout-wrapper .order-summary-wrapper #order-summary .order-summary-item-list .order-list-items .order-list-items-wrapper .checkout-item {
                width: 100%;
                margin-right: 0; }
                .checkout-container .checkout-wrapper .order-summary-wrapper #order-summary .order-summary-item-list .order-list-items .order-list-items-wrapper .checkout-item .flex-order-details {
                  width: 100%; } }
            .checkout-container .checkout-wrapper .order-summary-wrapper #order-summary .order-summary-item-list .order-list-items .order-list-items-wrapper .checkout-item .checkout-product-img {
              width: 80px;
              height: 80px;
              min-width: 80px;
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center center;
              margin-right: 1rem;
              border-bottom-right-radius: 35px; }
              @media (min-width: 767px) {
                .checkout-container .checkout-wrapper .order-summary-wrapper #order-summary .order-summary-item-list .order-list-items .order-list-items-wrapper .checkout-item .checkout-product-img {
                  margin-right: 1.75rem; } }
            .checkout-container .checkout-wrapper .order-summary-wrapper #order-summary .order-summary-item-list .order-list-items .order-list-items-wrapper .checkout-item .checkout-product-name {
              font-size: 16px;
              font-size: 1.33333rem;
              line-height: 24px;
              line-height: 2rem;
              color: #000; }
            .checkout-container .checkout-wrapper .order-summary-wrapper #order-summary .order-summary-item-list .order-list-items .order-list-items-wrapper .checkout-item .checkout-product-info {
              margin-top: 10px;
              display: flex;
              flex-direction: column; }
              .checkout-container .checkout-wrapper .order-summary-wrapper #order-summary .order-summary-item-list .order-list-items .order-list-items-wrapper .checkout-item .checkout-product-info > div {
                display: flex;
                flex-direction: row;
                justify-content: space-between; }
              .checkout-container .checkout-wrapper .order-summary-wrapper #order-summary .order-summary-item-list .order-list-items .order-list-items-wrapper .checkout-item .checkout-product-info span {
                display: block;
                letter-spacing: .6px;
                opacity: .7;
                padding: .25rem 0; }
            .checkout-container .checkout-wrapper .order-summary-wrapper #order-summary .order-summary-item-list .order-list-items .order-list-items-wrapper .checkout-item .checkout-product-spec {
              font-size: 14px;
              font-size: 1.16667rem;
              line-height: 18px;
              line-height: 1.5rem;
              color: #000000;
              display: flex;
              flex-direction: column; }
              @media (min-width: 980px) {
                .checkout-container .checkout-wrapper .order-summary-wrapper #order-summary .order-summary-item-list .order-list-items .order-list-items-wrapper .checkout-item .checkout-product-spec {
                  font-size: 16px;
                  font-size: 1.33333rem;
                  line-height: 26px;
                  line-height: 2.16667rem; } }
          .checkout-container .checkout-wrapper .order-summary-wrapper #order-summary .order-summary-item-list .order-list-items .order-list-items-wrapper .checkout-product-cost {
            display: none; }
            @media (min-width: 980px) {
              .checkout-container .checkout-wrapper .order-summary-wrapper #order-summary .order-summary-item-list .order-list-items .order-list-items-wrapper .checkout-product-cost {
                color: #000000;
                font-size: 12px;
                font-size: 1rem;
                line-height: 20px;
                line-height: 1.66667rem;
                letter-spacing: .4px;
                flex: 1;
                text-align: right;
                display: block;
                opacity: .7; } }
    .checkout-container .checkout-wrapper .order-summary-wrapper #order-summary .customer-code-form {
      display: flex;
      margin: 1.5rem 0;
      flex-wrap: wrap; }
      .checkout-container .checkout-wrapper .order-summary-wrapper #order-summary .customer-code-form input[type="text"] {
        width: calc(100% - 100px);
        padding-bottom: 0px;
        border: 0;
        border-bottom: 1px solid #e1e1e1;
        padding: 10px 0;
        background: transparent; }
      .checkout-container .checkout-wrapper .order-summary-wrapper #order-summary .customer-code-form button {
        height: 55px;
        width: 100px;
        font-size: 12px;
        font-size: 1rem;
        line-height: 24px;
        line-height: 2rem;
        color: #000;
        letter-spacing: .6px;
        font-family: 'GT-America-Expanded-Medium';
        border: 0;
        -webkit-appearance: none;
        padding-right: 0; }
      .checkout-container .checkout-wrapper .order-summary-wrapper #order-summary .customer-code-form .field-validation-error {
        display: block;
        width: 100%;
        font-size: 14px;
        font-size: 1.16667rem;
        line-height: 20px;
        line-height: 1.66667rem; }
    .checkout-container .checkout-wrapper .order-summary-wrapper #order-summary .checkout-subtotal-items > div {
      font-size: 12px;
      font-size: 1rem;
      line-height: 20px;
      line-height: 1.66667rem;
      display: flex;
      color: #000;
      letter-spacing: .6px;
      justify-content: flex-end;
      padding: 0.25rem 0; }
      .checkout-container .checkout-wrapper .order-summary-wrapper #order-summary .checkout-subtotal-items > div .checkout-item-number {
        min-width: 100px;
        text-align: right; }
    .checkout-container .checkout-wrapper .order-summary-wrapper #order-summary .checkout-subtotal-items .checkout-total-item {
      font-size: 16px;
      font-size: 1.33333rem;
      line-height: 24px;
      line-height: 2rem;
      letter-spacing: .53px;
      color: #000;
      padding-top: 0.5rem; }
  @media (min-width: 980px) {
    .checkout-container .checkout-wrapper .checkout-information {
      width: 70%;
      padding: 0 2rem; } }
  @media (min-width: 1366px) {
    .checkout-container .checkout-wrapper .checkout-information {
      width: 63%;
      padding: 0 12rem 0 2rem; } }
  .checkout-container .checkout-wrapper .checkout-information .checkout-info {
    margin: 2rem 0; }
    .checkout-container .checkout-wrapper .checkout-information .checkout-info h1 {
      margin-bottom: 3rem; }
    .checkout-container .checkout-wrapper .checkout-information .checkout-info section a.button {
      width: fit-content; }
    .checkout-container .checkout-wrapper .checkout-information .checkout-info section.order-complete-summary {
      margin: 0 auto; }
    .checkout-container .checkout-wrapper .checkout-information .checkout-info section.checkout-summary {
      margin-top: 40px; }
      .checkout-container .checkout-wrapper .checkout-information .checkout-info section.checkout-summary h3 {
        margin-bottom: 16px; }
      .checkout-container .checkout-wrapper .checkout-information .checkout-info section.checkout-summary a.button {
        margin: 3rem 0 2rem; }
    .checkout-container .checkout-wrapper .checkout-information .checkout-info h3 {
      font-size: 16px;
      font-size: 1.33333rem;
      line-height: 24px;
      line-height: 2rem;
      margin-bottom: 16px; }
      .checkout-container .checkout-wrapper .checkout-information .checkout-info h3.border-bottom {
        border-bottom: 1px solid #e1e1e1;
        padding-bottom: 16px; }
      .checkout-container .checkout-wrapper .checkout-information .checkout-info h3 span {
        font-family: 'GT-America-Expanded-Medium'; }
    @media (min-width: 1200px) {
      .checkout-container .checkout-wrapper .checkout-information .checkout-info form .no-flex {
        max-width: 45%; } }
    @media (min-width: 980px) {
      .checkout-container .checkout-wrapper .checkout-information .checkout-info form .flex-fields {
        display: flex;
        justify-content: space-between; } }
    .checkout-container .checkout-wrapper .checkout-information .checkout-info form .flex-fields > div {
      display: flex;
      flex-direction: column; }
      @media (min-width: 980px) {
        .checkout-container .checkout-wrapper .checkout-information .checkout-info form .flex-fields > div {
          width: 48%; } }
      .checkout-container .checkout-wrapper .checkout-information .checkout-info form .flex-fields > div label {
        letter-spacing: .6px;
        opacity: .7;
        margin-bottom: 7px; }
      .checkout-container .checkout-wrapper .checkout-information .checkout-info form .flex-fields > div ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        font-size: 16px;
        font-size: 1.33333rem;
        line-height: 24px;
        line-height: 2rem;
        color: #000;
        opacity: 1;
        /* Firefox */ }
      .checkout-container .checkout-wrapper .checkout-information .checkout-info form .flex-fields > div :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-size: 16px;
        font-size: 1.33333rem;
        line-height: 24px;
        line-height: 2rem;
        color: #000; }
      .checkout-container .checkout-wrapper .checkout-information .checkout-info form .flex-fields > div ::-ms-input-placeholder {
        /* Microsoft Edge */
        font-size: 16px;
        font-size: 1.33333rem;
        line-height: 24px;
        line-height: 2rem;
        color: #000; }
    @media (min-width: 980px) {
      .checkout-container .checkout-wrapper .checkout-information .checkout-info form .flex-fields._desktop {
        flex-direction: column; }
        .checkout-container .checkout-wrapper .checkout-information .checkout-info form .flex-fields._desktop > div {
          width: 100%; }
        .checkout-container .checkout-wrapper .checkout-information .checkout-info form .flex-fields._desktop.delivery-info {
          margin-top: 32px; } }
    @media (min-width: 1200px) {
      .checkout-container .checkout-wrapper .checkout-information .checkout-info form .flex-fields {
        display: flex;
        justify-content: space-between; }
        .checkout-container .checkout-wrapper .checkout-information .checkout-info form .flex-fields > div {
          width: 45%; }
        .checkout-container .checkout-wrapper .checkout-information .checkout-info form .flex-fields .selectric {
          padding-bottom: 5px; } }
    @media (min-width: 768px) {
      .checkout-container .checkout-wrapper .checkout-information .checkout-info form {
        margin-left: 0; } }
    .checkout-container .checkout-wrapper .checkout-information .checkout-info form input, .checkout-container .checkout-wrapper .checkout-information .checkout-info form textarea, .checkout-container .checkout-wrapper .checkout-information .checkout-info form select, .checkout-container .checkout-wrapper .checkout-information .checkout-info form .selectric-wrapper {
      margin-bottom: 2rem; }
    .checkout-container .checkout-wrapper .checkout-information .checkout-info form input:not(input[type=checkbox]) {
      font-size: 16px;
      font-size: 1.33333rem;
      line-height: 24px;
      line-height: 2rem;
      color: #000;
      border: 0;
      border-bottom: 1px solid #e1e1e1;
      padding: 10px 0;
      background: transparent; }
    .checkout-container .checkout-wrapper .checkout-information .checkout-info form textarea {
      font-size: 16px;
      font-size: 1.33333rem;
      line-height: 24px;
      line-height: 2rem;
      color: #000;
      border: 1px solid #e1e1e1;
      margin-top: 1.5rem;
      border-radius: 24px;
      min-height: 175px;
      padding: 15px;
      width: 100%;
      max-width: 100%; }
    .checkout-container .checkout-wrapper .checkout-information .checkout-info form h1 {
      font-size: 32px;
      font-size: 2.66667rem;
      line-height: 40px;
      line-height: 3.33333rem; }
    .checkout-container .checkout-wrapper .checkout-information .checkout-info form h2 {
      border-bottom: 1px solid #e1e1e1;
      padding-bottom: 16px; }
    .checkout-container .checkout-wrapper .checkout-information .checkout-info form .step-info-banner {
      background-color: #262626;
      padding: 24px 20px;
      display: flex;
      align-items: center;
      color: #fff;
      margin-bottom: 3rem; }
      .checkout-container .checkout-wrapper .checkout-information .checkout-info form .step-info-banner svg {
        margin-right: 16px;
        width: 35px; }
      .checkout-container .checkout-wrapper .checkout-information .checkout-info form .step-info-banner p {
        font-size: 12px;
        font-size: 1rem;
        line-height: 16px;
        line-height: 1.33333rem;
        color: #fff;
        margin: 0;
        letter-spacing: .24px; }
        .checkout-container .checkout-wrapper .checkout-information .checkout-info form .step-info-banner p a {
          color: #fff;
          text-decoration: underline;
          letter-spacing: .24px; }
    .checkout-container .checkout-wrapper .checkout-information .checkout-info form h2, .checkout-container .checkout-wrapper .checkout-information .checkout-info form h3 {
      font-size: 16px;
      font-size: 1.33333rem;
      line-height: 24px;
      line-height: 2rem; }
    .checkout-container .checkout-wrapper .checkout-information .checkout-info form .contact-info .step-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      margin-bottom: 31px; }
      .checkout-container .checkout-wrapper .checkout-information .checkout-info form .contact-info .step-header span {
        font-size: 10px;
        font-size: 0.83333rem;
        line-height: 24px;
        line-height: 2rem;
        opacity: .7;
        letter-spacing: .6px;
        display: block;
        margin-bottom: 2rem; }
      .checkout-container .checkout-wrapper .checkout-information .checkout-info form .contact-info .step-header::after {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 0 1px 1px;
        border-color: #e1e1e1;
        display: block;
        width: 100%;
        top: 40px; }
    .checkout-container .checkout-wrapper .checkout-information .checkout-info form .contact-info h3 {
      margin: 2.5rem 0;
      border-bottom: 1px solid #e1e1e1;
      padding-bottom: 16px; }
    .checkout-container .checkout-wrapper .checkout-information .checkout-info form .contact-info #shipping-info .flex-fields .bv_mainselect {
      margin-bottom: 2rem; }
      @media (min-width: 1200px) {
        .checkout-container .checkout-wrapper .checkout-information .checkout-info form .contact-info #shipping-info .flex-fields .bv_mainselect {
          margin-bottom: initial; } }
    .checkout-container .checkout-wrapper .checkout-information .checkout-info .shipping-method-radio {
      margin: 3rem 0; }
      .checkout-container .checkout-wrapper .checkout-information .checkout-info .shipping-method-radio ul {
        padding: 0; }
        .checkout-container .checkout-wrapper .checkout-information .checkout-info .shipping-method-radio ul > li {
          list-style: none;
          display: flex;
          border-bottom: 1px solid #DBDBDB;
          justify-content: space-between;
          padding: 32px 0;
          justify-content: space-between; }
          .checkout-container .checkout-wrapper .checkout-information .checkout-info .shipping-method-radio ul > li:first-child {
            padding-top: 0; }
          .checkout-container .checkout-wrapper .checkout-information .checkout-info .shipping-method-radio ul > li label {
            border-radius: 50%;
            cursor: pointer;
            display: flex;
            align-items: center;
            padding-left: 2.5rem;
            position: relative;
            top: 0.25rem; }
            .checkout-container .checkout-wrapper .checkout-information .checkout-info .shipping-method-radio ul > li label .radio-button__custom-indicator {
              background-color: #fff;
              border: 1px solid #000;
              border-radius: 50%;
              height: 24px;
              left: 0;
              position: absolute;
              top: -7px;
              width: 24px; }
              .checkout-container .checkout-wrapper .checkout-information .checkout-info .shipping-method-radio ul > li label .radio-button__custom-indicator::after {
                background-color: #000;
                border-radius: 50%;
                content: "";
                display: none;
                height: 8px;
                left: 7px;
                position: absolute;
                top: 7px;
                width: 8px; }
            .checkout-container .checkout-wrapper .checkout-information .checkout-info .shipping-method-radio ul > li label .radio-label {
              margin-top: -7px; }
            .checkout-container .checkout-wrapper .checkout-information .checkout-info .shipping-method-radio ul > li label input {
              opacity: 0;
              position: absolute;
              z-index: -1; }
              .checkout-container .checkout-wrapper .checkout-information .checkout-info .shipping-method-radio ul > li label input:checked ~ .radio-button__custom-indicator::after {
                display: block; }
    .checkout-container .checkout-wrapper .checkout-information .checkout-info .general-info p strong {
      font-size: 16px;
      font-size: 1.33333rem;
      line-height: 24px;
      line-height: 2rem;
      font-family: 'GT-America-Extended-Light';
      margin: 2rem 0;
      display: block;
      color: #000;
      font-weight: normal; }
    .checkout-container .checkout-wrapper .checkout-information .checkout-info .payment-title {
      margin-top: 3rem;
      border-bottom: 1px solid #e1e1e1;
      padding-bottom: 16px; }
    .checkout-container .checkout-wrapper .checkout-information .checkout-info .review-contact-info {
      margin: 2rem 0; }
      @media (min-width: 767px) {
        .checkout-container .checkout-wrapper .checkout-information .checkout-info .review-contact-info {
          display: flex; } }
      .checkout-container .checkout-wrapper .checkout-information .checkout-info .review-contact-info > div {
        margin: 24px 0; }
        @media (min-width: 767px) {
          .checkout-container .checkout-wrapper .checkout-information .checkout-info .review-contact-info > div {
            flex: 1; } }
        .checkout-container .checkout-wrapper .checkout-information .checkout-info .review-contact-info > div label {
          font-size: 12px;
          font-size: 1rem;
          line-height: 24px;
          line-height: 2rem;
          letter-spacing: .6px;
          display: block;
          opacity: .7; }
        .checkout-container .checkout-wrapper .checkout-information .checkout-info .review-contact-info > div span {
          font-size: 16px;
          font-size: 1.33333rem;
          line-height: 24px;
          line-height: 2rem;
          letter-spacing: .6px; }
    @media (min-width: 420px) {
      .checkout-container .checkout-wrapper .checkout-information .checkout-info .review-addresses {
        display: flex; }
        .checkout-container .checkout-wrapper .checkout-information .checkout-info .review-addresses > div {
          flex: 1; } }
    .checkout-container .checkout-wrapper .checkout-information .checkout-info .review-addresses .review-address {
      font-size: 12px;
      font-size: 1rem;
      line-height: 24px;
      line-height: 2rem;
      letter-spacing: .6px;
      opacity: .7;
      display: flex;
      flex-direction: column;
      margin: 2rem 0; }
    .checkout-container .checkout-wrapper .checkout-information .checkout-info .review-notes {
      padding: 40px 0; }
    .checkout-container .checkout-wrapper .checkout-information .checkout-info .checkbox-wrapper {
      display: block;
      margin-bottom: 15px; }
      .checkout-container .checkout-wrapper .checkout-information .checkout-info .checkbox-wrapper input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer; }
      .checkout-container .checkout-wrapper .checkout-information .checkout-info .checkbox-wrapper label {
        font-size: 10px;
        font-size: 0.83333rem;
        line-height: 18px;
        line-height: 1.5rem;
        letter-spacing: .6px;
        position: relative;
        cursor: pointer;
        display: flex; }
        @media (min-width: 600px) {
          .checkout-container .checkout-wrapper .checkout-information .checkout-info .checkbox-wrapper label {
            align-items: center; } }
        .checkout-container .checkout-wrapper .checkout-information .checkout-info .checkbox-wrapper label::before {
          content: '';
          -webkit-appearance: none;
          background-color: transparent;
          border: 1px solid #000;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
          padding: 10px;
          display: inline-block;
          position: relative;
          vertical-align: middle;
          cursor: pointer;
          margin-right: 12px;
          height: 24px;
          width: 24px; }
      .checkout-container .checkout-wrapper .checkout-information .checkout-info .checkbox-wrapper input:checked + label:after {
        content: '';
        display: block;
        position: absolute;
        top: 5px;
        left: 10px;
        width: 5px;
        height: 10px;
        border: solid #000;
        border-width: 0 1px 1px 0;
        transform: rotate(45deg); }
    .checkout-container .checkout-wrapper .checkout-information .checkout-info .order-print {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      margin-bottom: 64px; }
      @media (min-width: 768px) {
        .checkout-container .checkout-wrapper .checkout-information .checkout-info .order-print {
          flex-direction: row;
          margin-bottom: 80px; } }
      .checkout-container .checkout-wrapper .checkout-information .checkout-info .order-print a {
        display: flex; }
        .checkout-container .checkout-wrapper .checkout-information .checkout-info .order-print a.print {
          font-size: 12px;
          font-size: 1rem;
          line-height: 24px;
          line-height: 2rem;
          letter-spacing: .6px;
          font-family: 'GT-America-Expanded-Medium';
          text-decoration: none;
          color: #000; }
        .checkout-container .checkout-wrapper .checkout-information .checkout-info .order-print a.button:hover {
          color: #fff; }
        @media (min-width: 768px) {
          .checkout-container .checkout-wrapper .checkout-information .checkout-info .order-print a.button {
            margin-right: 8rem; } }
  .checkout-container .checkout-wrapper .checkout-information .form-cta-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 64px; }
    @media (min-width: 400px) {
      .checkout-container .checkout-wrapper .checkout-information .form-cta-btn {
        flex-direction: row-reverse; } }
    @media (min-width: 768px) {
      .checkout-container .checkout-wrapper .checkout-information .form-cta-btn {
        justify-content: space-between;
        margin-bottom: 80px; } }
    .checkout-container .checkout-wrapper .checkout-information .form-cta-btn button {
      margin-bottom: 2rem;
      height: 48px;
      width: fit-content;
      min-width: fit-content;
      margin: 0 8rem 20px auto; }
      @media (min-width: 400px) {
        .checkout-container .checkout-wrapper .checkout-information .form-cta-btn button {
          flex: 1;
          margin: 0 5rem 1rem auto; } }
      .checkout-container .checkout-wrapper .checkout-information .form-cta-btn button::before {
        width: 55px;
        height: 48px;
        right: -28px; }
    .checkout-container .checkout-wrapper .checkout-information .form-cta-btn a {
      font-weight: 600;
      width: 100%;
      margin-bottom: 10px;
      text-align: center; }
      @media (min-width: 400px) {
        .checkout-container .checkout-wrapper .checkout-information .form-cta-btn a {
          text-align: initial; } }

.content-listing h2 {
  text-align: center; }

.content-listing-wrapper {
  display: flex;
  flex-direction: column; }

.content-listing-item {
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  margin-bottom: 30px; }

.content-listing-container {
  position: relative;
  height: 243px;
  overflow: hidden; }

.content-listing-image {
  display: block; }
  .content-listing-image:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1; }
  .content-listing-image img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.content-listing-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2; }
  .content-listing-text a {
    color: #fff; }
    .content-listing-text a.small {
      text-transform: uppercase;
      letter-spacing: 1.76px;
      font-size: 11px;
      font-size: 0.91667rem;
      line-height: 19px;
      line-height: 1.58333rem; }

.content-listing-links {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
  padding: 32px; }
  .content-listing-links .title {
    font-size: 14px;
    font-size: 1.16667rem;
    line-height: 24px;
    line-height: 2rem;
    color: #000;
    margin-bottom: 8px; }
  .content-listing-links ul {
    flex-grow: 1;
    list-style-type: none;
    padding: 0;
    margin: 0 0 10px 0; }
  .content-listing-links li {
    padding: 0; }
    .content-listing-links li a {
      font-size: 12px;
      font-size: 1rem;
      line-height: 20px;
      line-height: 1.66667rem;
      display: inline-block;
      padding: 6px 0;
      margin: 0;
      opacity: .6; }
  .content-listing-links .btn-text {
    font-size: 11px;
    font-size: 0.91667rem;
    line-height: 19px;
    line-height: 1.58333rem;
    text-transform: uppercase;
    letter-spacing: 1.76px;
    opacity: .7;
    margin: 0; }

@media (min-width: 768px) {
  .content-listing-wrapper {
    flex-direction: row; }
  .content-listing-item {
    flex: 1;
    margin-left: 12px;
    margin-bottom: 0; }
    .content-listing-item:first-of-type {
      margin-left: 0;
      margin-right: 12px; }
  .content-listing-container {
    height: 382px; } }

.productdetail .image-gallery-carousel .sub-heading {
  justify-content: center; }
  .productdetail .image-gallery-carousel .sub-heading::before {
    content: none; }

.productdetail .main-nav .icon.main-logo {
  fill: #fff; }
  @media (min-width: 1023px) {
    .productdetail .main-nav .icon.main-logo {
      fill: #000; } }

@media (min-width: 1023px) {
  .product-breadcrumb {
    margin-top: 200px; } }

.product-detail {
  display: flex;
  flex-direction: column; }
  .product-detail h2 {
    margin-bottom: 12px; }
  .product-detail .sub-title {
    font-size: 12px;
    font-size: 1rem;
    line-height: 15px;
    line-height: 1.25rem;
    opacity: .7;
    margin-bottom: 24px; }
  .product-detail__info.mobile .sub-heading {
    margin-top: 32px;
    color: #767676; }
  .product-detail__info.mobile h2 {
    font-size: 32px;
    font-size: 2.66667rem;
    line-height: 40px;
    line-height: 3.33333rem;
    margin-bottom: 16px; }
  .product-detail__info.mobile .product-price._mobile p {
    font-size: 12px;
    font-size: 1rem;
    line-height: 24px;
    line-height: 2rem;
    color: #000;
    letter-spacing: normal;
    margin-bottom: 32px; }
    .product-detail__info.mobile .product-price._mobile p span {
      font-size: 20px;
      font-size: 1.66667rem;
      line-height: 24px;
      line-height: 2rem; }
  .product-detail__info.mobile .product-price__from.hide {
    display: none; }
  .product-detail .product-details {
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }
    .product-detail .product-details .add-to-basket {
      margin: 40px 0; }
      @media (min-width: 768px) {
        .product-detail .product-details .add-to-basket {
          margin: 40px 0 48px; } }
  .product-detail #product-finish {
    display: none; }
  .product-detail__left {
    order: 3; }
    .product-detail__left .desktop {
      display: none; }
    .product-detail__left .sub-heading {
      color: #767676; }
  .product-detail__image .images {
    display: flex;
    justify-content: left;
    position: relative; }
  .product-detail__image--img {
    overflow: hidden;
    border-bottom-right-radius: 80px;
    border: 1px solid #e8e8e8;
    margin-bottom: 0; }
    .product-detail__image--img img {
      display: block;
      max-width: 100%;
      margin: 0 auto; }
  .product-detail__image--secondary {
    display: none; }
  .product-detail__image--icon {
    position: absolute;
    bottom: 20px;
    border-radius: 50px;
    height: 48px;
    min-width: 48px;
    background-color: #F4F4F4;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 20px; }
  .product-detail__image .finish-select {
    margin: 32px 0; }
    .product-detail__image .finish-select__title {
      font-size: 16px;
      font-size: 1.33333rem;
      line-height: 1.5px;
      line-height: 0.125rem;
      color: #000;
      padding: 16px 0; }
    .product-detail__image .finish-select ul {
      padding: 0; }
      .product-detail__image .finish-select ul li {
        display: inline-block;
        margin-right: 10px; }
    .product-detail__image .finish-select__item {
      font-size: 10px;
      font-size: 0.83333rem;
      line-height: 1.6px;
      line-height: 0.13333rem;
      letter-spacing: .6px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: rgba(0, 0, 0, 0.7); }
      .product-detail__image .finish-select__item img {
        width: 40px;
        height: 40px;
        border-radius: 50px;
        margin-bottom: 10px;
        border: none;
        padding: 8px; }
    .product-detail__image .finish-select .border {
      color: #000; }
      .product-detail__image .finish-select .border img {
        border: 1px solid #000; }
  .product-detail__accordians {
    order: 3;
    display: flex;
    flex-direction: column; }
    .product-detail__accordians--wrapper {
      padding: 10px 0;
      border-bottom: 1px solid #e8e8e8; }
      .product-detail__accordians--wrapper ul {
        list-style: none; }
        .product-detail__accordians--wrapper ul li {
          letter-spacing: 0.6px;
          color: rgba(0, 0, 0, 0.7); }
          .product-detail__accordians--wrapper ul li:before {
            content: "\2022";
            color: rgba(0, 0, 0, 0.7);
            display: inline-block;
            width: 1em;
            margin-left: -1em; }
    .product-detail__accordians--single {
      padding: 10px 0; }
      .product-detail__accordians--single h4 {
        margin-bottom: 0; }
      .product-detail__accordians--single .accordion {
        cursor: pointer;
        width: 100%;
        transition: 0.2s cubic-bezier(0, 0, 0, 1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: transparent;
        border: 0;
        padding: 0; }
        .product-detail__accordians--single .accordion .plus-icon {
          width: 20px;
          height: 20px;
          position: relative; }
          .product-detail__accordians--single .accordion .plus-icon:before, .product-detail__accordians--single .accordion .plus-icon:after {
            content: "";
            position: absolute;
            background-color: #2A2A2A;
            transition: transform 0.2s cubic-bezier(0, 0, 0, 1); }
          .product-detail__accordians--single .accordion .plus-icon:before {
            top: 0;
            left: 50%;
            width: 2px;
            height: 100%;
            margin-left: -1px; }
          .product-detail__accordians--single .accordion .plus-icon:after {
            top: 50%;
            left: 0;
            width: 100%;
            height: 2px;
            margin-top: -1px; }
        .product-detail__accordians--single .accordion.active .plus-icon:before {
          transform: rotate(90deg); }
        .product-detail__accordians--single .accordion.active .plus-icon:after {
          transform: rotate(180deg); }
      .product-detail__accordians--single .panel {
        max-height: 0;
        overflow: hidden;
        transition: 0.2s cubic-bezier(0, 0, 0, 1); }
        .product-detail__accordians--single .panel.active {
          max-height: 600px; }
        .product-detail__accordians--single .panel .wrapper {
          padding: 16px 0 16px 16px; }
          .product-detail__accordians--single .panel .wrapper ul {
            list-style: none; }
            .product-detail__accordians--single .panel .wrapper ul li {
              letter-spacing: 0.6px;
              color: rgba(0, 0, 0, 0.7); }
              .product-detail__accordians--single .panel .wrapper ul li:before {
                content: "\2022";
                color: rgba(0, 0, 0, 0.7);
                display: inline-block;
                width: 1em;
                margin-left: -1em; }
              .product-detail__accordians--single .panel .wrapper ul li a {
                color: rgba(0, 0, 0, 0.7); }
          .product-detail__accordians--single .panel .wrapper.download-panel {
            padding-bottom: 0;
            padding-left: 0; }
            .product-detail__accordians--single .panel .wrapper.download-panel .product-download {
              padding: 16px;
              font-weight: 700;
              border-top: 1px solid #e8e8e8; }
              .product-detail__accordians--single .panel .wrapper.download-panel .product-download:nth-child(even) {
                background-color: #f4f4f4; }
              .product-detail__accordians--single .panel .wrapper.download-panel .product-download a {
                margin-bottom: 0;
                display: flex;
                justify-content: space-between;
                align-items: center; }
                .product-detail__accordians--single .panel .wrapper.download-panel .product-download a span {
                  font-size: 11px;
                  line-height: 16px;
                  text-transform: uppercase;
                  font-weight: 400;
                  letter-spacing: 1.6px;
                  display: flex;
                  align-items: center; }
                  .product-detail__accordians--single .panel .wrapper.download-panel .product-download a span svg {
                    margin-right: 16px; }
            .product-detail__accordians--single .panel .wrapper.download-panel .download-button {
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              border-top: 1px solid #e8e8e8;
              margin: 0;
              padding: 15px;
              text-transform: uppercase; }
              .product-detail__accordians--single .panel .wrapper.download-panel .download-button p {
                display: flex;
                align-items: center;
                font-size: 11px;
                line-height: 16px;
                letter-spacing: 1.6px;
                text-transform: uppercase; }
                .product-detail__accordians--single .panel .wrapper.download-panel .download-button p svg {
                  margin-right: 16px; }
        .product-detail__accordians--single .panel p:last-child {
          margin: 0; }
    .product-detail__accordians .make-enquiry {
      margin-top: 30px; }
  .product-detail .product-details .product-price {
    margin-top: 16px; }
    .product-detail .product-details .product-price p {
      font-size: 16px;
      font-size: 1.33333rem;
      line-height: 24px;
      line-height: 2rem;
      color: #000;
      letter-spacing: normal;
      margin-bottom: 32px; }
      .product-detail .product-details .product-price p span {
        font-size: 20px;
        font-size: 1.66667rem;
        line-height: 24px;
        line-height: 2rem; }
    @media (min-width: 980px) {
      .product-detail .product-details .product-price {
        margin-top: initial; } }
    .product-detail .product-details .product-price__from.hide {
      display: none; }
  .product-detail .product-details .product-quantity {
    margin-top: 16px; }
    .product-detail .product-details .product-quantity p {
      font-size: 16px;
      font-size: 1.33333rem;
      line-height: 24px;
      line-height: 2rem;
      color: #000; }
    @media (min-width: 768px) {
      .product-detail .product-details .product-quantity {
        margin-top: 32px; } }
  .product-detail .product-details .product-bulk-msg {
    margin-top: 16px; }
    .product-detail .product-details .product-bulk-msg p {
      font-size: 12px;
      font-size: 1rem;
      line-height: 24px;
      line-height: 2rem;
      letter-spacing: .6px;
      opacity: .7; }
      .product-detail .product-details .product-bulk-msg p a {
        text-decoration: underline; }
  .product-detail .product-details .customise-buy-buttons .finish-error {
    display: flex;
    align-items: baseline;
    margin-top: -16px;
    margin-bottom: 32px; }
    @media (min-width: 767px) {
      .product-detail .product-details .customise-buy-buttons .finish-error {
        margin-top: -30px; } }
    .product-detail .product-details .customise-buy-buttons .finish-error span {
      font-size: 10px;
      font-size: 0.83333rem;
      line-height: 18px;
      line-height: 1.5rem;
      color: #dd1b1b; }
    .product-detail .product-details .customise-buy-buttons .finish-error svg {
      margin-right: 10px;
      width: 15px;
      height: 10px; }
  .product-detail .product-details .customise-buy-buttons .add-to-basket-error {
    display: flex;
    align-items: baseline;
    margin-top: -32px;
    margin-bottom: 32px;
    display: none; }
    .product-detail .product-details .customise-buy-buttons .add-to-basket-error span {
      font-size: 10px;
      font-size: 0.83333rem;
      line-height: 18px;
      line-height: 1.5rem;
      color: #dd1b1b; }
    .product-detail .product-details .customise-buy-buttons .add-to-basket-error svg {
      margin-right: 10px;
      width: 15px;
      height: 10px; }
  @media (min-width: 1023px) {
    .product-detail {
      flex-direction: row;
      padding-top: 32px; }
      .product-detail .mobile {
        display: none; }
      .product-detail__left {
        order: 0;
        flex: 1;
        margin-right: 10px; }
        .product-detail__left .desktop {
          display: block; }
      .product-detail__image {
        width: 50%;
        flex: 1;
        margin-left: 40px; }
        .product-detail__image.product-finishes {
          margin-left: 0;
          width: 100%; }
        .product-detail__image .images {
          display: flex;
          flex-direction: column; }
        .product-detail__image--img {
          border-bottom-right-radius: 160px; } }

.brochure-container {
  position: relative; }
  .brochure-container .bg-shape {
    display: block;
    position: absolute;
    width: 300%;
    overflow: hidden;
    top: -230px;
    bottom: 0;
    height: calc(100% + 230px);
    right: 0; }
    .brochure-container .bg-shape svg {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      fill: #F5F5F5; }
    @media (min-width: 768px) {
      .brochure-container .bg-shape {
        width: 200%; } }
    @media (min-width: 980px) {
      .brochure-container .bg-shape {
        width: 100%; } }
  .brochure-container .listing {
    z-index: 2;
    position: relative; }
    .brochure-container .listing .listing-item:first-child:before {
      content: "";
      height: 1px;
      background-color: #DBDBDB;
      position: absolute;
      top: 0;
      left: 64px;
      right: 64px; }
  .brochure-container:after {
    z-index: 1;
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    background: #ECECEC;
    border-radius: 100% 0 0 0;
    width: 100%;
    padding-bottom: 100%;
    max-width: 730px; }
    @media (min-width: 768px) {
      .brochure-container:after {
        width: 60%;
        padding-bottom: 45%; } }

.all-downloads-cta {
  margin-top: 64px;
  display: flex;
  justify-content: center; }
  .all-downloads-cta a {
    margin: 0 auto; }

.pac-container.pac-logo:after {
  background-image: none !important; }

.find-a-showroom .no-results-wrapper {
  display: none; }
  .find-a-showroom .no-results-wrapper .showroom-no-results {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 32px; }
    .find-a-showroom .no-results-wrapper .showroom-no-results a {
      width: fit-content;
      margin: 32px auto 0 auto; }
    @media (min-width: 768px) {
      .find-a-showroom .no-results-wrapper .showroom-no-results {
        padding: 0 64px; } }
  .find-a-showroom .no-results-wrapper .results-form {
    position: relative; }
    @media (min-width: 980px) {
      .find-a-showroom .no-results-wrapper .results-form:before {
        content: "";
        background: #f5f5f5;
        width: 50%;
        position: absolute;
        top: 0;
        bottom: 0; } }
    .find-a-showroom .no-results-wrapper .results-form .container.no-results-form {
      background: #F5F5F5;
      padding: 0;
      border-bottom-right-radius: 80px; }
      .find-a-showroom .no-results-wrapper .results-form .container.no-results-form h2 {
        padding-top: 32px;
        margin: 0;
        text-align: center; }
        @media (min-width: 980px) {
          .find-a-showroom .no-results-wrapper .results-form .container.no-results-form h2 {
            padding-top: 60px; } }
      @media (min-width: 980px) {
        .find-a-showroom .no-results-wrapper .results-form .container.no-results-form {
          max-width: 1000px;
          border-bottom-right-radius: 160px; } }
      .find-a-showroom .no-results-wrapper .results-form .container.no-results-form form {
        padding: 32px; }
        @media (min-width: 980px) {
          .find-a-showroom .no-results-wrapper .results-form .container.no-results-form form {
            padding: 40px 60px 60px; }
            .find-a-showroom .no-results-wrapper .results-form .container.no-results-form form .col-md-12 {
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-start;
              margin: 20px 0 0;
              align-items: flex-start;
              max-width: 100%;
              flex-direction: row;
              align-items: flex-start;
              justify-content: space-between; }
          @-webkit-keyframes rotate {
            100% {
              -webkit-transform: rotate(360deg); } }
          @keyframes rotate {
            100% {
              -webkit-transform: rotate(360deg); } }
              .find-a-showroom .no-results-wrapper .results-form .container.no-results-form form .col-md-12 .umbraco-forms-field {
                width: 100%;
                margin-bottom: 30px; } }
      @media (min-width: 980px) and (min-width: 768px) {
        .find-a-showroom .no-results-wrapper .results-form .container.no-results-form form .col-md-12 .umbraco-forms-field {
          padding-right: 30px; } }
      @media (min-width: 980px) and (min-width: 768px) {
        .find-a-showroom .no-results-wrapper .results-form .container.no-results-form form .col-md-12 .umbraco-forms-field {
          width: 50%; }
          .find-a-showroom .no-results-wrapper .results-form .container.no-results-form form .col-md-12 .umbraco-forms-field.longanswer, .find-a-showroom .no-results-wrapper .results-form .container.no-results-form form .col-md-12 .umbraco-forms-field.dataconsent, .find-a-showroom .no-results-wrapper .results-form .container.no-results-form form .col-md-12 .umbraco-forms-field.singlechoice, .find-a-showroom .no-results-wrapper .results-form .container.no-results-form form .col-md-12 .umbraco-forms-field.multiplechoice, .find-a-showroom .no-results-wrapper .results-form .container.no-results-form form .col-md-12 .umbraco-forms-field.titleanddescription, .find-a-showroom .no-results-wrapper .results-form .container.no-results-form form .col-md-12 .umbraco-forms-field.recaptcha2 {
            width: 100%;
            padding-right: 0;
            max-width: 100%; } }

.find-a-showroom .showroom-filter {
  background-color: #f4f4f4;
  border-bottom-right-radius: 80px;
  padding: 32px; }
  .find-a-showroom .showroom-filter h2 {
    text-align: center;
    margin-bottom: 36px; }
  .find-a-showroom .showroom-filter .filter-wrapper {
    display: flex;
    flex-direction: column; }
    @media (min-width: 768px) {
      .find-a-showroom .showroom-filter .filter-wrapper {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 auto; } }
    @media (min-width: 980px) {
      .find-a-showroom .showroom-filter .filter-wrapper {
        width: 85%; } }
    @media (min-width: 1200px) {
      .find-a-showroom .showroom-filter .filter-wrapper {
        width: 75%; } }
    .find-a-showroom .showroom-filter .filter-wrapper fieldset {
      border: none;
      padding: 0;
      margin: 0; }
      @media (min-width: 768px) {
        .find-a-showroom .showroom-filter .filter-wrapper fieldset {
          width: calc(50% - 30px); } }
      .find-a-showroom .showroom-filter .filter-wrapper fieldset .validation-msg {
        font-size: 12px;
        font-size: 1rem;
        line-height: 16px;
        line-height: 1.33333rem;
        display: none;
        margin: 24px 0;
        color: #dd1515;
        background-color: rgba(221, 21, 21, 0.1);
        padding: 0.75rem;
        max-width: 400px; }
    .find-a-showroom .showroom-filter .filter-wrapper .filter-input {
      display: flex;
      flex-direction: column; }
      .find-a-showroom .showroom-filter .filter-wrapper .filter-input > div {
        display: flex;
        flex-direction: column; }
      .find-a-showroom .showroom-filter .filter-wrapper .filter-input label {
        letter-spacing: .6px;
        opacity: .7;
        margin-bottom: 16px; }
      .find-a-showroom .showroom-filter .filter-wrapper .filter-input input {
        font-size: 16px;
        font-size: 1.33333rem;
        line-height: 20px;
        line-height: 1.66667rem;
        color: #000;
        border: 0;
        border-bottom: 1px solid #000;
        padding: 10px 0;
        background: transparent;
        max-width: 400px; }
      .find-a-showroom .showroom-filter .filter-wrapper .filter-input .location-marker {
        flex-direction: row;
        align-items: center;
        margin-top: 25px; }
        .find-a-showroom .showroom-filter .filter-wrapper .filter-input .location-marker:hover span {
          cursor: pointer; }
        .find-a-showroom .showroom-filter .filter-wrapper .filter-input .location-marker span {
          font-size: 12px;
          font-size: 1rem;
          line-height: 15px;
          line-height: 1.25rem;
          letter-spacing: .04em;
          font-family: 'GT-America-Expanded-Medium';
          margin-left: 7px; }
        .find-a-showroom .showroom-filter .filter-wrapper .filter-input .location-marker svg {
          width: 32px;
          height: 28px; }
    .find-a-showroom .showroom-filter .filter-wrapper .filter-checkboxes {
      margin-top: 41px; }
      @media (min-width: 768px) {
        .find-a-showroom .showroom-filter .filter-wrapper .filter-checkboxes {
          margin-top: 0; } }
      .find-a-showroom .showroom-filter .filter-wrapper .filter-checkboxes span {
        font-size: 12px;
        font-size: 1rem;
        line-height: 15px;
        line-height: 1.25rem;
        letter-spacing: .6px;
        opacity: .7; }
      .find-a-showroom .showroom-filter .filter-wrapper .filter-checkboxes .checkbox-wrapper {
        margin-top: 20px; }
        .find-a-showroom .showroom-filter .filter-wrapper .filter-checkboxes .checkbox-wrapper .checkboxlist {
          margin-top: 10px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap; }
          .find-a-showroom .showroom-filter .filter-wrapper .filter-checkboxes .checkbox-wrapper .checkboxlist label {
            font-size: 16px;
            font-size: 1.33333rem;
            line-height: 24px;
            line-height: 2rem;
            user-select: none;
            display: block;
            position: relative;
            padding-left: 25px;
            order: 2;
            text-transform: none;
            padding: 0 36px;
            margin-bottom: 22px; }
          .find-a-showroom .showroom-filter .filter-wrapper .filter-checkboxes .checkbox-wrapper .checkboxlist input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            order: 1;
            margin-top: 3px; }
            .find-a-showroom .showroom-filter .filter-wrapper .filter-checkboxes .checkbox-wrapper .checkboxlist input:checked ~ .checkmark:after {
              display: block; }
          .find-a-showroom .showroom-filter .filter-wrapper .filter-checkboxes .checkbox-wrapper .checkboxlist .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 24px;
            width: 24px;
            background-color: transparent;
            border: 1px solid rgba(0, 0, 0, 0.3); }
            .find-a-showroom .showroom-filter .filter-wrapper .filter-checkboxes .checkbox-wrapper .checkboxlist .checkmark.active-border {
              border: 1px solid black; }
            .find-a-showroom .showroom-filter .filter-wrapper .filter-checkboxes .checkbox-wrapper .checkboxlist .checkmark:after {
              content: url(../../img/icon-tick.svg);
              position: absolute;
              display: none;
              left: 4px;
              top: 4px;
              width: 16px;
              height: 16px; }
    .find-a-showroom .showroom-filter .filter-wrapper .buttons {
      margin: 36px 0 36px; }
      @media (min-width: 768px) {
        .find-a-showroom .showroom-filter .filter-wrapper .buttons {
          margin: 36px 0;
          width: calc(100% - 30px); } }
      .find-a-showroom .showroom-filter .filter-wrapper .buttons .button span {
        font-size: 11px;
        font-size: 0.91667rem;
        line-height: 14px;
        line-height: 1.16667rem;
        letter-spacing: .16em;
        opacity: 1; }
      @media (min-width: 768px) {
        .find-a-showroom .showroom-filter .filter-wrapper .buttons .button {
          margin: 0 auto; } }
      .find-a-showroom .showroom-filter .filter-wrapper .buttons .button:disabled, .find-a-showroom .showroom-filter .filter-wrapper .buttons .button[disabled] {
        opacity: .6;
        cursor: not-allowed; }
  @media (min-width: 768px) {
    .find-a-showroom .showroom-filter {
      border-bottom-right-radius: 160px; } }

.find-a-showroom .showroom-map.hidden {
  display: none; }

.find-a-showroom .showroom-map.container {
  padding: 0;
  margin: 0; }
  @media (min-width: 768px) {
    .find-a-showroom .showroom-map.container {
      margin: 0 auto;
      padding: 0 32px; } }

.find-a-showroom .showroom-map h2 {
  margin-bottom: 48px;
  text-align: center; }

.find-a-showroom .showroom-results {
  margin-bottom: 48px; }
  @media (min-width: 768px) {
    .find-a-showroom .showroom-results {
      margin-bottom: 80px; } }
  .find-a-showroom .showroom-results .showroom .nearest-title, .find-a-showroom .showroom-results .showroom .secondary-title {
    text-align: center; }
  .find-a-showroom .showroom-results .showroom .nearest-results {
    border-bottom: 1px solid #e1e1e1;
    padding: 31px 8px; }
    .find-a-showroom .showroom-results .showroom .nearest-results:nth-of-type(1), .find-a-showroom .showroom-results .showroom .nearest-results:nth-of-type(3) {
      border-top: 1px solid #e1e1e1; }
    @media (min-width: 768px) {
      .find-a-showroom .showroom-results .showroom .nearest-results {
        padding: 37px 20px; } }
    .find-a-showroom .showroom-results .showroom .nearest-results .accordion-minus {
      display: none; }
      .find-a-showroom .showroom-results .showroom .nearest-results .accordion-minus:hover {
        cursor: pointer; }
    .find-a-showroom .showroom-results .showroom .nearest-results .accordion-plus svg {
      transition: transform 0.2s ease-in; }
      .find-a-showroom .showroom-results .showroom .nearest-results .accordion-plus svg:hover {
        cursor: pointer;
        transform: rotate(45deg); }
    .find-a-showroom .showroom-results .showroom .nearest-results.first-item .accordion-minus {
      display: inline; }
    .find-a-showroom .showroom-results .showroom .nearest-results.first-item .accordion-plus {
      display: none; }
  .find-a-showroom .showroom-results .showroom.premier-showroom .location-title .location-title-text span.prem-showroom-label {
    color: #84754E;
    opacity: 1; }
    .find-a-showroom .showroom-results .showroom.premier-showroom .location-title .location-title-text span.prem-showroom-label:before {
      content: "• ";
      color: #84754E; }
  .find-a-showroom .showroom-results .showroom.premier-showroom .location-title .btn-toggle .accordion-minus {
    display: none; }
  .find-a-showroom .showroom-results .showroom.premier-showroom.first-item .accordion-minus {
    display: inline; }
  .find-a-showroom .showroom-results .showroom.premier-showroom.first-item .accordion-plus {
    display: none; }
  .find-a-showroom .showroom-results .showroom .secondary-title {
    margin: 64px 0 32px; }
    @media (min-width: 768px) {
      .find-a-showroom .showroom-results .showroom .secondary-title {
        margin: 64px 0 48px; } }
  .find-a-showroom .showroom-results .showroom .location-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .find-a-showroom .showroom-results .showroom .location-title > div:first-child {
      display: flex;
      align-items: center; }
    .find-a-showroom .showroom-results .showroom .location-title .location-title-icon svg {
      width: 32px;
      height: 39px; }
      @media (min-width: 768px) {
        .find-a-showroom .showroom-results .showroom .location-title .location-title-icon svg {
          width: 44px;
          height: 53px; } }
    .find-a-showroom .showroom-results .showroom .location-title .location-title-text {
      padding: 0 13px; }
      @media (min-width: 768px) {
        .find-a-showroom .showroom-results .showroom .location-title .location-title-text {
          padding: 0 0 0 15px; } }
      .find-a-showroom .showroom-results .showroom .location-title .location-title-text p {
        color: #000;
        margin: 0;
        letter-spacing: initial; }
      .find-a-showroom .showroom-results .showroom .location-title .location-title-text span {
        font-size: 10px;
        font-size: 0.83333rem;
        line-height: 24px;
        line-height: 2rem;
        letter-spacing: .4px;
        opacity: .6; }
        .find-a-showroom .showroom-results .showroom .location-title .location-title-text span.prem-showroom-label {
          color: #84754E;
          opacity: 1; }
          .find-a-showroom .showroom-results .showroom .location-title .location-title-text span.prem-showroom-label:before {
            content: "• ";
            color: #84754E; }
        @media (min-width: 768px) {
          .find-a-showroom .showroom-results .showroom .location-title .location-title-text span {
            font-size: 12px;
            font-size: 1rem;
            line-height: 24px;
            line-height: 2rem; } }
  .find-a-showroom .showroom-results .showroom .location-details {
    display: none; }
    .find-a-showroom .showroom-results .showroom .location-details.first-item {
      display: block; }
    .find-a-showroom .showroom-results .showroom .location-details .location-details-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 43px;
      flex-wrap: wrap; }
      @media (min-width: 1080px) {
        .find-a-showroom .showroom-results .showroom .location-details .location-details-wrapper {
          flex-wrap: initial; } }
      .find-a-showroom .showroom-results .showroom .location-details .location-details-wrapper .showroom-details {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; }
        @media (min-width: 768px) {
          .find-a-showroom .showroom-results .showroom .location-details .location-details-wrapper .showroom-details {
            flex-wrap: initial; } }
        .find-a-showroom .showroom-results .showroom .location-details .location-details-wrapper .showroom-details .showroom-details-title {
          font-size: 14px;
          font-size: 1.16667rem;
          line-height: 18px;
          line-height: 1.5rem;
          colour: #000;
          opacity: 1; }
        .find-a-showroom .showroom-results .showroom .location-details .location-details-wrapper .showroom-details .location-address {
          width: 50%; }
          @media (min-width: 768px) {
            .find-a-showroom .showroom-results .showroom .location-details .location-details-wrapper .showroom-details .location-address {
              padding-right: 60px;
              width: initial; } }
        .find-a-showroom .showroom-results .showroom .location-details .location-details-wrapper .showroom-details .location-phone {
          width: 45%;
          padding-left: 30px; }
          @media (min-width: 768px) {
            .find-a-showroom .showroom-results .showroom .location-details .location-details-wrapper .showroom-details .location-phone {
              padding: 0 60px;
              width: initial; } }
        .find-a-showroom .showroom-results .showroom .location-details .location-details-wrapper .showroom-details .location-products {
          width: 100%;
          margin-top: 24px; }
          @media (min-width: 768px) {
            .find-a-showroom .showroom-results .showroom .location-details .location-details-wrapper .showroom-details .location-products {
              padding: 0 60px;
              width: initial;
              margin-top: 0; } }
        .find-a-showroom .showroom-results .showroom .location-details .location-details-wrapper .showroom-details p, .find-a-showroom .showroom-results .showroom .location-details .location-details-wrapper .showroom-details a, .find-a-showroom .showroom-results .showroom .location-details .location-details-wrapper .showroom-details span {
          font-size: 12px;
          font-size: 1rem;
          line-height: 24px;
          line-height: 2rem;
          letter-spacing: .4px;
          opacity: .6; }
      .find-a-showroom .showroom-results .showroom .location-details .location-details-wrapper .contact-links {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 40px; }
        @media (min-width: 1080px) {
          .find-a-showroom .showroom-results .showroom .location-details .location-details-wrapper .contact-links {
            align-items: end;
            margin-top: 0px;
            width: initial;
            text-align: right; } }
        .find-a-showroom .showroom-results .showroom .location-details .location-details-wrapper .contact-links a {
          font-size: 12px;
          font-size: 1rem;
          line-height: 15px;
          line-height: 1.25rem;
          margin-bottom: 20px;
          letter-spacing: .4px;
          font-weight: 600; }

.premier-content {
  background: #fff;
  max-width: 640px;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 40px;
  width: 80%; }
  @media (min-width: 768px) {
    .premier-content {
      padding: 100px;
      width: 100%; } }
  .premier-content p {
    font-size: 12px;
    font-size: 1rem;
    line-height: 24px;
    line-height: 2rem;
    letter-spacing: .4px; }
  .premier-content button {
    display: none; }
  .premier-content .close-premier-popup {
    background-color: #f4f4f4;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    top: 12px;
    right: 12px; }
    @media (min-width: 768px) {
      .premier-content .close-premier-popup {
        width: 48px;
        height: 48px;
        top: 24px;
        right: 24px; } }
    .premier-content .close-premier-popup svg {
      transition: transform 0.2s ease-in; }
      .premier-content .close-premier-popup svg:hover {
        transform: rotate(45deg); }
    .premier-content .close-premier-popup:hover {
      cursor: pointer; }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.close-btn,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.close-btn, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button.mfp-arrow {
  display: block; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-download {
  position: absolute;
  right: 30px;
  color: #fff;
  text-decoration: none;
  bottom: 65px; }
  .mfp-download svg {
    width: 16px;
    height: 16px;
    fill: #fff;
    margin-right: 10px; }
  .mfp-download.download-black {
    color: #000; }
    .mfp-download.download-black svg {
      fill: #000; }

.close-btn {
  display: flex;
  align-items: center;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 50px;
  text-decoration: none;
  text-align: center;
  font-size: 16px;
  font-size: 1.33333rem;
  line-height: 24px;
  line-height: 2rem;
  padding: 0 0 18px 10px;
  color: #FFF;
  margin-right: 20px; }
  .close-btn svg {
    border: 1px solid #000;
    fill: #000;
    border-radius: 50%;
    margin-left: 10px;
    width: 17px;
    height: 17px;
    display: block;
    padding: 2px;
    margin-bottom: 3px; }
    .close-btn svg.white-close {
      border: 1px solid #fff;
      fill: #fff; }
  .close-btn.inline-close {
    position: inherit;
    margin-right: 0px; }

.mfp-close-btn-in .close-btn {
  color: #333; }
  @media (min-width: 768px) {
    .mfp-close-btn-in .close-btn {
      align-self: flex-start; } }

.mfp-image-holder .close-btn,
.mfp-iframe-holder .close-btn {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: auto; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before, .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .close-btn {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .close-btn {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }
