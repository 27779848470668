.filter {
	position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 99;
    overflow: hidden; 
    width: 100%;     

     &.show {
 		visibility: visible; 
 		opacity: 1; transition: 
 		visibility 0s linear 0s, opacity 300ms;
     }

     &.hide {
     	visibility: hidden; 
        opacity: 0; 
     	transition: visibility 0s linear 300ms, opacity 300ms;
     }
   

    &:before {
      	content: '';
      	position: absolute;
      	top: 0;
      	bottom: 0;
      	right: 0;
      	left: 0;
      	background: rgba(0,0,0,.5);
      	z-index: 99;
      	opacity: 0;
      	transition: opacity $duration $easing;
     }
   

    .filter-wrapper {
    	width: 0;
    	position: absolute;
    	top: 0;
    	bottom: 0;
    	left: 0;
    	overflow-y: auto;
    	z-index: 100;
    	background: white;
        transition: width $duration $easing;

        &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }

    &::-webkit-scrollbar-track {
        background: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background: #000;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #000;
    }


    	.filters {
    		width: 100%;
	    	padding:128px 32px 32px;
	    }

	    .close {
	    	background: $pale-grey;
	    	padding: 16px 40px 13px 24px;
        color: black;
        position: absolute;
        top: 32px;
        right: 0;
        border-radius: 24px 0 0 24px;
        cursor: pointer;
        &:hover {
        	background: black;
        	color: white;
          svg {
            fill: $white;
          }
        }
	    }


    	@media (min-width: $max-tablet ) {	       
	       

        .filters {
	    		padding: 128px 64px 64px;
	    		// width: 415px;
	    		display: block;
	    	}
	    }

	    
    }

    &.show {
	

		&:before {
			opacity: 1;
		}

		.filter-wrapper {
			width: 100%;
			
			@media (min-width: $max-tablet ) {	
				width: 415px;
			}
		}
    }

    form {
      width: 100%;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
    }

    .faqSection.container .faqs .accordion-multi {
          padding: 0 0 16px;
          border: 0;
          border-bottom: 1px solid $border;

          &.active {
            background-color: transparent;
          }

          &:hover {
            background-color: transparent;
          }
       

    }


    .umbraco-forms-fieldset .umbraco-forms-container {
      padding: 0;
    }

    .umbraco-forms-fieldset .umbraco-forms-container .umbraco-forms-field  {
      width: 100%;
      border: 0;

      .filter-group {
        @include font-size(16,24);
      }
    

        .checkboxlist {
          display: flex;
          flex-direction: column;
          margin: 0;

          label {
            @include font-size(10,24);
            display: flex;
            align-items: center;
            opacity: .7;
            padding: 12px 0;
            border-bottom: 1px solid $border;
            letter-spacing: .6px;
          }

          .checkmark {
            top: 50%;
            right: 0;
            left: auto;
            transform: translateY(-50%);
          }
        }
    }
}