.site-header {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    .main-nav {
        display: flex;
        flex-direction: column;
        align-items: center;

        .logo {
            position: absolute;
            top: 48px;

            .main-logo {
                width: 90px;
                height: 30px;

                &.mobile {
                    display: block;
                }

                &.desktop {
                    display: none;
                }
            }

            a {
                color: white;
            }

            @media(min-width: 1024px) {
                top: 16px;
                margin: 43px auto 28px;
                position: relative;

                a {
                    color: black;
                }

                .main-logo {
                    width: 105px;
                    height: 45px;

                    &.desktop {
                        display: block;
                    }

                    &.mobile {
                        display: none;
                    }
                }
            }
        }

        &.dark {

            .logo {
                a {
                    color: white;

                    svg {
                        fill: white;
                    }
                }
            }
        }
    }

    .search-panel {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: white;
        transition: $duration $easing;
        overflow: hidden;
        z-index: 9;
        color: $text;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-height: 0;


        @media(min-width: $breakpoint) {
            top: 0;
        }

        &.active {
            max-height: 300px;
        }

        form {
            width: 100%;
            max-width: 498px;
            display: flex;
            align-items: center;
            margin: 5px 0;
            padding: 24px;


            @media(min-width: $breakpoint) {
                padding: 0;
            }


            .search-close {
                width: 12px;
                margin-left: 24px;
                cursor: pointer;

                svg {
                    width: 12px;
                    height: 12px;
                }
            }
        }

        .explore-links {
            background-color: #f4f4f4;
            width: 100%;
            padding: 40px 45px;

            @media(min-width: $breakpoint) {
                padding: 32px 0;
            }


            .wrapper {
                width: 100%;
                max-width: 498px;
                margin: 0 auto;
            }


            .title {
                opacity: 0.3;
                @include font-size(14);
            }

            ul {
                width: 100%;
                margin: 16px 0;
                padding: 0;


                li {
                    list-style: none;
                    margin: 16px 0;
                    padding: 0;

                    a {
                        color: $text;
                        opacity: 0.7;
                        letter-spacing: .6px;
                    }
                }
            }
        }
    }


    .upper-nav {
        display: grid;
        grid-template:
            "one" auto "two" auto / auto max-content max-content;
        font-size: 11px;
        background-color: $dark-grey;
        height: 96px;
        color: $white;
        padding: 0 32px;

        @media(max-width: #{$breakpoint - 1}) {
            justify-items: end;
        }

        @media(min-width: $breakpoint) {
            height: 40px;
            grid-template:
                "one two three" auto / auto max-content max-content;
        }

        a {
            display: flex;
            align-items: center;
            opacity: 0.6;
            color: $white;
            margin-bottom: 0px;
            padding: 0 32px;
            border-right: 1px solid $white;

            &:first-child {
                padding-left: 0px;
            }

            &:last-child {
                border-right: none;
                padding-right: 0;
            }

            &:hover {
                opacity: 1;
            }
        }

        .upper-links {
            display: flex;
            align-items: center;

            .header-icons {

                button {
                    background-color: transparent;
                    padding: 0px;
                    border: 0;
                    outline: none;
                }

                .shopping-basket {
                    position: relative;
                    margin-right: 15px;

                    &.basket-active, &:hover {
                        /*border-bottom: 1px solid $black;*/
                        &:after {
                            width: 100%;
                        }

                        opacity: 1;
                    }

                    svg {
                        width: 24px;
                        height: 26px;
                        margin-top: 4px;
                        opacity: .6;
                        transition: all .2s ease-in-out;

                        &:hover {
                            opacity: 1;
                        }
                    }

                    .items {
                        @include font-size(10,10);
                        border-radius: 50%;
                        background-color: $white;
                        color: $black;
                        width: 20px;
                        height: 20px;
                        text-align: center;
                        position: absolute;
                        top: 0;
                        left: -12px;
                        padding-top: 6px;
                        padding-right: 1px;
                    }
                }
            }

            @media(min-width: 1024px) {
                .header-icons {

                    .shopping-basket {
                        margin-right: 23px;

                        svg {
                            width: 16px;
                            height: 18px;
                        }

                        .items {
                            @include font-size(8,10);
                            width: 17px;
                            height: 17px;
                            text-align: center;
                            padding-top: 4px;
                        }
                    }
                }
            }

            &.language-selector {
                display: flex;
                grid-area: one;
                margin-right: -20px;

                @media(min-width: $breakpoint) {
                    position: relative;
                    grid-area: auto;
                    margin-right: 0;
                }

                .language-filter {
                    cursor: pointer;
                    // font-family: 'GT-America-Extended-Light';
                    display: flex;
                    align-items: center;
                    opacity: .6;
                    text-transform: uppercase;
                    padding: 0 10px;
                    transition: opacity $duration $easing;

                    & > .icon {
                        transition: transform $duration $easing;
                    }

                    &:hover {
                        opacity: 1;
                    }

                    &.language-active {
                        opacity: 1;

                        & > .icon {
                            transform: rotate(180deg);
                        }
                    }

                    @media(min-width: 767px) {
                        padding: 0 32px;
                    }

                    .flag {
                        margin-right: 10px;

                        img {
                            width: 22px;
                            margin-top: 2px;
                        }
                    }

                    span {
                        margin-right: 8px;
                    }
                }

                .language-dropdown {
                    position: absolute;
                    display: none;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    flex-direction: column;
                    top: 40px;
                    right: 0;
                    width: 100%;
                    z-index: 5;


                    @media(min-width: $breakpoint) {
                        top: 40px;
                        right: 30px;
                    }

                    ul {
                        padding-left: 0;
                        margin: 0;
                        list-style-type: none;

                        li {
                            &.region {
                                padding: 30px 0 28px;
                                background-color: $dropdown-bg;

                                span {
                                    @include font-size(18);
                                    color: $white;
                                    display: inline-block;
                                    padding: 0 30px 12px 30px;
                                }
                            }

                            a {
                                @include font-size(12);
                                width: 100%;
                                padding: 12px 30px;
                                background-color: $dropdown-bg;
                                text-transform: uppercase;

                                &:hover {
                                    background-color: $sub-nav-hover;
                                }

                                .flag {
                                    margin-right: 10px;

                                    img {
                                        width: 22px;
                                        margin-top: 3px;
                                    }
                                }
                            }
                        }
                    }

                    &.language-filter-active {
                        display: flex;
                    }
                }
            }

            &.quick-links {
                display: none;

                @media(min-width: 1024px) {
                    display: flex;
                }
            }

            &.cta-links {
                grid-area: two;
                margin-right: -14px;

                @media(min-width: $breakpoint) {
                    grid-area: auto;
                    margin-right: 0;
                }

                .search-open {
                    margin-right: 2px;
                    opacity: 0.6;
                    transition: $duration $easing;
                    cursor: pointer;

                    &:hover {
                        opacity: 1;
                    }

                    svg {
                        width: 24px;
                        height: 24px;
                        margin-top: 4px;

                        @media(min-width: 1024px) {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }

                .icon {
                    opacity: 1;
                }
            }
        }
    }

    .search {
        margin-bottom: 0;

        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: rgba(0, 0, 0, .7);
        }

        ::-moz-placeholder { /* Firefox 19+ */
            color: rgba(0, 0, 0, .7);
        }

        :-ms-input-placeholder { /* IE 10+ */
            color: rgba(0, 0, 0, .7);
        }

        :-moz-placeholder { /* Firefox 18- */
            color: rgba(0, 0, 0, .7);
        }

        input[type="text"] {
            @include font-size(16);
            height: 30px;
            letter-spacing: 0.6px;
            display: inline-block;
            border: none;
            outline: none;
            padding: 3px;
            background: none;
            z-index: 3;
            cursor: pointer;
            flex-grow: 2;
            width: 100%;

            @media(min-width: 1024px) {
                @include font-size(12);
            }
        }


        input[type="submit"] {
            height: 20px;
            width: 20px;
            display: inline-block;
            margin-right: 24px;
            background: url(../../img/icon-search-black.svg) center center no-repeat;
            text-indent: -10000px;
            border: none;
            outline: none;
            z-index: 2;
            cursor: pointer;
        }
    }
}

nav {

    ul, li {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    a {
        display: block;
        text-decoration: none;

        &:hover, &:visited {
            text-decoration: none;
        }
    }
}

.menu-bar {
  display: flex;
}

.menu-link {
  @include font-size(11, 16);
  padding: $menu-link-padding;
  color: $top-nav-color;
  transition: background .2s, color .2s;
  position: relative;
  z-index: 1;
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 1px;

  .icon {
    display: none;
  }

  @media(min-width: $breakpoint) {
    height: 100%;
  }

  &.mega-menu-link {
    color: $sub-nav-color;
  }
}

.mega-menu-header {
  font-size: 1.2em;
  text-transform: uppercase;
  font-weight: bold; 
}

// ------------------ MEDIA QUERIES

@media all and (min-width: $breakpoint + 1px) {
  // Desktop only
  
  .nav {
    > nav {
      max-width: $max-content-width;
      margin: 0 auto;
      display: flex;
      justify-content: center;
    }
  }
  
  .menu {
    > li {
      > [aria-haspopup="true"] {
        ~ ul {
          display: none;
        }
      }
    }
  }
  
  .menu-bar {
    > li {
     > [aria-haspopup="true"] {
        // STYLING: Down arrow on desktop
        span {
          display: flex;
        }

        .icon {
          display: block;
          width: 13px;
          height: 13px;
          margin-left: 6px;
        }
        
        &:before {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          border-width: 0 8.5px 7px 8.5px;
          border-color: transparent transparent #2c2c2c transparent;
          border-style: solid;
          bottom: 0px;
          right: 9px;
          transform: translateX(-50%);
          transition: none;
          opacity: 0;
          z-index: 1;
        }

        // FUNCTIONALITY: Open mega menu
        &:hover {
          &:before {
            opacity: 1;
            transition: opacity .2s;
          }

          ~ ul {            
            display: flex;
            transform-origin: top;
            animation: dropdown .2s ease-out;
          }
        }

        // FUNCTIONALITY: Keep mega menu open
        ~ ul {
          &:hover {
            display: flex;
          }
        }
      }
      
      // FUNCTIONALITY: Keep mega menu open
      &:focus-within {

        > [aria-haspopup="true"] {

          ~ ul {            
            display: flex;
          }
        }
      }
    
      &:hover > [aria-haspopup="true"]:before {
        opacity: 1;
        transition: opacity .2s;
      }
    }

  }
  
  .mega-menu {
    // LAYOUT: Mega menu
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 790px;
    background: $dropdown-bg;
    padding: 34px 40px;
    z-index: 10;
   
    // FUNCTIONALITY: Keep mega menu open
    &:hover {
      display: flex;
    }

    a {
      &:hover {
        background: $sub-nav-hover;
        color: $sub-nav-hover-color;
      }
    }
  }
  
  // STYLING: Flat mega menu columns
  .mega-menu--flat {
    > * {
      flex: 1;
    }
  }
  
  .menu-list {
    display: flex;
  }

  //STYLING: menu columns
  .menu-column {
    flex: 1 1 29%;

    + .menu-column {
      margin-left: 20px;
    }

    a {
      margin: 0;
      transition: opacity $duration $easing;
    }

    &-image {
      flex: 0 1 42%;
      margin-left: 50px;

      a:hover {
        background: none;
      }

      img {
        max-width: 100%;
      }
    }

    .menu-column-title {
      @include font-size(18, 24);
      color: $white;
      margin-bottom: 10px;

      &:hover {
        background-color: transparent;
      }
    }

    .menu-column-highlight {
      @include font-size(12, 28);
      letter-spacing: 1px;
      color: $white;
      text-transform: uppercase;
      margin-top: 8px;
      transition: opacity $duration $easing;

      &:hover {
          background: none;
          opacity: 0.7;
      }
    }
  
    .menu-list-link {
      @include font-size(12, 24);
      color: $sub-nav-color;
      padding: $sub-menu-link-padding;
      margin: 0;
      text-transform: initial;
      letter-spacing: 0.6px;

      &:hover {
        background: none;
        opacity: 0.7;
      }
    }
  }

  // Hide mobile specific elements
  .mobile-menu-trigger, .mobile-menu-header, .mobile-menu-back-item {
    display: none;
  }

}

@media all and (max-width: $breakpoint) {
    
  .nav {
    padding: 20px;
  }
  
  .mobile-menu-trigger, .mobile-menu-header, .mobile-menu-back-item {
    display: block;
  }
  
  .mobile-menu-trigger {
    border: 0;
  }
  
  .mobile-menu-header {
    order: -1;
    background: grey;

    a {
      padding: $menu-link-padding;
      color: #FFF;
      visibility: visible;
    }

  }
  
  .menu-bar {
    flex-direction: column;
    position: fixed;
    top: 0;
    left: -100%;
    height: 100vh;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    transition: left .3s;

    > li {
        position: relative;

      > [aria-haspopup="true"] {
        ~ ul {
          display: flex;
          flex-direction: column;         
          position: relative;         
          width: 100%;
          background: $mobile-sub-bg;
          max-height: 0;
          overflow: hidden;
          transition: $duration $easing;

          &.active {
             max-height: 100vh;
          }

          // Second level
          > li {
            > ul {
              > li {
                position: relative;
                background: $mobile-sub-bg;

                > ul{
                  display: flex;
                  flex-direction: column;         
                  position: relative;         
                  width: 100%;
                  max-height: 0;
                  overflow: hidden;
                  transition: $duration $easing;

                  &.active {
                     max-height: 100vh;
                  }
                } 
              }
            }
          }
        }
      }
    }
  }
 
  .mega-menu-content {
    padding: $menu-link-padding;
  }
  
  .mobile-menu-back-item {
    order: -1;

    a {
      background: tint(grey, 70%);
     
      max-height: $mobile-menu-back-height;
      margin-top: $mobile-menu-back-offset;
      pointer-events: none;

      &:before {
        content: "";
        width: 14px;
        height: 12px;
        // background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1397521/arrowLeft.svg#default');
        background-size: 14px;
        margin-right: 10px;
        display: inline-block;
      }
    }
  }
  
  // ------------------------ ALL DEVICES
  
  .mobile-menu-trigger {
    position: absolute;
    top: 52px;
    left: 24px;
    z-index: 1;

    // FUNCTIONALITY: Open mobile menu
    &.active {
      ~ ul {
        left: 0;
      }
    }
  }
  
  .menu-bar {
    top: 0;
    padding-top: 96px;
    background-color: $mobile-nav-bg;

    // FUNCTIONALITY: Keep menu open
    .menu-link {
      color: white;
      padding: 24px 32px;

      span {
        opacity: 1;
      }

      &[aria-haspopup="true"] {
        margin-right: 80px;
      }

      &:hover,
      &:focus {
        span {
          opacity: .7;
        }
      }
    }

    .menu-column-image {
      display: none;
    }

    .menu-column-title {
      @include font-size(11, 16);
      text-transform: uppercase;
      color: $white;
      padding: 24px 32px;
      margin-bottom: 0;
      transition: $easing $duration opacity;

      &:is(a):hover {
          opacity: 0.7;
      }

      &[aria-haspopup="true"] {
        margin-right: 80px;
      }
    }

    .menu-list-link {
      @include font-size(12);
      text-transform: none;
      padding: 12px 32px 12px 52px;
      opacity: 0.7;

      &:hover,
      &:focus {
        opacity: 1;
      }
    }

    .menu-column-highlight {
      @include font-size(12);
      color: $white;
      padding: 12px 32px;
      margin-bottom: 0;
      transition: $easing $duration opacity;

      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }

    .show-child {
      color: white;
      position: absolute;
      right: 24px;
      top: 24px;

        .plus-icon {  
          width: 20px;
          height: 20px;
          position: relative;

          &:before,
          &:after {
            content: "";
            position: absolute;
            background-color: white;
            transition: transform $duration $easing;
          }

          &:before {
            top: 0;
            left: 50%;
            width: 1px;
            height: 100%;
            margin-left: 0;
          }

          &:after {
            top: 50%;
            left: 0;
            width: 100%;
            height: 1px;
            margin-top: 0;
          }
        } 

        &.active {

          
          .plus-icon {
              &:before { transform: rotate(90deg); }
              &:after { transform: rotate(180deg); }
            }
        }
    }

    > li {

      > [aria-haspopup="true"] {
        // FUNCTIONALITY: Open mega menu
        &:focus {

          ~ ul {
            left: 0;
          }
        }
        ~ ul {
          // STYLING: Back button offset
         
      
          // FUNCTIONALITY: Keep mega menu open
          &:hover, &:focus-within {
            left: 0;
          }

          // FUNCTIONALITY: Keep dropdowns open
          li {

            &:focus-within {

              > [aria-haspopup="true"] {

                ~ ul {
                  max-height: 500px;
                  transform: scaleY(1);
                }
              }
            }
          }
          
        }
      }
      // FUNCTIONALITY: Prevent clicks on link behind back button
      &:focus-within ~ .mobile-menu-header a {
        visibility: hidden;
      }
    }
  }
  
  // ------------------------ TOUCH DEVICES
  
  @media (hover: none) {
    
    // FUNCTIONALITY: Open mobile menu 
    .mobile-menu-trigger {

      &.active {

        ~ ul {
          left: 0;
        }
      }
    }
    
    // FUNCTIONALITY: Open mega menu
    .menu-bar {

      > li {

        > [aria-haspopup="true"] {

          &:hover {

            ~ ul {
              left: 0;
            }
          }
          ~ ul {

            &:hover {
              left: 0;
            }
            // FUNCTIONALITY: Open dropdowns
            [aria-haspopup="true"] {

              &:hover {

                ~ ul {
                  max-height: 500px;
                  animation: dropdown .3s forwards;
                }
              }

              ~ ul {

                &:hover {
                  max-height: 500px;
                  transform: scaleY(1);
                }
              }
            }
          }
        }

        &:hover ~ .mobile-menu-header {

          a {
            visibility: hidden;
          }
        }
      }
    }
  }
}


// ------------------ ANIMATIONS

@keyframes dropdown {
  0% {
    opacity: 0;
    transform: scaleY(0) translateX(-50%);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scaleY(1) translateX(-50%);
  }
}

@keyframes flyout {
  0% {
    opacity: 0;
    transform: scaleX(0);
  }
  100% {
    opacity: 1;
    transform: scaleX(1);
  }
}


.menu-icon-wrapper {
                width: 25px;
                height: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                z-index: 2;

                .menu-icon {
                    position: relative;
                     opacity: 0.6;

                    &::before, &::after {
                        position: absolute;
                        left: 0;
                        content: '';
                        display: block;
                        transition: transform 0.2s ease-in, top 0.2s linear 0.2s;
                    }

                    &::before {
                        top: -4px;
                    }

                    &::after {
                        top: 4px;
                    }
                }
              .menu-icon {
                    width: 25px;
                   
                }
                 .menu-icon::before, .menu-icon::after {
                    width: 24px;
                    height: 1px;
                    background-color: white;
                }

                .menu-icon-active {
                    background-color: transparent;

                    &::before, &::after {
                        top: 0;
                        transition: top 0.2s linear, transform 0.2s ease-in 0.2s;
                        background-color: #FFF;
                    }

                    &::before {
                        transform: rotate(45deg);
                    }

                    &::after {
                        transform: rotate(-45deg);
                    }
                }
            }



.main-nav.dark, .videogallery .main-nav {
  
  .nav {

    .menu-link {
      color: white;
      transition: $duration $easing;

      span {
          transition: opacity $duration $easing;
      }

      &:hover {
        span {
          opacity: .7;
        }
      }
    }
  }
}

.videogallery header {

  .main-logo {
        color: white;        
    }
  
}

body {

    &:before {
        content: '';
        background-color: rgba(0,0,0,.5);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        transition: 1s ease;
    }

    &.overlay-open {
        overflow: hidden;

        &::before {
            opacity: 1;
            transition: 1s ease;
            z-index: 3;
        }
    }
} 
