.tabs {
    width: 100%;

    [role="tablist"] {
        margin: 0 0 -0.1em;
        overflow: visible;
    }

    [role="tab"] {
        position: relative;
        margin: 0;
        padding: 15px $block-padding;
        border: 1px solid $border;
        overflow: visible;
        background-color: #f2f2f2;
    }

    [role="tab"][aria-selected="true"] {
        border-radius: 0;
        background: $black;
        color: $white;
        outline: 0;
    }

    [role="tab"][aria-selected="false"] {
        &:before {
            border-color: #f2f2f2 transparent transparent transparent !important;
        }
    }

    [role="tab"]:hover,
    [role="tab"]:focus,
    [role="tab"]:active {
        outline: 0;
        border-radius: 0;
    }

    [role="tabpanel"]:focus {
        outline: 0;
    }

    [role="tabpanel"]:focus::after {
        position: absolute;
        bottom: 0;
        right: -1px;
        left: -1px;
        content: '';
    }

    [role="tabpanel"] p {
        margin: 0;
    }

    [role="tabpanel"] * + p {
        margin-top: 1em;
    }
}