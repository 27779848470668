.pagination-container {

  padding: 0;

  .pagination {
    margin: $spacing 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  
    .button {
      padding: 15px 24px 13px 32px;
      margin: 0 60px 0 0;
      height: 32px;

      &:before {
        height: 32px;
      }
  
      &.pag-prev {
        order: 2;
      }
  
      &.pag-next {
            transform: rotate(180deg);
            margin: 0 0 0 60px;
  
            order: 3;
  
            &:after {
              margin-top: -1px;
            }
      }
    }
  
    .pag-numbers {
      display: flex;
      align-items: center;
      
      justify-content: center;
      width: 100%;
  
      span {
        margin: 0 5px;
        font-weight: 700;
      }
     
  
      .pag-number {
        
        width: 55px;
        height: 55px;
        margin: 0 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $text;
        transition: $duration $easing;
  
  
        &:hover,
        &.active {
       
          text-decoration: none;
        }
  
        &.active {
            font-family: 'GT-America-Expanded-Medium';
        }
      }
      
    }
  
    @media (min-width: $max-tablet ) {	

      flex-wrap: nowrap;

      .button {
        height: 47px;

        &.pag-prev {
          order: 1;
        }
    
        &.pag-next {
              order: 3;
        }

        &:before {
          height: 47px;
        }
      }

      .pag-numbers {
        width: auto;
        order: 2;
      }
    }
  }
}


.videogallery {

  .pagination {
 

  .button {

    background-color: $dark-theme-bg;
    color:  #A4A4A4;
    border: 1px solid #A4A4A4;


    span {
      color:  #A4A4A4;
    }
    
    &:before {
      background-color: $dark-theme-bg;
      border-color:#A4A4A4;
      border-left-color: transparent;
      
    }

    .hover {
      &:before {
        background-color: white;
      }
    }

    &:hover {
      color: black;
         span {
          color: black;
         }
    }
  }

  .pag-numbers {
   

    .pag-number {      
     
      color:  #A4A4A4;  

      &:hover,
      &.active {
         color: white;
       
      }

      
    }
    
  }
}

}