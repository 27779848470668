﻿.contact-sales {
    background-color: $background-black;
    position: relative;
    padding: 160px 0 60px;

     &:after {
        background-color: $black-accent;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 70%;
        height: 60%;
        content: '';
        clip-path: polygon(100% 100%, 0 100%, 100% 0);
        z-index: 1;
    }

    &__img { 
        width: 60%;
        height: 175px;
        border-bottom-right-radius: $curve-mob;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &__text {
        z-index: 2;
        position: relative;
        text-align: center;
        padding: 40px 64px;
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
            color: $white;
           
        }

        p {
            color: $white;           
            margin-bottom: 30px;
            opacity: .7;
        }

        .button {
            align-self: center;
            margin-left: -48px;
        }
    }

    @media(min-width: $max-tablet) {
        padding: 64px !important;
        display: flex;
        justify-content: space-around;


        &:after {
            clip-path: polygon(54% 0, 100% 0, 100% 100%, 0 100%);
            height: 100%;
            width: 85%;
        } 


        &__img {
            position: relative;
            max-width: 400px;
            height: 266px;
            border-bottom-right-radius: $curve-desktop;            
            z-index: 2;
            
        }

        &__text {
            max-width: 470px;
            justify-content: center;
            padding: 0 40px;
        }
    }
}