.content-listing {

    h2 {
        text-align: center;
    }

    &-wrapper {
        display: flex;
        flex-direction: column;
    }

    &-item {
        display: flex;
        flex-direction: column;
        background-color: $pale-grey;
        margin-bottom: 30px;
    }

    &-container {
        position: relative;
        height: 243px;
        overflow: hidden;
    }

    &-image {
        display: block;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba( 0, 0, 0, .3 );
            z-index: 1;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        z-index: 2;

        a {
            color: $white;

            &.small {
                text-transform: uppercase;
                letter-spacing: 1.76px;
                @include font-size(11);
            }
        }
    }

    &-links {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        text-align: center;
        padding: $spacing;

        .title {
            @include font-size(14, 24);
            color: $black;
            margin-bottom: 8px;
        }

        ul {
            flex-grow: 1;
            list-style-type: none;
            padding: 0;
            margin: 0 0 10px 0;
        }

        li {
            padding: 0;

            a {
                @include font-size(12);
                display: inline-block;
                padding: 6px 0;
                margin: 0;
                opacity: .6;
            }
        }

        .btn-text {
            @include font-size(11);
            text-transform: uppercase;
            letter-spacing: 1.76px;
            opacity: .7;
            margin: 0;
        }
    }
}



@media (min-width: $max-tablet) {
    .content-listing {
        &-wrapper {
            flex-direction: row;
        }

        &-item {
            flex: 1;
            margin-left: 12px;
            margin-bottom: 0;

            &:first-of-type {
                margin-left: 0;
                margin-right: 12px;
            }
        }

        &-container {
            height: 382px;
        }
    }
}
