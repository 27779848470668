﻿.image-caption-container {
    display: flex;
    justify-content: center;

    .media-wrapper {
        padding: 0px;
        text-align: center;
        margin: 30px 0;
        max-width: 100%;
        flex-grow: 1;

        .lightbox-youtube {
            position: relative;
            display: block;

            &-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 25%;
                    max-width: 136px;
                    border-radius: 0 0 4rem 0;
                }
            }
        }

        .image-caption-img {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
            height: 137px;

            @media (min-width: $max-tablet) {
                height: 268px;
            }

            @media (min-width: $small-desktop) {
                height: 440px;
            }
        }

        .image-caption {
            color: $caption-txt-grey;
            padding-top: 1.5rem;
            justify-content: center;

            &:before {
                opacity: .5;
            }
        }


        &:hover {
            .image-caption-zoom {
                opacity: 1;
                transition: opacity 0.2s ease-out;
            }
        }
    }
}

.caption-wrapper {
    display: flex;
    align-items: center;
    width: auto;

    &.video-caption {
        position: initial;
        padding: 0;
    }

    .caption {
        margin-bottom: 0;
        font-style: italic;
        @include font-size(12);
    }

    .caption-line {
        display: none;
    }
}
