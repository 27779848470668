﻿.page-size-filter {
    display: flex;
    align-items: center;

    label {
        @include font-size(12,2);
        margin-right: 8px;

        @media (min-width: $max-tablet ) {
            @include font-size(14,1.14);
            margin-right: $grid-spacing;
        }
    }

    .bv_mainselect {
        min-width: 70px;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        display: flex;
        align-items: center;
        padding: $button-padding;
        border: 1px solid $black;
        border-right-color: transparent;
        text-decoration: none;
        letter-spacing: 1.6px;
        position: relative;
        outline: none;
        text-transform: uppercase;
        align-self: flex-start;
        cursor: pointer;
        height: 32px;
        color: black;
        background: transparent;
        margin-right: 45px;
        z-index: 9; 

        @media (min-width: $max-tablet ) {
            min-width: 122px;
            height: 47px;
            margin-right: 52px;
        }

        &:before {
            content: '';
            width: 48px;
            height: 38px;
            border: 0;
            border-top: 1px solid black;
            border-right: 1px solid black;
            border-bottom: 1px solid black;
            transform: skewX(-45deg);
            position: absolute;
            right: -20px;
            top: -1px;
            background-color: transparent;

            @media (min-width: $max-tablet ) {
                height: 47px;
                right: -25px;
            }
        }

        .bv_atual {
            @include font-size(10,1.6);
            padding: 15px 0 15px 0;
            border-bottom: none;
            letter-spacing: 1.6px;

            .arrow {
                right: -14px;
                z-index: 99;

                &.down {
                    margin-top: -5px;
                }

                &.up {
                    margin-top: -1px;
                }

                @media (min-width: $max-tablet ) {
                    right: 17px;
                }
            }
        }

        .bv_ul_inner {
            top: 37px;
            width: 74px;
            min-width: 74px;
            left: 8px;

            @media (min-width: $max-tablet ) {
                top: 46px;
                width: 100px;
                min-width: 100px;
                left: 19px;
            }
        }
    }
}
