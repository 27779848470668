
.faqSection {
    &.container {
        padding: 0;

        @media (min-width: $max-tablet) {
            padding: 0 $horizontal-space;
        }

        h2 {
            padding: 0 $horizontal-space;
            margin-bottom: 3rem;

            @media (min-width: $max-tablet) {
                padding: 0;
            }
        }


        .faqs {

            .accordion,
            .accordion-multi {
                background: white;
                cursor: pointer;
                padding: 24px $horizontal-space;
                width: 100%;
                transition: $duration $easing;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 1px solid $border;
                font-family: 'GT-America-Expanded-Medium';
                @include font-size(12,20);
                letter-spacing: .6px;
                border-bottom: none;
                border-left: none;
                border-right: none;

                .plus-icon {
                    width: 20px;
                    height: 20px;
                    position: relative;

                    &:before,
                    &:after {
                        content: "";
                        position: absolute;
                        background-color: $black;
                        transition: transform $duration $easing;
                    }

                    &:before {
                        top: 0;
                        left: 50%;
                        width: 1px;
                        height: 100%;
                        margin-left: -1px;
                    }

                    &:after {
                        top: 50%;
                        left: 0;
                        width: 100%;
                        height: 1px;
                        margin-top: -1px;
                    }
                }

                .arrow-icon {
                    height: 20px;
                    width: 35px;
                    display: block;
                    position: relative;
                    cursor: pointer;


                    .left-bar,
                    .right-bar {
                        position: absolute;
                        background-color: transparent;
                        top: 50%;
                        width: 20px;
                        height: 5px;
                        display: block;
                        border-radius: 2px;

                        &:after {
                            content: "";
                            background-color: $pale-grey;
                            width: 20px;
                            height: 5px;
                            display: block;
                            transition: all $duration $easing;
                            z-index: -1;
                        }
                    }

                    .left-bar {
                        left: 0;
                        transform: translateY(-50%) rotate(35deg);

                        &:after {
                            border-radius: 6px 10px 10px 6px;
                        }
                    }

                    .right-bar {
                        left: 15px;
                        transform: translateY(-50%) rotate(-35deg);

                        &:after {
                            border-radius: 10px 6px 6px 10px;
                        }
                    }
                }

                &.active, &:hover {
                    background-color: $pale-grey;


                    .plus-icon {
                        &:before,
                        &:after {
                            background-color: $black;
                        }
                    }

                    .arrow-icon {
                        .left-bar,
                        .right-bar {

                            &:after {
                                background-color: $black;
                            }
                        }
                    }
                }

                &.active {
                    border-bottom: 1px solid $border;

                    .plus-icon {
                        &:before {
                            transform: rotate(90deg);
                        }

                        &:after {
                            transform: rotate(180deg);
                        }
                    }

                    .arrow-icon {

                        .left-bar:after {
                            transform-origin: center center;
                            transform: rotate(-70deg);
                        }

                        .right-bar:after {
                            transform-origin: center center;
                            transform: rotate(70deg);
                        }
                    }
                }
            }


            .panel {
                max-height: 0;
                overflow: hidden;
                transition: $duration $easing;

                &.active {
                    max-height: 600px;
                }

                .wrapper {
                    padding: 2.5rem;


                    @media (min-width: $max-tablet) {
                        width: 74%;
                    }
                }

                p:last-child {
                    margin: 0;
                }
            }
        }

        & div:last-of-type .accordion,
        & div:last-of-type .accordion-multi {
            border-bottom: 1px solid $border;
        }
    }
}
