.listing {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -30px;

    @media (min-width: $max-tablet ) {
        margin: 0;
    }

    .listing-item {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .img {
            width: 100%;
            height: 220px;
            overflow: hidden;

            .img-holder {
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                width: 100%;
                height: 100%;
                transition: $duration $easing;
                background-color: $border;
            }

            &:hover {

                .img-holder {
                    transform: scale(1.1);
                }
            }
        }

        .content {
            padding: $block-padding;
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            .date {
                @include font-size(12);
                text-transform: uppercase;
                color: $text-light;
                margin-bottom: 10px;
            }

            .title {
                @include font-size(24);
                margin-bottom: $spacing-small;

                a {
                    text-decoration: none;
                    color: inherit;
                    transition: $duration $easing;

                    &:hover {
                    }
                }

                @media (min-width: $max-tablet ) {
                    @include font-size(32);
                }
            }

            .author {
                @include font-size(14);
            }

            .button-wrapper {
                display: flex;
                justify-content: center;

                .view-product {
                    margin-left: -48px;
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }

            .button {
                margin-top: $spacing;
            }

        }
    }



    &.grid {

        &.contact {

           .content {
            padding-bottom: 0 !important;
           }

            a {
                font-family: 'GT-America-Expanded-Medium';
            }

            p:last-child {
                margin-bottom: 0;
            }
        }


        @media (min-width: $max-tablet ) {
            .listing-item {
                max-width: 50%;               
                margin-bottom: $spacing;

                &:nth-child(even) {
                    padding: 0 0 0 $grid-spacing;

                    &.product {
                      padding: 0;
                    }
                }

                .content {
                    padding: $block-padding 0;

                    .button {
                        align-self: start;
                    }
                }
            }

            &.three {
                .listing-item {
                    max-width: 33.333333%;
                   


                    &:nth-child(3n) {
                        
                    }

                    &:nth-child(3n+1) {
                        
                    }
                }
            }
        }

        @media (min-width: $small-desktop ) {

            &.four {
                .listing-item {
                    max-width: 25%;
                    

                    &:nth-child(4n+1) {
                       
                    }

                    &:nth-child(4n) {
                        
                    }
                }
            }
        }

        
    }


    &:not(.grid) {

        .listing-item {
            @media (min-width: $max-tablet ) {
                margin: $spacing 0;

                .img {
                    width: 50%;
                    order: 2;
                    height: 100%;
                }

                .content {
                    order: 1;
                    width: 50%;

                    .button {
                        align-self: start;
                    }
                }
            }
        }

        &.alternate {

            .listing-item {

                .container {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    padding: 0;
                    align-items: center;
                }

                padding: 64px 32px;
                margin: 0;
                justify-content: space-between;

                &.image-text-block {
                    padding: $block-padding 0;

                    &:before {
                        display: none;
                    }

                    .img {
                        max-width: 100%;
                        @media (min-width: $max-tablet) {
                            max-width: 455px;

                        }
                    }

                    .img-block-content {
                         max-width: 100%;

                         @media (min-width: $max-tablet) {
                            max-width: 520px;

                        }
                    }
                }

                .content {
                    
                    order: 2;
                    padding: $block-padding 0 0;

                    @media (min-width: $max-tablet ) {
                        max-width: 520px;
                    }

                    small {
                        @include font-size(12,24);
                        color: white;
                        opacity: .5;
                        letter-spacing: .6px;
                    }

                    .title {
                        @include font-size(24);
                    }

                    .button {
                        align-self: flex-start;
                        margin: 24px 0 0;
                    }
                }

                .img {
                    //max-width: 455px;
                    order: 1;
                    height: 137px;

                    @media (min-width: 569px ) {
                        height: 207px;
                    }

                    @media (min-width: $max-tablet ) {
                       max-height: 315px;
                       max-width: 455px;
                    }
                        

                    &.img-text-block {
                        height: 216px;

                        @media (min-width: $max-tablet ) {
                            height: 247px;
                            padding-top: 24px;
                        }

                        @media (min-width: $small-desktop ) {
                            height: 223px;
                            padding-top: 0;
                        }
                    }

                    &.curve {
                        border-bottom-left-radius: $curve-mob;
                    }
                }

                &:nth-child(even) {

                    .img {

                        &.curve {
                            border-bottom-left-radius: 0;
                            border-bottom-right-radius: $curve-mob;
                        }
                    }
                }



                @media (min-width: $max-tablet ) {
                    padding: 64px;

                    &.image-text-block {
                        padding: $block-padding 0;
                    }

                    .img {
                        width: 48%;
                        order: 2;
                        height: 315px;

                        &.curve {
                            border-bottom-left-radius: $curve-desktop;
                        }
                    }


                    .content {
                        padding: $block-padding 64px $block-padding 0;
                        width: 50%;
                        margin-right: 2%;
                        order: 1;

                        &.img-block-content {
                            padding: $block-padding $block-padding $block-padding 0;
                        }
                    }

                    &:nth-child(even) {

                        .img {
                            order: 1;

                            &.curve {
                                border-bottom-left-radius: 0;
                                border-bottom-right-radius: $curve-desktop;
                            }
                        }

                        .content {
                            margin: 0 0 0 2%;
                            order: 2;
                            padding: $block-padding 0 $block-padding 64px;
                        }
                    }
                }

                @media (min-width: $small-desktop ) {
                    .content {
                        padding: $block-padding $block-padding $block-padding 40px;

                        &.img-block-content {
                            padding: 0 $block-padding $block-padding 0;
                        }
                    }

                    &:nth-child(even) {
                        .content {
                            padding: $block-padding 0 $block-padding 40px;
                        }
                    }
                }
            }

            &.dark {
                width: 100%;
                max-width: 1300px;
                margin: 0 auto;



                .listing-item {
                    background-color: $background-black;
                    color: white;

                    &:nth-child(even) {
                        background-color: $dark-grey;
                    }

                    p {
                        color: white;
                        opacity: .7;
                        margin-bottom: 40px;
                    }
                }
            }

            &.light {
                width: 100%;
                max-width: 1300px;
                margin: 0 auto;

                .listing-item {
                    position: relative;

                    &:first-child {
                        &:before {
                            content: '';
                            height: 1px;
                            background-color: $border;
                            position: absolute;
                            top: 0;
                            left: 32px;
                            right: 32px;

                            @media (min-width: $max-tablet ) {
                                left: 64px;
                                right: 64px;
                            }
                        }

                    }
                }

                .listing-item:after {
                    content: '';
                    height: 1px;
                    background-color: $border;
                    position: absolute;
                    bottom: 0;
                    left: 32px;
                    right: 32px;

                    @media (min-width: $max-tablet ) {
                        left: 64px;
                        right: 64px;
                    }
                }

                
            }
        }
    }
}
