﻿table {
    text-align: center;
    width: 100%;
    display: block;
    overflow-x: auto;
    padding-bottom: 2.5rem;

    &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }

    &::-webkit-scrollbar-track {
        background: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background: #000;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #000;
    }

    @media (min-width: $max-tablet) {
        overflow-x: hidden;
        padding-bottom: 0;
    }


    th, td {
        border: 1px solid $border;
        padding: 1.75rem 0;
        @include font-size(12,24);
        letter-spacing: 0.6px;
        color: $content-txt;
        min-width: 350px;

        @media (min-width: $max-tablet) {
            min-width: auto;
        }
    }

    th {
        color: $black;
    }
}
