/* convert to em from px */
@function em($px, $base: $base-font-size) {
  @return ($px / $base) * 1em;
}

/* convert to rem from px */
@function calculateRem($px, $base: 12) {
  $remSize: $px / $base;
  @return #{$remSize}rem;
}

/* unit conversion used by font size */
@function meta-unit($number, $unit) {

  @if $unit == "px" {
    @return $number+px;
  }
  @if $unit == "%" {
    @return $number+"%";
  }
  @if $unit == "em" {
    @return em($number)+px;
  }
  @if $unit == "rem" {
    @return calculateRem($number);
  }
}

@mixin font-size($size, $line-height: ($size + 8)) {
  font-size: $size + px;
  font-size: meta-unit($size,rem);
  line-height: meta-unit($line-height,px);
  line-height: meta-unit($line-height,rem);
}

@mixin vertical-gradient($start,$end) {
  background: $end;
  background: -webkit-linear-gradient($start,$end);
  background: linear-gradient($start,$end);
}

@mixin hide-text() {
  text-indent: 110%;
  white-space: nowrap;
  overflow: hidden;
}

/* Slightly lighten a color
 * @access public
 * @param {Color} $color - color to tint
 * @param {Number} $percentage - percentage of `$color` in returned color
 * @return {Color}
 */
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/* Slightly darken a color
 * @access public
 * @param {Color} $color - color to shade
 * @param {Number} $percentage - percentage of `$color` in returned color
 * @return {Color}
 */
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}