html,
body {
  min-height: 100%;
   font-size: 12px;
}

body {
    position: relative;
    width: 100%;
    font-family: 'GT-America-Extended-Light';
    font-weight: 400;
    font-style: normal;
  
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.svg-sprite {
  display: none;
}


b,
strong {
    font-family: 'GT-America-Expanded-Medium';
}

// Hide SVG Sprite
.svg-sprite {
  display: none;
}

a {
  text-decoration: none;
  color: $black;

  &:hover {
    text-decoration: none;
  }

  &.link-w-icon {
    display: flex;
    align-items: center;

    span {
      margin-right: 8px;
    }
  }
}

a:active,
a:hover,
button {
  outline: 0;
}
img {
  border: 0;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}


section {
  margin: $vertical-space-mob auto;
  

  @media(min-width: $max-tablet) {
    margin: $vertical-space auto;
  }

  &.fadeUp {
    visibility: hidden; 
  }

  &.no-top {
    margin-top: 0;
  }
  
  &.no-bottom {
    margin-bottom: 0;
  }
}

.container {
  width: 100%;
  max-width: $max-content-width;
  margin: 0 auto; 
  padding: 0 $horizontal-space-mob;
  position: relative;

  &.max-1300 {
    max-width: 1300px;
    padding: 0;
  }
}

.border-top {
  border-top: 1px solid $border;
  padding-top: 60px;
}

.parallax {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

svg {
  &.icon {

    width: 16px;
    height: 16px;

    &.white {
      fill: $white;
    }
  
    &.black {
      fill: $black;
    }
  }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}