.listing-header {
	display: flex;
	justify-content: space-between;
    align-items: center;
    padding:32px 0;

	.sub-heading {
		display: none;
		margin-bottom: 0;

		@media (min-width: $max-tablet ) {
            display: flex;
        }
	}

	.filter-btn {
		@include font-size(10,16);
		text-transform: uppercase;
		letter-spacing: 1.6px;
		color: black;
		display: flex;
		align-items: center;
		padding: 8px 16px;
		cursor: pointer;

		svg {
			margin-left: 30px;
		}

		@media (min-width: $max-tablet ) {
            border: 1px solid black;
            padding: 16px 32px;

            &:hover {
        		color: white;
        		background: black;
        	}
        }


	}

}

.listing.product-list {
	// justify-content: space-between;
	margin: 0;

	.product {
		margin: 12px 10px;
		max-width: 100%;

		@media (min-width: 580px ) {
			max-width: calc(50% - 20px);
		}

		a {
			width: 100%;
			margin-bottom: 0;
		}

		.img {
			border: 1px solid $lighter-grey;
			width: 100%;
			// height: 200px;
			height: 320px;

			.img-holder {
				background-size: contain;
				background-color: $white;
			}
		}

		.content {
			padding: 24px 8px 48px;
			text-align: center;

			.prod-name {
				@include font-size(14,24);
				letter-spacing: .6px;
				color: black;
			}

			.category {
				color: black;
				opacity: .7;
				letter-spacing: .6px;
			}

			.price {
				@include font-size(18,24);
				letter-spacing: .8px;
				margin-top: $grid-spacing;

				span {
					@include font-size(12);
					letter-spacing: .6px;
				}
			}
		}
	}

	@media (min-width: $max-tablet ) {

		.product {
			border: 1px solid $lighter-grey;
			max-width: calc(33.333333% - 20px) !important;
			margin-left: 10px;
			margin-right: 10px;
			// &:nth-child(1n + 4), &:first-child {
			//   margin-left: 0px;
			// }
			&:last-child {
				margin-left: 10px;
			}

			&:nth-child(3n) {
				margin-right: 0px;
			}

			.img {
				border: 0;
			}
		}
	}

	@media (min-width: $small-desktop ) {

		.product {

			.img {
				height: 300px;
			}

			.content {
				.prod-name {
					@include font-size(18,24);
				}
			}
		}
	}
}

.no-results {

  h2 {
    margin-bottom: 16px;
  }
  .button {
    display: inline-flex;
  }
}
