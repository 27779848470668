.button:not(.text-link) {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    display: flex;
    align-items: center;
    padding: $button-padding;
    border: 1px solid $black;
    border-right-color: transparent;
    transition: color $duration $easing;
    text-decoration: none;
    //font-family: 'GT-America-Extended-Light';
    letter-spacing: 1.6px;
    position: relative;
    outline: none;
    text-transform: uppercase;
    align-self: flex-start;
    cursor: pointer;
    background: $white;
    height: 47px;

    .btn-text {
        @include font-size(11,11);
        color: $black;
        z-index: 2;
    }

    &:before {
        content: '';
        width: 48px;
        height: 47px;
        border: 0;
        border-top: 1px solid black;
        border-right: 1px solid black;
        border-bottom: 1px solid black;
        transform: skewX(-45deg);
        position: absolute;
        right: -25px;
        top: -1px;
        z-index: 1;
        background-color: $white;
    }

    &:after {
        content: '';
        width: 56px;
        height: 1px;
        background: $black;
        position: absolute;
        top: 50%;
        right: -52px;
        z-index: 2;
        /* mix-blend-mode: difference;*/
        filter: invert(50%);
    }

    div.hover {
        position: absolute;
        left: -1px;
        top: -1px;
        bottom: -1px;
        width: calc(100% + 51px);
        z-index: 1;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        overflow: hidden;
        transition: $duration $easing;

        &:before {
            content: '';
            background: $black;
            height: 100%;
            width: 0;
            position: absolute;
            left: -25px;
            top: 0;
            bottom: 0;
            right: 0;
            transition: $duration $easing;
            transform: skewX(-45deg);
            z-index: 1;
        }
    }

    &:hover {

        .btn-text {
            color: white;
        }

        div.hover {
            &:before {
                width: 100%;
            }
        }
    }

    &.back {

        svg {
            margin-right: 16px;
            z-index: 2;
        }
    }

    &.button-black {
        color: $black;
        background: $white;
        border: 1px solid $black;



        &:hover {
            background: $black;
            color: $white;

            &:before {
                background: $black;
            }
        }
    }

    &.white-solid {
        color: black;
        background: $white;
        border: 1px solid $white;

        &:before {
            border-color: $white;
            background: white;
        }

        &:after {
            background: $white;
            mix-blend-mode: difference;
            filter: none;
        }

        div.hover {

            &:before {
                background: $btn-hover-grey;
            }
        }

        &:hover {
            .btn-text {
                color: black;
            }
        }
    }

    &.light-grey {
        background: $btn-hover-grey;
        border: 1px $btn-hover-grey;

        &:before {
            background: $btn-hover-grey;
            border-color: $btn-hover-grey;
        }

        div.hover {


            &:before {
                background: #E0E0E0;
            }
        }

        &:hover {
            border-color: $btn-hover-grey;

            .btn-text {
                color: black;
            }
        }
    }

    &.grey-solid {
        color: white;
        background: $dark-grey-button;
        border: 1px solid $dark-grey-button;

        .btn-text {
            color: white;
        }

        &:before {
            border-right-color: $dark-grey-button;
            border-top-color: $dark-grey-button;
            background: $dark-grey-button;
        }

        &:after {
            background: #7C7C7C;
            mix-blend-mode: initial;
        }

        div.hover {

            &:before {
                background: $dark-grey-button-hover;
            }
        }

        &:hover {
            .btn-text {
                color: black;
            }
        }
    }

    &.btn-disabled {
        color: $black;
        background: $white;
        border: 1px solid $black;
        opacity: .3;

        .btn-text {
            color: $black;
        }

        div.hover {
            cursor: not-allowed;

            &::before {
                background: none;
            }
        }

        &:hover {
            cursor: not-allowed;

            .btn-text {
                color: none;
            }
        }
    }

    &.transparent {
        color: black;
        background: transparent;

        &:before {
            border-right-color: $black;
            border-top-color: $black;
            background: transparent;
        }

        &:after {
            background: $black;
            mix-blend-mode: difference;
        }

        div.hover {

            &:before {
                background: $black;
            }
        }

        &:hover {
            .btn-text {
                color: $white;
            }
        }
    }
}


.text-link {
    background: none;
    border: none;
    outline: none;
    letter-spacing: 0.6px;
}