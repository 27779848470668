.collection-carousel {
  display: flex;

  h2 {
    margin-bottom: 32px;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .carousel-toggle {
      display: flex;
      justify-content: center;

      &__toggle{
        cursor: pointer;
        //font-family: GT-America-Extended-Light;
        border: 0;

        .btn-text {
          position: relative;
          z-index: 3;
        }

        &:first-of-type {
          border-bottom-left-radius: 30px;
          border-top-left-radius: 30px;
          position: relative;
          padding: 17px 20px 16px 40px;

          @media (min-width: $max-tablet ) {	
            padding: 17px 30px 16px 50px;
          }
    
          &:before {
            content: '';
            position: absolute;
            height: 0;
            border-style: solid;
            border-width: 48px 45px 0 0;
            border-color: $black transparent transparent transparent;
            top: 0;
            right: -44px;
            z-index: 1;
          }
        }

        &:last-child {
          border-bottom-right-radius: 30px;
          border-top-right-radius: 30px;
          padding: 17px 40px 16px 50px;

          @media (min-width: $max-tablet ) {	
            padding: 17px 50px 16px 60px;
          }
    

          div.hover {
            bottom: 0;
            left: 0;
            width: 100%;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
    
            &:before {
                left: 23px;
            }
          }
        }

        div.hover {
          position: absolute;
          left: -1px;
          top: 0;
          bottom: -1px;
          width: calc(100% + 51px);
          z-index: 1;
          border-top-left-radius: 30px;
          border-bottom-left-radius: 30px;
          overflow: hidden;
          transition: $duration $easing;
  
          &:before {
            content: '';
            background: #E0E0E0;
            height: 100%;
            width: 0;
            position: absolute;
            left: -25px;
            top: 0;
            bottom: 0;
            right: 0;
            transition: $duration $easing;
            transform: skewX(-40deg);
            z-index: 1;
          }
        }

        &:hover {
          div.hover {
              &:before {
                  width: 100%;
              }
          }
        }
      }

      [role="tab"][aria-selected="true"] {
        cursor: auto;
        div.hover {
          &:before {
            background: transparent;
          }
        }
      }
    }
  }

  //used for inital load of hidden carousel items
  .hide-carousel {
    position: absolute;
    left: -9999px;
    width: 100%;
  }

  &__container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .button {
      align-self: center;
      margin-left: -47px;
    }

    &--slider {
      display: flex;
      align-items: center;
      margin: 30px 0;
      justify-content: center;

      .slick-arrow {
        border-radius: 50px;
        height: 50px;
        min-width: 50px;
        border: none;
        background-color: $light-grey;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: $duration $easing;

        &:hover {
          background-color: $black;
          color: white;

          .icon {
            fill: $white;
          }
        }
      }

      .slide {
        // display: flex !important;
        // align-items: center;
        // flex-direction: column;
        // padding: 30px 40px;
        // margin: 10px;
        // border: 1px solid $lighter-grey;
        // width: auto !important;
        
        .collection-container {
          
          display: flex;
          justify-content: center;

          .collection-item {
            // padding: 30px 40px;
            margin: 30px 10px;
            border: 1px solid $lighter-grey;
            max-width: 360px;
            width: 100%;
  
            a {
              .img {
                width: 100%;
                height: 280px;
                overflow: hidden;

                @media (min-width: $max-tablet) {
                   height: 319px;
                }

      
                .slide-image {
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 100%;
                    height: 100%;
                    transition: $duration $easing;
                }
      
                &:hover {
      
                    .slide-image {
                        transform: scale(1.1);
                    }
                }
              }
            }
            .content {
              display: flex !important;
              align-items: center;
              flex-direction: column;
              padding: 25px 0;
  
              h3 {
                font-size: 18px;
                margin-top: 1rem;
                margin-bottom: 0;
              }
      
              p {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
