﻿.newsletter-pod {
    background-color: $black;
    padding: 160px 0 40px;
    color: $white;
    position: relative;   

    .umbraco-forms-navigation {
        width: 100%;
        margin: 0 auto;
        justify-content: flex-start;

    }

    .background {
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 0;
        width: 100%;
        height: 140px;
        position: relative;
        z-index: 0;
    }

    .content {
        display: flex;
        flex-direction: column;
        background: $mobile-nav-bg;
        padding: 48px 32px;
        z-index: 1;
        position: relative;
    }

    &__text {
        margin-bottom: 30px;
        
        h2 {
            @include font-size(32, 32);  
            color: $white;
        }
        
        p {
            color: $white;
           opacity: .7;
        }
    }

    &__signup {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media(min-width: $max-tablet) {
            align-items: center;
        }

        .newslettersignup {
            width: 100%;
        }

        
        .umbraco-forms-page {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            @media(min-width: $max-mobile) {
                align-items: center;
            }

            .button div.hover:before {
                        background: #EEEEEE;
                    }

                    .button:hover .btn-text {
                        color: black;
                    }

            .umbraco-forms-fieldset {
                border: 0;
                width: 100%;
            

                .umbraco-forms-container {
                    justify-content: flex-start;
                    margin: 0;
                    align-items: flex-start;

                    @media(min-width: $max-mobile) {
                        justify-content: center;
                        margin: 0 auto;
                    }
                }

                .umbraco-forms-field-wrapper {
                    position: relative;    
             


                    .umbraco-forms-label {
                        transition: $duration $easing;
                        position: absolute;
                        left: 0;
                    }
                    
                    input[type=text] {
                        width: 100%;
                        background-color: transparent;
                        border: none;
                        border-bottom: 1px solid rgba(255, 255, 255, .7);
                        margin-bottom: 0;
                        padding-bottom: 5px;
                        color: white;

                        &:focus {
                            border-bottom: 1px solid $white;
                        }
                    }

                    input:focus + .umbraco-forms-label{
                        top: -18px;
                    }

                    &--check {
                        display: flex;
                        width: 100%;
                        @include font-size(10);       
                        margin-bottom: 20px;

                        label {
                            margin-left: 10px;
                        }

                        a {
                            color: $white;
                        }

                        input[type=checkbox] {
                            position: relative;
                            cursor: pointer;
                        }
                        
                        input[type=checkbox]:before {
                            content: "";
                            display: block;
                            position: absolute;
                            width: 16px;
                            height: 16px;
                            top: 0;
                            left: 0;
                            border: 1px solid $light-grey;
                            background-color: $black;
                        }

                        input[type=checkbox]:checked:after {
                            content: "";
                            display: block;
                            width: 5px;
                            height: 10px;
                            border: solid $white;
                            border-width: 0 2px 2px 0;
                            -webkit-transform: rotate(45deg);
                            -ms-transform: rotate(45deg);
                            transform: rotate(45deg);
                            position: absolute;
                            top: 2px;
                            left: 6px;
                        }
                    }
                }
            }
        }
    }

    @media(min-width: $max-tablet) {
        background-size: cover;
        background-repeat: no-repeat;
        padding: 120px 0;

        .umbraco-forms-navigation {
            width: 400px;
            margin: 0 auto;
        }

        .background {
            position: absolute;
            top:0;
            left:0;
            bottom: 0;
            right: 0;
            height: 100%;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: linear-gradient(90deg, transparent 25%, rgba(0,0,0,.8) 45%);
                z-index: 1;
            }

            &:after {
                content:'';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba( 0, 0, 0, .3 );
                z-index: 1;
            }
        }

        .content {
            background: none;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            padding: 64px;
        }

        &__text {
            width: 100%;
            max-width: 320px;
            margin-bottom: 0;
            padding-right: 32px;

            h2 {
                @include font-size(48,48);                
            }
        }

        &__signup {
            width: 100%;
            max-width: 600px;

            input[type=text] {
                margin-bottom: 40px;
                padding-bottom: 15px;
                &::placeholder {
                    color: $white;
                }
            }

            &--check {
                margin-bottom: 40px;
            }
        }
    
    }

}

@media screen and (max-height: 575px){
    #rc-imageselect, .g-recaptcha {
        transform:scale(0.77);-webkit-transform:scale(0.77);transform-origin:0 0;-webkit-transform-origin:0 0;
    }
}
