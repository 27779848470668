﻿.download-pod {
    background-color: $pod-grey-bg;
    border-bottom-right-radius: $curve-mob;
    margin: 0 $spacing;

    &__img {
        width: 100%;
        height: 246px;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &__text {
        z-index: 2;
        position: relative;
        text-align: center;
        padding: 40px 64px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h3 {
            color: $black;
            margin-top: 24px;
        }

        p {
            color: $black;
            margin-bottom: 30px;
            opacity: .7;
        }

        .button {
            align-self: center;
            margin-left: -48px;
        }
    }

    @media(min-width: $max-tablet) {
        display: flex;
        justify-content: space-around;
        margin: 0 auto;
   
        border-bottom-right-radius: $curve-desktop;

        &__img {
            flex: 1;
            height: initial;
            max-width: 456px;
        }

        &__text {
            flex: 1;

            p {
                max-width: 80%;
            }
        }
    }
}
